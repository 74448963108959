import React from 'react';
import { downloadIcon, trashIcon } from '../../Assets/dynamic-svg/general-icons';
import { royalBlue } from '../../Utils/styleHelper';

const downloadButton = {
  color: royalBlue,
  textAlign: 'left',
  padding: '0px',
  maxWidth: '248px',
  maxHeight: '23px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};
const downloadIconStyle = {
  alignSelf: 'center',
  marginTop: '-5px',
  marginLeft: '10px',
  cursor: 'pointer'
};
const deleteIconStyle = {
  marginLeft: '0px',
  cursor: 'pointer'
};

function displayFileSize(fileSize, decimals = 1) {
  if (!fileSize) {
    return '0 Bytes';
  }

  if (fileSize < 1024) {
    return fileSize + ' Bytes';
  }

  if (parseFloat((fileSize / 1024).toFixed(decimals)) < 1024) {
    return (fileSize / 1024).toFixed(decimals) + ' KB';
  }

  return (fileSize / (1024 * 1024)).toFixed(decimals) + ' MB';
}

function FileDataCard(props) {
  const { fileName, fileId, fileSize, rootStore } = props;
  const { modalStore } = rootStore;

  function getFile() {
    try {
      props.onDownload(fileId);
    } catch (e) {
      console.log(e)
      modalStore.showConfirm('Error on downloading file ');
    }
  }

  const deleteFile = () => {
    // create modal
    modalStore.showChoice(
      'Datei löschen',
      'Wollen Sie diese Datei wirklich löschen?',
      'Abbrechen',
      () => {},
      'Löschen',
      () => {
        props.onDelete(fileId)
      }
    );
  };

  return (
    <React.Fragment>
      <button style={downloadButton} onClick={e => getFile()} title={fileName}>
        {fileName}
      </button>
      {fileSize ? <span className="filesize">{displayFileSize(fileSize)}</span> : ''}
      <span style={downloadIconStyle} className="icon-general download-icon" onClick={() => getFile()}>
        {downloadIcon(props.iconColor ? props.iconColor : royalBlue)}
      </span>
      {!props.hideDeleteButton && (
        <span style={deleteIconStyle} className="icon-general delete-button" onClick={() => deleteFile()}>
          {trashIcon(royalBlue)}
        </span>
      )}
    </React.Fragment>
  );
}

export default FileDataCard;
