import React from 'react';
import { observable, action, decorate } from 'mobx';

/*
  Similar to the Modal Store - with different options and styling.
*/

export default class InfoBoxStore {
  infoBoxVisible = false;
  title = 'InfoBox title';
  content;
  buttons;
  shouldCloseOnOverlayClick = true;
  modalQueue = [];

  showInfoBox(title, content, buttons) {
    if (this.infoBoxVisible) {
      this.queueInfoBox(title, content, buttons);
    } else {
      this.title = title;
      this.content = content;
      this.buttons = buttons;
      this.infoBoxVisible = true;
    }
  }

  showNoButtons(title, text) {
    const showTitle = title;
    const showContent = <div>{text}</div>;
    this.showInfoBox(showTitle, showContent, null);
  }

  /* Used for ADV InfoBox */
  showConfirm(title, text, buttonText, buttonCallback) {
    const showTitle = title;
    const showContent = <div>{text}</div>;
    const showButtons = (
      <button
        className={'btn-solid'}
        onClick={() => {
          this.hideInfoBox();
          if (buttonCallback) {
            setTimeout(() => {
              buttonCallback();
            }, 1);
          }
        }}>
        {buttonText || 'OK'}
      </button>
    );
    this.showInfoBox(showTitle, showContent, showButtons);
  }

  /* Check Usage */
  showChoice(title, text, ghostButtonText, ghostButtonCallback, defaultButtonText, defaultButtonCallback) {
    const showTitle = title;
    const showContent = <div>{text}</div>;
    const showButtons = (
      <React.Fragment>
        <button
          className={'modal-btn modal-cancel-button'}
          onClick={() => {
            this.hideInfoBox();
            if (ghostButtonCallback) {
              setTimeout(() => {
                ghostButtonCallback();
              }, 1);
            }
          }}>
          {' '}
          {ghostButtonText || 'Abbrechen'}
        </button>
        <button
          className={'btn-ghost modal-confirm-button'}
          onClick={() => {
            this.hideInfoBox();
            if (defaultButtonCallback) {
              setTimeout(() => {
                defaultButtonCallback();
              }, 1);
            }
          }}>
          {' '}
          {defaultButtonText || 'OK'}
        </button>
      </React.Fragment>
    );
    this.showInfoBox(showTitle, showContent, showButtons);
  }

  queueInfoBox(title, content, buttons) {
    const entry = { title, content, buttons };
    this.modalQueue.push(entry);
  }

  showNextInfoBox() {
    if (this.modalQueue.length > 0) {
      const entry = this.modalQueue.shift();
      this.showInfoBox(entry.title, entry.content, entry.buttons);
    }
  }

  hideInfoBox() {
    this.infoBoxVisible = false;
    this.title = '';
    this.content = undefined;
    this.buttons = undefined;
    setTimeout(() => {
      this.showNextInfoBox();
    }, 100);
  }
}

decorate(InfoBoxStore, {
  // Observables
  infoBoxVisible: observable,

  // Actions
  showInfoBox: action,
  hideInfoBox: action,
  showNextInfoBox: action
});
