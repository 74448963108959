import { PERSONAL_DATA_TEXT, ROLE_TEXT } from '../Pages/EmployeePage/labels';
import { EMPLOYEE_HEADER_NAME } from '../Utils/constants';

export const helpTextEditEmployee = {
  title: EMPLOYEE_HEADER_NAME,
  personalData: {
    id: 'help-text-personal-data',
    title: PERSONAL_DATA_TEXT,
    description: (
      <div>
        Nachname, Vorname und E-Mail-Adresse werden zur eindeutigen Identifikation der Mitarbeiter benötigt. Über die E-Mail-Adresse wird
        sichergestellt, dass den Mitarbeitern nur die eigenen Fälle angezeigt werden.
      </div>
    )
  },
  role: {
    id: 'help-text-role',
    title: ROLE_TEXT,
    description: (
      <div>
        Sofern die Mitarbeiter in der Finanzbuchhaltung und/oder Entgeltabrechnung beschäftigt sind, können Sie hier die weitergehenden
        Funktionen des Geschenkemanagers für diese Rollen aktivieren.
      </div>
    )
  }
};
