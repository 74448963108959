import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

function handleActionClick(e, item, props) {
  e.stopPropagation();
  item.click(props.index);
  props.closeMenu();
}
function MenuActions(props) {
  /* 30px per action and base of 20px */
  let translate = '';
  let openToTop = -30 * props.listItems.length - 20;
  const translateYValue = `${openToTop}px`;
  translate = `translateY(${props.isMenuOutOfBounds ? translateYValue : '0'})`;

  // scrolled table needs different translate values
  if (props.scrollTableRowIndex >= 0) {
    const tableBody = document.getElementsByTagName('tbody')[0];
    let scrollTranslateYValue = -20;
    if (tableBody.scrollTop > 0) {
      scrollTranslateYValue = scrollTranslateYValue + tableBody.scrollTop * -1;
    }
    if (props.isMenuOutOfBounds) {
      scrollTranslateYValue = scrollTranslateYValue + openToTop;
    }
    translate = `translate(-20px, ${scrollTranslateYValue}px)`;
  }

  return (
    <div style={{ transform: translate }} className="menu-container" ref={props.rowMenuRef}>
      <ul>
        {props.listItems.map(item => (
          <li
            key={`${props.index}${item.label}`}
            className={'icon-wrapper'}
            onClick={e => {
              handleActionClick(e, item, props);
            }}>
            <span>
              {item.icon} {item.label}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

MenuActions.propTypes = {
  rowMenuRef: PropTypes.func
};

export default MenuActions;
