import { firstDayLastYearDate, lastDayNextYearDate } from '../../Utils/constants';
import { formatDateObject } from '../../sma-shared/Utils/utils';

// -----  sender/approver labels
export const LAST_NAME_TEXT = 'Nachname';
export const FIRST_NAME_TEXT = 'Vorname';
export const COMPANY_TEXT = 'Unternehmen';
export const PERSONAL_ID = 'Personal ID';
export const EMAIL_TEXT = 'E-Mail-Adresse';
export const SENT_BY_EMAIL = 'Angelegt durch';
export const LIST_OF_RECEIVERS_TEXT = 'Empfänger-Liste anzeigen';

export const INFO_FOR_EMPFANGER =
  'Informieren Sie den Empfänger, wenn Sie die Zuwendung pauschal versteuern und ihn dadurch von der individuellen Steuerpflicht befreien (§ 37b Abs. 3 Satz 3 EStG).';
export const INFO_FOR_EMPFANGER_COPYTEXT =
  'Für Ihr Geschenk haben wir die Pauschalsteuer nach § 37b EStG übernommen. Wir haben diese Pauschalsteuer angemeldet und abgeführt. Diese Bescheinigung dient Ihnen als Nachweis gegenüber Ihrem Finanzamt, dass die Besteuerung des Geschenks bereits erfolgt ist. ';
export const STATUS_DER_GENEHMIGUNG_INFO =
  'Sie können hier – sofern Sie die Berechtigung dazu haben – das Geschenk selbst genehmigen. Andernfalls können Sie die Genehmigung bei einer dritten Person einholen.';
export const SELF_APPROVAL_INFO = 'Hier können Sie die Genehmigung selbst erteilen.';
export const APPROVAL_INFO =
  'Hier können Sie die Genehmigung anfordern. Der Genehmiger wird über eine E-Mail über die ausstehende Genehmigung informiert.';
export const APPROVAL_COMMENT = 'Kommentar';
export const APPROVAL_EMAIL = 'E-Mail-Adresse Genehmiger';
export const INFO_TOO_MANY_APPROVER_CHANGES = 'Der Genehmiger wurde zu oft geändert. Es sind max. 5 Änderungen möglich.';

export const STATUS_DER_GENEHMIGUNG_TITLE = 'Status der Genehmigung';
export const INFO_FOR_EMPFANGER_TITLE = 'Infos für Empfänger';
export const COMMENT_FROM_SENDER = 'Kommentar von Absender';
export const COMMENT_FROM_APPROVER = 'Kommentar von Genehmiger';
export const APPROVED = 'Genehmigt';
export const APPROVED_BY = 'Genehmigung durch';
export const REQUEST_APPROVED = 'Genehmigung anfordern';
export const REQUEST_SELF_APPROVED = 'Genehmigung selbst erteilen';
export const REMINDER = 'Erinnerung';
export const COPY_TEXT_TO_CLIPBOARD = 'Text in Zwischenablage kopieren';
export const REJECTED = 'Abgelehnt';
export const SELF_APPROVED = 'Selbst genehmigen';
export const GET_APPROVAL = 'Genehmigung einholen';
export const SEND_REMINDER = 'Erinnerung senden';
export const REQUEST_AGAIN = 'Erneut anfordern';
export const REJECTED_REQUEST = 'Genehmigung ablehnen';
export const ACCEPT_REQUEST = 'Genehmigung erteilen';
// ----- email SUBJECT LABELS
export const SUBJECT_EMAIL_TO_APPROVER = `Haufe Geschenkemanager: Genehmigung erforderlich`;
export const SUBJECT_REMINDER_EMAIL_TO_APPROVER = `Haufe Geschenkemanager: Erinnerung Genehmigung erforderlich`;
export const SUBJECT_EMAIL_TO_SENDER_APPROVED = `Haufe Geschenkemanager: Ihre Anfrage wurde genehmigt.`;
export const SUBJECT_EMAIL_TO_SENDER_REJECTED = `Haufe Geschenkemanager: Ihre Anfrage wurde abgelehnt.`;
export const SUBJECT_EMAIL_TO_ACCOUNTING = `Haufe Geschenkemanager: Bearbeitung durch Finanzbuchhaltung erforderlich`;
export const SUBJECT_EMAIL_TO_PAYROLL = `Haufe Geschenkemanager: Bearbeitung durch Entgeltabrechnung erforderlich`;

//------ entry check labels ------------
export const ENTRY_CHECK_PAGE_TITLE = 'Einstiegs-Check';
export const RECEIVER_TYPE_TEXT = 'Empfänger';

export const RECEIVER_NUMBER_TEXT = 'Anzahl';
//------ receiver check labels ------------
export const RECEIVER_PAGE_TITLE = 'Empfänger';
export const RECEIVER_PERSONALDATA_TEXT = 'Personendaten';

// ----- gift labels
export const APPROVAL_PAGE_TITLE = 'Genehmigung';
export const GIFT_TITLE_TEXT = 'Geschenk';
export const INFO_GIFT_TEXT = 'Infos zum Geschenk';
export const REASON_TEXT_SIMPLE = 'Anlass';
export const REASON_TEXT = 'Anlass: Bitte beachten Sie die Kriterien im Hilfetext.';
export const DESCRIPTION_OCCASION_TEXT = 'Beschreibung Anlass';
export const DESCRIPTION_GIFT_TEXT = 'Beschreibung Geschenk';
export const GIFTDATE_TEXT = 'Datum der Schenkung';
export const GIFTVALUE_TEXT = valueType => `Wert (${valueType.toLowerCase()}) pro Geschenk`;
export const INVOICE_NUMBER_TEXT = 'Rechnungsnummer';
export const INTERNAL_INVOICE_NUMBER_TEXT = 'Interne Belegnummer';
export const ADD_DOCUMENT_TEXT = 'Dokument hinzufügen';
export const DOCUMENT_TEXT = 'Dokument';
export const PLEASE_CHOOSE_TEXT = 'Bitte treffen Sie eine Auswahl.';
export const PLEASE_ENTER_OCCASION_ERROR_TEXT = 'Bitte geben Sie an, ob es sich um einen besonderen persönlichen Anlass handelt.';
export const PLEASE_DESCRIPTION_OCCASION_ERROR_TEXT = 'Bitte beschreiben Sie den Anlass für das Geschenk.';
export const PLEASE_ENTER_DESCRIPTION_ERROR_TEXT = 'Bitte beschreiben Sie das Geschenk selbst.';
export const PLEASE_ENTER_DATE_ERROR_TEXT = `Bitte geben Sie ein Datum zwischen dem ${formatDateObject(
  firstDayLastYearDate
)} und dem ${formatDateObject(lastDayNextYearDate)} im Format (TT.MM.JJJJ) ein.`;
export const PLEASE_ENTER_VALUE_ERROR_TEXT = 'Bitte geben Sie einen Wert ein.';
export const ERROR_ENTER_LASTNAME = 'Bitte geben Sie einen Nachnamen ein.';
export const ERROR_ENTER_FIRSTNAME = 'Bitte geben Sie einen Vornamen ein.';
export const ERROR_ENTER_COMPANY = 'Bitte nennen Sie ein Unternehmen.';
export const ERROR_MESSAGE_EMAIL = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
export const PLEASE_ENTER_INVOICE_NUMBER_ERROR_TEXT = 'Bitte geben Sie die Rechnungsnummer der Eingangsrechnung ein.';

// ----- falldaten labels
export const FALLDATEN_TITLE_PAGE = 'Falldaten';
export const FALLDATEN_DESCRIPTION = '';

export const BERICHT_TITLE_PAGE = 'Bericht zum Geschenkefall';
export const GESCHENKFALLS_TITLE_CARD = 'Ergebnis des Geschenkefalls';
export const RECEIVER_TITLE_CARD = 'Empfänger';
export const GIFT_TITLE_CARD = 'Geschenk';
export const APPROVER_TITLE_CARD = 'Genehmigung';
export const GROSS_VALUE_TEXT = 'Aufwand (brutto) pro Geschenk';
export const NET_VALUE_TEXT = 'Aufwand (netto) pro Geschenk';

// ------- accounting labels -----------
export const FIBU_INVOICENUMBER = 'Rechnungsnummer';
export const FIBU_RECEIPTNUMBER = 'Interne Belegnummer';
export const FIBU_ADDDOCUMENT = 'Dokument hinzufügen';
export const ACCOUNT = 'Konto';
export const SKR03 = 'SKR 03';
export const SKR04 = 'SKR 04';
export const PROCESSING_BY_FIBU = 'Bearbeitung durch Fibu';
export const INVOICE_INFORMATION = 'Rechnungsinformationen';
export const CONFIRM_INPUTS = 'Eingaben übernehmen';
export const BOOKING_INFORMATION = 'Informationen zur Buchung';
export const HOW_TO_ASSIGN_ACCOUNTS = 'So kontieren Sie richtig';
export const FIBU_FINISHED = 'Fibu bearbeitet';
export const FIBU_CARD_TEXT = 'Der Fall wird zur weiteren Bearbeitung automatisch an die Entgeltabrechnung weitergeleitet.';
export const NO_BOOKING_NECESSARY_TEXT = 'Keine Buchung nötig.';

export const COMPLETE_TASK = 'Bearbeitung abschließen';

// ------- payroll labels -----------
export const PAYROLL_PAGE_TITLE = 'Bearbeitung durch Entgeltabrechnung';
export const COMMENT_TITLE = 'Kommentar von Fibu';
export const PAYROLL_INFO_TEXT = 'Informationen zur Versteuerung';
export const PAYROLL_TAX_TEXT = 'Lohnsteuer';
export const PAYROLL_SOCIAL_TAX_TEXT = 'Sozialversicherung';
export const PROCESSING_STATUS_TEXT = 'Status der Bearbeitung';
export const PROCESSING_BY_PAYROLL = 'Bearbeitung durch Entgeltabrechnung';
export const PAYROLL_CASE_CLOSED = 'Der Fall ist komplett bearbeitet. Sie können sich nun den Bericht ansehen.';

export const COMMENT = 'Kommentar';
export const INVALID_EMAIL = 'Invalid e-mail';
export const CHECK_CASE_DATA_RIGHT = 'Rechts sehen Sie die Falldaten.';
export const ERROR_GROSS_VALUE = 'Bitte geben Sie den Aufwandsbetrag (brutto) je Geschenk ein.';
export const ERROR_NET_VALUE = 'Bitte geben Sie den Aufwandsbetrag (netto) je Geschenk ein.';
export const ERROR_FIBU_INTERNAL_NUMBER = 'Bitte geben Sie die interne Belegnummer ein.';

export const messageNotificationSender = {
  draft: '',
  approvalRequest: 'Die Genehmigung wurde angefordert. Der Genehmiger wird automatisch informiert.',
  selfApproved: 'Der Fall wird zur weiteren Bearbeitung automatisch an die Finanzbuchhaltung weitergeleitet.',
  completed: 'Der Fall ist komplett bearbeitet. Sie können sich nun den Bericht ansehen.'
};

export const messageNotificationApprover = {
  approved: 'Der Fall wird zur weiteren Bearbeitung automatisch an die Finanzbuchhaltung weitergeleitet.',
  rejected: 'Der Absender wird automatisch über die Ablehnung informiert.'
};
