/**
 * INFO: Props required
 * applicationTitle => string
 * headerLinks => ex: [{title: 'link1', path:'/to/link1'}, etc.]
 */

import './_header.scss';

import HeaderLogo from './HeaderLogo';
import { Link } from 'react-router-dom';
import LinksContainer from './LinksContainer';
import PropTypes from 'prop-types';
import React from 'react';
import UserProfile from './UserProfile';
function Header(props) {
  return (
    <div className="header">
      <div
        className="header__content"
        style={{
          height: props.height,
        }}>
        <Link to="/">
          <HeaderLogo
            customLogo={props.customLogo}
            additionalLogo={props.additionalLogo}
            dividerPlacementLast={props.dividerPlacementLast}
          />
        </Link>
        <LinksContainer applicationTitle={props.applicationTitle} headerLinks={props.headerLinks} />
        {props.customComponents ? props.customComponents : ''}
        {!props.userDisconnected && (
          <UserProfile
            userTitle={props.userTitle}
            userProfile={props.userProfile}
            userInfo={props.userInfo}
            userMenuActions={props.userMenuActions}
            siteid={props.siteid}
            event={props.event}
          />
        )}
      </div>
    </div>
  );
}

Header.defaultProps = {
  applicationTitle: 'Application Title',
  headerLinks: [],
  height: '66px',
};

Header.propTypes = {
  applicationTitle: PropTypes.string.isRequired,
  headerLinks: PropTypes.array.isRequired,
  userProfile: PropTypes.object.isRequired,
  userTitle: PropTypes.string,
  userInfo: PropTypes.object,
  additionalLogo: PropTypes.string,
  dividerPlacementLast: PropTypes.bool,
  siteid: PropTypes.string,
  event: PropTypes.string,
};

export default Header;
