import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { getObjectByPropValueFromArray, isEmpty } from '../../../sma-shared/Utils/utils';
import { WORKFLOW } from '../WorkflowNavigation/workflow';
import { GIFTCASE_ENTRYCHECK_PATH } from '../../../Utils/paths';
import InputCard, { INPUT_CARD_TYPE } from '../../../Components/InputCard';
import { linkIcon } from '../../../Assets/dynamic-svg/general-icons';
import { blackPearl } from '../../../Utils/styleHelper';
import { showErrorInView, scrollIntoViewById, generateGtmEventName } from '../../../Utils/utils';
import { actions, activeArea } from '../../../Utils/constants';
import {
  receiverType as receiverTypeEnum,
  OK_BUTTON,
  CANCEL_BUTTON,
  RECEIVER_CHANGES_TITLE,
  RECEIVER_CHANGES_MESSAGE
} from '../../../Utils/constants';
import FileDataList from '../../../Components/FileDataList';
import './_style.scss';
import { ENTRY_CHECK_PAGE_TITLE, RECEIVER_TYPE_TEXT } from '../giftCaseLabels';
import { helpTextEntryCheck } from '../../../HelpText/entryCheckHelpText';

function EntryCheck(props) {
  const { giftDataStore, createGiftStore, helpTextStore, apiStore, modalStore } = props.rootStore;

  const [isInvalidReceiverType, setIsInvalidReceiverType] = useState(false);
  const [isInvalidCompliance, setIsInvalidCompliance] = useState(false);

  const [receiverType, setReceiverType] = useState(giftDataStore.receiverType);
  const [compliantGiftPolicy, setComplianceGiftPolicy] = useState(false);
  const [fileAttachments, setFileAttachments] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    createGiftStore.currentWorkflowStep = getObjectByPropValueFromArray(WORKFLOW, 'path', GIFTCASE_ENTRYCHECK_PATH);
    createGiftStore.currentWorkflowStep.validate = validate;
    helpTextStore.setHelpText(helpTextEntryCheck);
    return () => {
      helpTextStore.resetHelpText();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReceiverType(giftDataStore.receiverType);
    setComplianceGiftPolicy(giftDataStore.compliantGiftPolicy);
    createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.receiverType, giftDataStore.compliantGiftPolicy, giftDataStore.giftValue]);

  useEffect(() => {
    if (apiStore.settings && apiStore.settings.compliance) {
      if (apiStore.settings.compliance.url) {
        setUrl(apiStore.settings.compliance.url);
      }
      if (apiStore.settings.compliance.fileAttachments && apiStore.settings.compliance.fileAttachments.length > 0) {
        setFileAttachments(apiStore.settings.compliance.fileAttachments);
      }
    }
    createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.settings]);

  useEffect(() => {
    if (apiStore.settings && apiStore.settings.company) {
      if (
        apiStore.settings.compliance.url ||
        (apiStore.settings.compliance.fileAttachments && apiStore.settings.compliance.fileAttachments.length > 0)
      ) {
        giftDataStore.isComplianceGiftPolicyVisible = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore]);

  const validate = () => {
    const invalidResults = [];

    invalidResults.push(validateInput(giftDataStore.receiverType, setIsInvalidReceiverType, false));
    if (giftDataStore.isComplianceGiftPolicyVisible) {
      const isInvalidCompliantGiftPolicy = giftDataStore.compliantGiftPolicy === true;
      invalidResults.push(!isInvalidCompliantGiftPolicy);
      setIsInvalidCompliance(!isInvalidCompliantGiftPolicy);
    }

    const isValid = !invalidResults.some(value => value);
    if (!isValid) {
      showErrorInView();
    }

    createGiftStore.currentWorkflowValidating = !createGiftStore.currentWorkflowValidating;
    return isValid;
  };

  const validateInput = (value, setInvalidState, ignoreEmptyValue = false) => {
    const invalidState = ignoreEmptyValue ? false : isEmpty(value);
    setInvalidState(invalidState);
    return invalidState;
  };

  const handlerReciverTypeChanged = newValue => {
    if (giftDataStore.receivers.length > 0) {
      modalStore.showChoice(RECEIVER_CHANGES_TITLE, RECEIVER_CHANGES_MESSAGE, CANCEL_BUTTON, null, OK_BUTTON, () => {
        setReceiverType(newValue);
        giftDataStore.receiverType = newValue;
        getRecommendation(giftDataStore.receiverType);
        if (newValue === receiverTypeEnum.employee.value) {
          giftDataStore.receivers.forEach(receiver => {
            receiver.company = '';
          });
        } else if (newValue === receiverTypeEnum.bussinesPartner.value) {
          giftDataStore.receivers.forEach(receiver => {
            receiver.personalId = '';
          });
        }
      });
    } else {
      giftDataStore.receiverType = newValue;
      setReceiverType(newValue);
      getRecommendation(giftDataStore.receiverType);
    }
  };

  //Render Receivers Data Card
  const renderReceiversCard = () => [
    {
      type: INPUT_CARD_TYPE.label,
      label: 'An wen möchten Sie das Geschenk verschenken?'
    },
    {
      type: INPUT_CARD_TYPE.radioButton,
      checked: receiverType === receiverTypeEnum.employee.value,
      label: receiverTypeEnum.employee.label,
      name: 'receiver',
      value: receiverTypeEnum.employee.value,
      isInvalid: isInvalidReceiverType,
      disabled: !giftDataStore.canEdit,
      onChange: e => {},
      onClick: value => {
        validateInput(value, setIsInvalidReceiverType, true);
        handlerReciverTypeChanged(value);
      }
    },
    {
      type: INPUT_CARD_TYPE.radioButton,
      checked: receiverType === receiverTypeEnum.bussinesPartner.value,
      label: receiverTypeEnum.bussinesPartner.label,
      name: 'receiver',
      value: receiverTypeEnum.bussinesPartner.value,
      isInvalid: isInvalidReceiverType,
      disabled: !giftDataStore.canEdit,
      onChange: e => {},
      onClick: value => {
        validateInput(value, setIsInvalidReceiverType, true);
        handlerReciverTypeChanged(value);
      }
    }
  ];

  //Render Company Guidilenes Compliance Box
  const renderCompanyGuidlinesCard = () => [
    {
      id: 'company-guidelines',
      type: INPUT_CARD_TYPE.checkbox,
      checked: compliantGiftPolicy,
      name: 'Bei der Schenkung werden die aktuellen Geschenke-Richtlinien Ihres Unternehmens eingehalten.',
      isInvalid: isInvalidCompliance,
      disabled: !giftDataStore.canEdit,
      value: compliantGiftPolicy,
      onChange: value => {
        setComplianceGiftPolicy(!compliantGiftPolicy);
        giftDataStore.compliantGiftPolicy = !compliantGiftPolicy;
        validateInput(value, setIsInvalidCompliance, true);
      }
    },
    {
      type: INPUT_CARD_TYPE.customElement,
      customElement: renderNotification()
    }
  ];

  //Render Gift Value Card
  const renderGiftValueRecommendation = () => [
    {
      type: INPUT_CARD_TYPE.customElement,
      customElement: renderRecommendations()
    }
  ];

  //Render Orange Notification under Policy Guidlines
  const renderNotification = () => {
    return (
      <div className="card-notification">
        <div className="policies">
          <p>Die aktuell geltenden Geschenke-Richtlinien in Ihrem Unternehmen können Sie hier einsehen:</p>
          {url && (
            <p data-gtmid={generateGtmEventName(activeArea.entryCheck, actions.open, "GuidelineURL")}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {linkIcon(blackPearl)} <span className="link-text">{url}</span>
              </a>
            </p>
          )}
          {fileAttachments && (
            <div className={url === '' ? 'compliance-file-list no-url-above' : 'compliance-file-list'}
              data-gtmid={generateGtmEventName(activeArea.entryCheck, actions.download, "Guideline")}>
              <FileDataList
                attachments={fileAttachments}
                idFromDB={apiStore.settings._id}
                rootStore={props.rootStore}
                iconColor={blackPearl}
                onDownload={async fileId => apiStore.downloadComplianceAttachment(fileId)}
              />
            </div>
          )}
        </div>

        <div className="compliance-area">
          <p>Allgemeine Infos zum Thema Compliance finden Sie hier:</p>
          <a
            href="https://www.haufe.de/compliance/management-praxis/geschenke-und-einladungen-in-der-compliance-praxis-was-ist-tabu_230130_429886.html"
            target="_blank"
            rel="noopener noreferrer"
            data-gtmid={generateGtmEventName(activeArea.entryCheck, actions.open, "GeneralGuideline")}>
            {linkIcon(blackPearl)} <span className="link-text">Allgemeine Compliance-Regeln für Geschenke</span>
          </a>
        </div>
      </div>
    );
  };

  //Render Reccomendatios
  const renderRecommendations = () => {
    return <div className="recommendations">{ReactHtmlParser(giftDataStore.recommendation)}</div>;
  };

  const getRecommendation = async receiverType => {
    if (apiStore.settings && apiStore.settings.company) {
      const recomandation = await apiStore.getGiftValueGuidance(receiverType);
      giftDataStore.recommendation = recomandation;
      createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    }
  };

  const showHelpText = helpTextId => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  return (
    <div className="entry-check-container flex-container">
      <div className="create-gift-page-content">
        <div className="page-header">
          <h2 className="title">{ENTRY_CHECK_PAGE_TITLE}</h2>
        </div>
        <InputCard
          title={RECEIVER_TYPE_TEXT}
          isMandatory
          data={renderReceiversCard()}
          helpTextId={helpTextEntryCheck && helpTextEntryCheck.receiverType ? helpTextEntryCheck.receiverType.id : ''}
          onClickHelpText={showHelpText}
        />
        {!isEmpty(giftDataStore.recommendation) ? (
          <InputCard title={'Bitte beachten Sie:'} isMandatory data={renderGiftValueRecommendation()} onClickHelpText={showHelpText} />
        ) : (
          ''
        )}
        {(url || fileAttachments) && (
          <InputCard title={'Geschenke-Richtlinien'} isMandatory data={renderCompanyGuidlinesCard()} onClickHelpText={showHelpText} />
        )}
      </div>
      <div className="grey-container-without-sidebar"></div>
    </div>
  );
}

export default inject('rootStore')(observer(EntryCheck));
