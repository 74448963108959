import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import arrowUP from '../../../../Assets/svg/dropdown-arrow-up.svg';
import arrowDOWN from '../../../../Assets/svg/dropdown-arrow-down.svg';
import arrowDOWNDisabled from '../../../../Assets/svg/dropdown-arrow-down-disabled.svg';
import { manatee } from '../../../../Utils/styleHelper';
import './_style.scss';

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {!props.selectProps.menuIsOpen ? (
          <div>
            <span>
              <img className="dropdown-icon" alt="" src={props.selectProps.isDisabled ? arrowDOWNDisabled : arrowDOWN} />
            </span>
          </div>
        ) : (
          <div>
            <span>
              <img className="dropdown-icon" alt="" src={arrowUP} />
            </span>
          </div>
        )}
      </components.DropdownIndicator>
    )
  );
};

class Dropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }

  render() {
    const customStyles = {
      control: (styles, { isDisabled }) => ({
        ...styles,
        backgroundColor: 'white',
        cursor: 'pointer',
        height: this.props.height || 54,
        borderRadius: 4,
        boxShadow: 0,
        color: isDisabled ? '#d2d4d6' : '#ced4da',
        borderColor: this.state.dropdownOpen && !this.props.isInvalid ? '#075BFA' : this.props.isInvalid ? '#E81546' : '#ced4da', // eslint-disable-line
        '&:hover': {
          borderColor: !this.props.isInvalid ? '#075BFA' : '#ced4da'
        }
      }),
      // options in the dropdown list
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? '#075BFA' : isFocused ? '#e3e3e3' : null, // eslint-disable-line
        color: isSelected ? 'white' : 'black',
        cursor: 'pointer',
        padding: '14px 20px'
      }),
      placeholder: styles => ({
        ...styles,
        color: manatee,
        fontStyle: 'italic'
      }),
      menuList: styles => ({
        ...styles,
        textAlign: 'left',
        borderRight: '1px solid #075BFA',
        borderLeft: '1px solid #075BFA',
        borderBottom: '1px solid #075BFA',
        borderTop: '1px solid #075BFA',
        marginTop: 0,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)'
      }),
      valueContainer: styles => ({
        ...styles,
        padding: '14px 20px',
        height: '100%'
      }),
      // each element of the dropdown
      singleValue: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? '#d2d4d6' : '#232A36'
      }),
      menu: styles => ({
        ...styles,
        marginTop: 0,
        zIndex: 2,
        boxShadow: 'none'
      }),
      indicatorSeparator: styles => ({
        ...styles,
        display: 'none'
      }),
      input: styles => ({
        ...styles,
        margin: '0',
        padding: '0'
      })
    };

    const validationMessage = this.props.isInvalid && this.props.errorMsg;

    const className = this.props.isInvalid ? 'dropdown-input-container invalid' : 'dropdown-input-container';
    return (
      <div className={className}>
        <Select
          className={this.props.className}
          name={this.props.id}
          id={this.props.id}
          onChange={this.props.onChange}
          onMenuOpen={() => {
            this.setState({ dropdownOpen: true });
          }}
          onMenuClose={() => {
            this.setState({ dropdownOpen: false });
          }}
          components={{ DropdownIndicator }}
          placeholder={this.state.dropdownOpen ? '' : this.props.placeholder}
          value={this.props.value}
          isInvalid={this.props.isInvalid}
          options={this.props.options}
          styles={customStyles}
          isDisabled={this.props.isDisabled === undefined ? false : this.props.isDisabled}
          isSearchable={this.props.isSearchable}
          noOptionsMessage={() => 'Keine Treffer bei der Suche.'}
          menuPlacement={this.props.menuPlacement}
        />
        {validationMessage && <span className="dropdown-error-message">{this.props.errorMsg}</span>}
      </div>
    );
  }
}

export default Dropdown;

Dropdown.propTypes = {
  isSearchable: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.object,
  height: PropTypes.number,
  menuPlacement: PropTypes.string
};
