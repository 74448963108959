import React from 'react';
import './_style.scss';
import { envelopeSolidIcon } from '../../Assets/dynamic-svg/general-icons';
import { white } from '../../Utils/styleHelper';
import { addClass, removeClass } from '../../Utils/utils';

function AdminData(props) {
  const renderAdmins = (admin, index) => {
    return (
      <div className="admin-info" key={`admin-${index}`}>
        <p>
          {admin.lastName}, {admin.firstName}
        </p>
        <p>{admin.email}</p>
      </div>
    );
  };

  return (
    <div className="admin-data">
      <div
        className="admin-data-icon"
        onClick={() => {
          addClass('.admin-data-box', 'show-popup');
          addClass('.admin-data-icon', 'colored');
        }}>
        {envelopeSolidIcon(white)}
      </div>
      <div className="admin-data-box">
        <div
          className="close-box"
          onClick={() => {
            removeClass('.admin-data-box', 'show-popup');
            removeClass('.admin-data-icon', 'colored');
          }}>
          <span></span>
          <span></span>
        </div>
        <div className="content">
          <h2>Kontakt zum admin</h2>
          {props.adminUsers.map((admin, index) => {
            return renderAdmins(admin, index);
          })}
        </div>
      </div>
    </div>
  );
}

export default AdminData;
