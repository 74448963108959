import moment from 'moment';
import { getArray } from '../../Utils/gift';

export const getLatestGiftStatus = gift => {
  if (gift.status && gift.status.status) {
    const statusArray =  getArray(gift.status.status).sort((a, b) => moment(a.date).isAfter(moment(b.date)));
    return statusArray.length > 0 ? statusArray.pop().status : '';
  }
  return '';
};
