import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import UserMenu from './UserMenu';
import './_style.scss';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserMenuOpened: false
    };

    this.userMenuRef = undefined;
    this.profileBtnRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleUserMenu() {
    this.setState({
      isUserMenuOpened: !this.state.isUserMenuOpened
    });
  }

  closeUserMenu() {
    this.setState({
      isUserMenuOpened: false
    });
  }

  handleClickOutside(event) {
    /* handle user menu open - click outside */
    const userMenu = this.userMenuRef;
    if (userMenu && !userMenu.contains(event.target) && !this.profileBtnRef.current.contains(event.target)) {
      this.setState({ isUserMenuOpened: false });
    }
  }

  displayProfileName() {
    const userProps = this.props.userProfile;
    return userProps.firstName || userProps.lastName ? `${userProps.firstName} ${userProps.lastName}` : '';
  }

  displayProfileInitials() {
    const userProps = this.props.userProfile;
    return userProps.firstName || userProps.lastName ? `${userProps.firstName.slice(0, 1)}${userProps.lastName.slice(0, 1)}` : '?';
  }

  render() {
    return (
      <div>
        <button ref={this.profileBtnRef} className="profile-container" onClick={() => this.toggleUserMenu()}>
          <div className="full-name-container">
            <span className="user-name">{this.displayProfileName()}</span>
          </div>
          <div className={this.props.userInfo ? 'social-icon-container' : 'social-icon-container social-icon-container-api-error'}>
            <div className={this.state.isUserMenuOpened ? 'social-icon-button social-icon-blue-border' : 'social-icon-button'}>
              <div className="user-initials-container">{this.displayProfileInitials()}</div>
            </div>
          </div>
        </button>
        {this.state.isUserMenuOpened && (
          <UserMenu
            userMenuRef={ref => (this.userMenuRef = ref)}
            closeUserMenu={() => this.closeUserMenu()}
            userMenuActions={this.props.userMenuActions}
            userProfile={this.props.userProfile}
            siteid={this.props.siteid}
            event={this.props.event}
          />
        )}
      </div>
    );
  }
}

UserProfile.propTypes = {
  userInfo: PropTypes.object,
  userProfile: PropTypes.object,
  userMenuActions: PropTypes.array,
  siteid: PropTypes.string,
  event: PropTypes.string,
};

export default UserProfile;
