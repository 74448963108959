import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from '../../src/Assets/fonts/vfs_fonts';
import { isEmpty, formatNumber } from '../sma-shared/Utils/utils';
import moment from 'moment';
import { receiverType, reasonOptions, GIFT_VALUE_TYPE } from './constants';
import {
  GESCHENKFALLS_TITLE_CARD,
  GIFT_TITLE_CARD,
  RECEIVER_TITLE_CARD,
  COMPANY_TEXT,
  DESCRIPTION_GIFT_TEXT,
  DESCRIPTION_OCCASION_TEXT,
  EMAIL_TEXT,
  FIRST_NAME_TEXT,
  LAST_NAME_TEXT,
  REASON_TEXT_SIMPLE,
  INTERNAL_INVOICE_NUMBER_TEXT,
  INVOICE_NUMBER_TEXT,
  GIFTVALUE_TEXT,
  GIFTDATE_TEXT,
  NET_VALUE_TEXT,
  GROSS_VALUE_TEXT,
  PERSONAL_ID
} from '../Pages/CreateGiftPages/giftCaseLabels';
import { getArray } from './gift';

pdfMake.fonts = {
  HaufeMerriweatherSans: {
    normal: 'HaufeMerriweatherSans.ttf',
    bold: 'HaufeMerriweatherSans-Bold.ttf',
    italics: 'HaufeMerriweatherSans-Italic.ttf',
    bolditalics: 'HaufeMerriweatherSans-BdItalic.ttf'
  },
  HaufeMerriweatherSansLight: {
    normal: 'HaufeMerriweatherSansLt.ttf',
    bold: 'HaufeMerriweatherSans-Bold.ttf',
    italics: 'HaufeMerriweatherSansLt-Italic.ttf',
    bolditalics: 'HaufeMerriweatherSans-BdItalic.ttf'
  }
};

function cm2pt(cm) {
  return (parseFloat(cm) * 10 * 72) / 25.4;
}

function createLogo() {
  const logo = `<svg width="50" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333.3 104.9">
  <g fill="#000000">
  <path d="M156.8,66.5c-15,0-19.6-4-19.6-9.1l0-18.2h9.9v17.2c0,4.2,4.4,5.3,10.2,5.3c4,0,7-0.1,9.2-0.4V39.1h9.9v26.1
  C172.6,65.9,165.2,66.5,156.8,66.5z"/>
<polygon points="68.4,65.8 68.4,54.8 49,54.8 49,65.8 39.1,65.8 39.1,39.1 49,39.1 49,50 68.4,50 68.4,39.1 78.3,39.1 78.3,65.8
      "/>
<path d="M198.6,44.5h26.6v-5.3h-28.7c-7.8,0-10.5,1.4-10.5,5.4v21.3h9.9l0-11.2h29.3v-4.7h-29.3l0-3.8
  C195.9,45.1,196.5,44.5,198.6,44.5z"/>
<path d="M245.5,44.5c-2.1,0-2.7,0.7-2.7,1.6l0,3.9h29.3v4.6h-29.3l0,4.3c0,1,0.6,1.6,2.7,1.6l26.6,0v5.3h-28.7
  c-7.8,0-10.5-1.4-10.5-5.4V44.5c0-4,2.7-5.4,10.5-5.4h28.7v5.3H245.5z"/>
<path d="M282,60.8c0-1.3,0.8-1.8,3.1-1.8h3.9c2.3,0,3.1,0.5,3.1,1.8V64c0,1.3-0.8,1.8-3.1,1.8h-3.9c-2.3,0-3.1-0.5-3.1-1.8V60.8z"
  />
<path style="fill-rule:evenodd;clip-rule:evenodd;" d="M108.1,49.4c-6.3,0-12,0.6-15.9,1.3c-2.8,0.5-4.1,1.5-4.1,3.9v6.7
  c0,2.4,1.2,3.5,4.1,3.9c3.9,0.7,9.6,1.2,15.9,1.3c8.3,0,15.5-0.6,19.1-1.2V48c0-8.3-3.2-8.8-14.1-8.8H91.2v4.6h21.7
  c4.1,0,4.4,1.1,4.4,3.6v2.1H108.1z M108.1,62c-3.1,0-6.1-0.2-8.1-0.6c-1.4-0.2-2-0.8-2-1.9v-3.1c0-1.2,0.6-1.7,2-1.9
  c2-0.4,5-0.6,8.1-0.6h9.2v7.9C115.5,61.9,111.8,62,108.1,62L108.1,62z"/>
<rect x="0" y="0" style="fill:none;" width="333.3" height="104.9"/>
  </g>
</svg>`;
  return logo;
}

function createLine(width = 485, height = 10, strokeWidth = 0.5) {
  const svg = `<svg width="${width}" height="${height}" >
                <line x1="0" y1="${height / 2}" x2="${width}" y2="${height / 2}" stroke="#C2C6CC" stroke-width="${strokeWidth}" />
              </svg>`;
  return svg;
}

const getField = fieldValue => {
  if (!isEmpty(fieldValue)) {
    return fieldValue;
  } else {
    return '\n';
  }
};

//Document Header
const documentHeader = gift => {
  return [
    {
      text: GESCHENKFALLS_TITLE_CARD,
      style: 'headingStyle'
    },
    {
      text: '',
      style: 'headingEnd'
    }
  ];
};

//Get Sender
const getSenderHeader = gift => {
  const statuses = getArray(gift.status.status)
  const content = [];

  if (statuses.find(({ status }) => status === 'draft')) {
    const result = statuses.find(({ status }) => status === 'draft');
    content.push({ text: `Abgesendet am ${moment(result.date).format('DD.MM.YYYY')} von ${result.name}`, style: 'processData' });
  }
  return content;
};
//Get Approver
const getApproverHeading = gift => {
  const statuses = getArray(gift.status.status);
  const content = [];

  if (statuses.find(({ status }) => status === 'approved')) {
    const result = statuses.find(({ status }) => status === 'approved');
    content.push({ text: `Genehmigt am ${moment(result.date).format('DD.MM.YYYY')} von ${result.name}`, style: 'processData' });
  }
  return content;
};
//Get Financial Accouting
const getFibuHeading = gift => {
  const statuses = getArray(gift.status.status);
  const content = [];
  if (statuses.find(({ status }) => status === 'payrollProcessing')) {
    const result = statuses.find(({ status }) => status === 'payrollProcessing');
    content.push({
      text: `Bearbeitet von der Fibu am ${moment(result.date).format('DD.MM.YYYY')} von ${result.name} `,
      style: 'processData'
    });
  }
  return content;
};
//Get Payroll
const getPayrollHeading = gift => {
  const statuses = getArray(gift.status.status);
  const content = [];
  if (statuses.find(({ status }) => status === 'completed')) {
    const result = statuses.find(({ status }) => status === 'completed');
    content.push({
      text: `Bearbeitet von der Entgeltabrechnung am ${moment(result.date).format('DD.MM.YYYY')} von ${result.name} `,
      style: 'processData'
    });
  }
  return content;
};

//Receiver Data
const receriverData = gift => {
  const content = [];
  if (!isEmpty(gift.receiver)) {
    content.push({ text: RECEIVER_TITLE_CARD, style: 'headline' }, { text: '', style: 'subheadline' });

    const receivers = getArray(gift.receiver.receivers);
    receivers.forEach(receiver => {
      content.push({
        columns: [
          { text: FIRST_NAME_TEXT, style: 'plaintextWithMargin' },
          { text: getField(receiver.firstName), style: 'plaintextWithMargin', alignment: 'right' }
        ]
      });
      content.push({
        svg: createLine()
      });
      content.push({
        columns: [
          { text: LAST_NAME_TEXT, style: 'plaintextWithMargin' },
          { text: getField(receiver.lastName), style: 'plaintextWithMargin', alignment: 'right' }
        ]
      });
      content.push({
        svg: createLine()
      });
      if (gift.entryCheck.receiverType === receiverType.bussinesPartner.value) {
        content.push({
          columns: [
            { text: COMPANY_TEXT, style: 'plaintextWithMargin' },
            { text: getField(receiver.company), style: 'plaintextWithMargin', alignment: 'right' }
          ]
        });
      }
      if (gift.entryCheck.receiverType === receiverType.employee.value) {
        content.push({
          columns: [
            { text: PERSONAL_ID, style: 'plaintextWithMargin' },
            { text: getField(receiver.personalId), style: 'plaintextWithMargin', alignment: 'right' }
          ]
        });
      }
      content.push({
        svg: createLine()
      });
      content.push({
        columns: [
          { text: EMAIL_TEXT, style: 'plaintextWithMargin' },
          { text: getField(receiver.email), style: 'plaintextWithMargin', alignment: 'right' }
        ]
      });
      content.push(
        {
          svg: createLine()
        },
        {
          text: '',
          style: 'headline'
        }
      );
    });
  }
  return content;
};

const giftData = gift => {
  const content = [];
  content.push(
    {
      text: GIFT_TITLE_CARD,
      style: 'headline'
    },
    {
      text: '',
      style: 'subheadline'
    }
  );

  content.push(
    {
      columns: [
        { text: REASON_TEXT_SIMPLE, style: 'plaintextWithMargin' },
        {
          text:
            getField(gift.gift.giftReason) && getField(gift.gift.giftReason) in reasonOptions
              ? reasonOptions[getField(gift.gift.giftReason)].label
              : '',
          style: 'plaintextWithMargin',
          alignment: 'right'
        }
      ]
    },
    {
      svg: createLine()
    }
  );

  content.push(
    {
      columns: [
        { text: DESCRIPTION_OCCASION_TEXT, style: 'plaintextWithMargin' },
        { text: getField(gift.gift.giftDescriptionOccasion), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );

  content.push(
    {
      columns: [
        { text: DESCRIPTION_GIFT_TEXT, style: 'plaintextWithMargin' },
        { text: getField(gift.gift.giftDescription), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );

  content.push(
    {
      columns: [
        { text: GIFTDATE_TEXT, style: 'plaintextWithMargin' },
        { text: moment(getField(gift.gift.giftDate)).format('DD.MM.YYYY'), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );

  content.push(
    {
      columns: [
        {
          text: GIFTVALUE_TEXT(GIFT_VALUE_TYPE[gift.gift.giftValueType] ? GIFT_VALUE_TYPE[gift.gift.giftValueType].label : ''),
          style: 'plaintextWithMargin'
        },
        { text: getField(formatNumber(gift.gift.giftValue)), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );
  content.push(
    {
      columns: [
        { text: NET_VALUE_TEXT, style: 'plaintextWithMargin' },
        { text: getField(formatNumber(gift.invoice.netValue)), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );
  content.push(
    {
      columns: [
        { text: GROSS_VALUE_TEXT, style: 'plaintextWithMargin' },
        { text: getField(formatNumber(gift.invoice.grossValue)), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );

  content.push(
    {
      columns: [
        { text: INTERNAL_INVOICE_NUMBER_TEXT, style: 'plaintextWithMargin' },
        { text: getField(gift.invoice.internalNumber), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );

  content.push(
    {
      columns: [
        { text: INVOICE_NUMBER_TEXT, style: 'plaintextWithMargin' },
        { text: getField(gift.invoice.invoiceNumber), style: 'plaintextWithMargin', alignment: 'right' }
      ]
    },
    {
      svg: createLine()
    }
  );

  return content;
};

function createPDF(gift) {
  const fontSizeHeadline = 12;
  const fontSize = 10;
  const marginLeft = cm2pt(2);
  const marginTop = cm2pt(3);
  const marginRight = cm2pt(2);
  const marginBottom = cm2pt(2);

  let content = [];

  content = [];
  content.push(documentHeader(gift));
  content.push(getSenderHeader(gift));
  content.push(getApproverHeading(gift));
  content.push(getFibuHeading(gift));
  content.push(getPayrollHeading(gift));
  content.push(receriverData(gift));
  content.push(giftData(gift));

  const docDefinition = {
    content,
    header: function (currentPage) {
      return [
        {
          text: 'Bericht zum Geschenkefall',
          style: 'pageTitle'
        }
      ];
    },
    footer: function (currentPage, pageCount) {
      return [
        {
          svg: createLine(),
          margin: [marginLeft, 0, marginLeft, 5]
        },
        {
          columns: [
            {
              svg: createLogo(),
              width: 75,
              margin: [-10, -5, 0, 0]
            },
            { text: 'Haufe-Lexware GmbH & Co. KG', alignment: 'left' },
            { text: `Seite ${currentPage}/${pageCount}`, alignment: 'right' }
          ],
          columnGap: 0,
          margin: [marginLeft, 0, marginLeft, 0]
        }
      ];
    },
    defaultStyle: {
      font: 'HaufeMerriweatherSans',
      fontSize,
      lineHeight: 1.2,
      bold: false
    },
    styles: {
      pageTitle: {
        fontSize: fontSizeHeadline * 2,
        lineHeight: 2,
        alignment: 'left',
        margin: [marginLeft, 20, 0, 0]
      },
      headingStyle: {
        fontSize: fontSizeHeadline * 1.2,
        bold: true
      },
      headingEnd: {
        fontSize: fontSizeHeadline,
        margin: [0, 0, 0, 10]
      },
      subHeader: {
        fontSize: fontSizeHeadline * 1.5,
        lineHeight: 1.2,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      },
      headline: {
        fontSize: fontSizeHeadline,
        lineHeight: 1.2,
        bold: true,
        margin: [0, 15, 0, 0]
      },
      subheadline: {
        fontSize,
        lineHeight: 1.2,
        margin: [0, 0, 0, 10]
      },
      paragraph: {
        fontSize,
        lineHeight: 1.2,
        margin: [0, 0, 0, 15],
        bold: false
      },
      plaintext: {
        fontSize,
        lineHeight: 1.2,
        margin: [0, 0, 0, 0],
        bold: false
      },
      plaintextLight: {
        font: 'HaufeMerriweatherSansLight'
      },
      plaintextWithMargin: {
        margin: [10, 0, 10, 0]
      },
      plaintextLightWithMargin: {
        font: 'HaufeMerriweatherSansLight',
        margin: [10, 0, 10, 0]
      },
      list: {
        fontSize,
        lineHeight: 1.2,
        margin: [20, 0, 0, 0],
        bold: false
      },
      spacer: {
        margin: [0, 0, 0, 15]
      },
      spaced: {
        fontSize: fontSizeHeadline,
        lineHeight: 1.2,
        bold: true,
        margin: [0, 10, 0, 5]
      },
      label: {
        fontSize,
        lineHeight: 1.2,
        bold: true
      },
      blankLine: {
        fontSize,
        lineHeight: 1.2
      },
      link: {
        color: '#0563C1',
        bold: true
      },
      italic: {
        italics: true
      },
      footer: {
        fontSize: 10,
        margin: [marginLeft, 5, marginRight, 0]
      },
      footerRight: {
        alignment: 'right'
      }
    },
    pageSize: 'A4',
    pageMargins: [marginLeft, marginTop, marginRight, marginBottom]
  };

  return docDefinition;
}

function generatePdfReport(gift) {
  const docDefinition = createPDF(gift);
  pdfMake.vfs = vfsFonts.vfs;
  return pdfMake.createPdf(docDefinition);
}

function downloadReport(gift, title = 'Bericht') {
  const pdfGenerator = generatePdfReport(gift);
  pdfGenerator.download(`${title}.pdf`);
}

function printReport(rootStore, exportTasksDescription) {
  const pdfGenerator = generatePdfReport(rootStore, exportTasksDescription);
  pdfGenerator.print();
}

export { downloadReport, printReport };
