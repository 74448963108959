import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { inject, observer } from 'mobx-react';
import closeIcon from '../../Assets/svg/close-icon-grey.svg';
import './_style.scss';

class ModalPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.close = this.close.bind(this);
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.props.rootStore.modalStore.modalVisible && this.props.rootStore.modalStore.primaryButton) {
        this.props.rootStore.modalStore.primaryButton.focus();
      }
    }, 100);
  }

  close() {
    this.props.rootStore.modalStore.hideModal();
  }

  render() {
    const { modalStore } = this.props.rootStore;
    const className = modalStore.secondaryModalClassName
      ? `modal-confirm ${modalStore.secondaryModalClassName}`
      : modalStore.modalClassName
      ? `modal-confirm ${modalStore.modalClassName}`
      : 'modal-confirm';
    return (
      <Modal
        isOpen={modalStore.modalVisible}
        shouldCloseOnOverlayClick={modalStore.shouldCloseOnOverlayClick}
        onRequestClose={this.close}
        className={className}
        overlayClassName="backdrop"
        ariaHideApp={false}>
        <div className={modalStore.secondaryContent ? 'modal-content-container saved' : 'modal-content-container'}>
          <button className="close" onClick={this.close}>
            <img alt="" src={closeIcon} />
          </button>
          {modalStore.title && <div className="modal-header-container">{modalStore.title}</div>}
          {modalStore.content}
        </div>
        {modalStore.buttons && (
          <div className={modalStore.secondaryContent ? 'modal-button-container saved' : 'modal-button-container'}>
            {modalStore.buttons}
          </div>
        )}
        {modalStore.secondaryContent && (
          <div className="modal-content-container">
            {modalStore.secondaryTitle && <div className="modal-header-container">{modalStore.secondaryTitle}</div>}
            {modalStore.secondaryContent}
          </div>
        )}
        {modalStore.secondaryButtons && <div className="modal-button-container">{modalStore.secondaryButtons}</div>}{' '}
      </Modal>
    );
  }
}

ModalPopup.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject('rootStore')(observer(ModalPopup));
