import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { getObjectByPropValueFromArray, isEmpty } from '../../../sma-shared/Utils/utils';
import { sstTrackingHelper } from '../../../Utils/utils';
import { WORKFLOW } from '../WorkflowNavigation/workflow';
import { GIFTCASE_APPROVAL_PATH } from '../../../Utils/paths';
import { areTooManyApproverChanges, getFullName, scrollIntoViewById } from '../../../Utils/utils';
import InputCard, { INPUT_CARD_TYPE } from '../../../Components/InputCard';
import './_style.scss';
import { receiverType, status, STATUS_MESSAGE_APPROVAL, actions, activeArea } from '../../../Utils/constants';
import RequestApproval from './requestApproval';
import SelfApproval from './selfApproval';
import { copyIcon } from '../../../Assets/dynamic-svg/general-icons';
import { royalBlue } from '../../../Utils/styleHelper';
import SideBar from '../SideBar';
import {
  APPROVER_TITLE_CARD,
  INFO_FOR_EMPFANGER_COPYTEXT,
  INFO_FOR_EMPFANGER_TITLE,
  STATUS_DER_GENEHMIGUNG_INFO,
  STATUS_DER_GENEHMIGUNG_TITLE,
  COMMENT_FROM_SENDER,
  COMMENT_FROM_APPROVER,
  COMMENT,
  APPROVED_BY,
  REQUEST_APPROVED,
  REQUEST_SELF_APPROVED,
  SELF_APPROVED,
  GET_APPROVAL,
  SEND_REMINDER,
  REQUEST_AGAIN,
  REJECTED_REQUEST,
  ACCEPT_REQUEST,
  COPY_TEXT_TO_CLIPBOARD,
  CHECK_CASE_DATA_RIGHT,
  messageNotificationSender,
  messageNotificationApprover,
  INFO_FOR_EMPFANGER,
  INFO_TOO_MANY_APPROVER_CHANGES
} from '../giftCaseLabels';
import { copyToClipboard, generateGtmEventName } from '../../../Utils/utils';
import { helpApprovalText } from '../../../HelpText/createGiftHelpText';

function Gift(props) {
  const { createGiftStore, giftDataStore, apiStore, modalStore, helpTextStore } = props.rootStore;
  const { userInfo } = apiStore;
  const [currentStatus, setCurrentStatus] = useState();
  const [approverEmail, setApproverEmail] = useState();
  const [statusMessage, setStatusMessage] = useState();
  const [statusCommentTitle, setCommentTitle] = useState('');
  const [statusComment, setStatusComment] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    createGiftStore.currentWorkflowStep = getObjectByPropValueFromArray(WORKFLOW, 'path', GIFTCASE_APPROVAL_PATH);
    createGiftStore.currentWorkflowStep.validate = validateStep;
    if (giftDataStore.receiverType === receiverType.bussinesPartner.value && currentStatus === status.approved) {
      helpTextStore.setHelpText(helpApprovalText);
    }
    return () => {
      helpTextStore.resetHelpText();
    };
    // eslint-disable-next-line
  }, []);

  const validateStep = () => {
    if (currentStatus !== status.approvalRequest) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (giftDataStore.status) {
      const lastApprovalStatus = giftDataStore.status
        .filter(
          item =>
            item.status === status.draft ||
            item.status === status.approvalRequest ||
            item.status === status.approved ||
            item.status === status.rejected
        )
        .pop();
      const currentStatusValue = lastApprovalStatus ? lastApprovalStatus.status : '';
      const approver = lastApprovalStatus && giftDataStore.approvalRequestTo !== userInfo.Email ? lastApprovalStatus.email : '';
      const message =
        !lastApprovalStatus ||
        lastApprovalStatus.status === status.draft ||
        (lastApprovalStatus.status === status.approvalRequest && giftDataStore.approvalRequestTo === userInfo.Email)
          ? STATUS_MESSAGE_APPROVAL['']
          : `${STATUS_MESSAGE_APPROVAL[lastApprovalStatus.status]} am ${moment(lastApprovalStatus.date).format('DD.MM.YYYY')}`;

      const title =
        currentStatusValue === status.approved || currentStatusValue === status.rejected || currentStatusValue === status.approvalRequest
          ? giftDataStore.approvalRequestTo === userInfo.Email
            ? COMMENT_FROM_SENDER
            : COMMENT_FROM_APPROVER
          : '';

      const comment =
        lastApprovalStatus &&
        (currentStatusValue === status.approved || currentStatusValue === status.rejected) &&
        giftDataStore.approvalRequestTo !== userInfo.Email
          ? getStatusValue(status.approved, 'comment') || getStatusValue(status.rejected, 'comment') //me as sender take the comment from approved/reject
          : giftDataStore.senderEmail !== userInfo.Email
          ? getStatusValue(status.approvalRequest, 'comment')
          : ''; // me as approver take the comment from approval request
      setCurrentStatus(currentStatusValue);
      setCommentTitle(title);
      setStatusComment(comment);
      setApproverEmail(approver);
      setStatusMessage(message);
      createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    }
    // eslint-disable-next-line
  }, [giftDataStore.status]);

  const getStatusValue = (statusValue, key) => {
    if (giftDataStore.status) {
      const statusItem = giftDataStore.getStatus(statusValue);
      if (statusItem) {
        return statusItem[key];
      }
    }
  };

  const renderStatusComment = () => {
    if (statusComment && statusCommentTitle) {
      return (
        <React.Fragment>
          <div className="line-separator" />
          <div className="comment-container">
            <div className="title">{statusCommentTitle}</div>
            <div className="comment">{statusComment}</div>
          </div>
        </React.Fragment>
      );
    }
  };

  const renderApprovalMessageCard = () => {
    if (currentStatus) {
      const cardElements = [];
      if (userInfo.Email === giftDataStore.approvalRequestTo) {
        cardElements.push({
          type: INPUT_CARD_TYPE.label,
          label: messageNotificationApprover[currentStatus]
        });
      } else {
        if (approverEmail === userInfo.Email && currentStatus !== status.draft) {
          cardElements.push({
            type: INPUT_CARD_TYPE.label,
            label: messageNotificationSender.selfApproved
          });
        } else {
          cardElements.push({
            type: INPUT_CARD_TYPE.label,
            label: messageNotificationSender[currentStatus]
          });
        }
      }

      if (currentStatus.approved) {
        cardElements.push({
          type: INPUT_CARD_TYPE.label,
          label: giftDataStore.getStatus(status.approved) ? `${APPROVED_BY}: ${giftDataStore.getStatus(status.approved).email}` : ''
        });
      }

      cardElements.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderStatusLabel()
      });
      if (currentStatus === status.approvalRequest && giftDataStore.approvalRequestTo === userInfo.Email) {
        cardElements.push({
          type: INPUT_CARD_TYPE.textArea,
          label: COMMENT,
          isMandatory: false,
          placeholder: ' ',
          value: comment,
          onChange: e => {
            setComment(e.target.value);
          }
        });
      }
      cardElements.push({
        type: INPUT_CARD_TYPE.label,
        label: currentStatus === status.draft ? STATUS_DER_GENEHMIGUNG_INFO : ''
      });
      cardElements.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderApprovalButtons()
      });

      return (
        <React.Fragment>
          <div className="line-separator" />
          <InputCard title={STATUS_DER_GENEHMIGUNG_TITLE} isMandatory data={cardElements} onClickHelpText={showHelpText} />
        </React.Fragment>
      );
    }
  };

  const changeApprover = () => {
    if (areTooManyApproverChanges(giftDataStore.numberOfApproverChange)) {
      modalStore.showConfirm('Warnung', INFO_TOO_MANY_APPROVER_CHANGES);
    } else {
      requestApproval();
    }
  };

  const renderApprovalButtons = () => {
    return (
      <div className="approval-buttons-area">
        {currentStatus === status.draft && (
          <React.Fragment>
            <button onClick={() => selfApproveRequest()} className="btn btn-transparent"
              data-gtmid={generateGtmEventName(activeArea.approval, actions.select, "SelfApproval")}>
              {SELF_APPROVED}
            </button>
            <button onClick={() => requestApproval()} className="btn btn-primary"
              data-gtmid={generateGtmEventName(activeArea.approval, actions.select, "ApprovalRequest")}>
              {GET_APPROVAL}
            </button>
          </React.Fragment>
        )}
        {currentStatus === status.approvalRequest && giftDataStore.approvalRequestTo !== userInfo.Email && (
          <React.Fragment>
            <button onClick={() => changeApprover()} className="btn btn-transparent">
              {REQUEST_AGAIN}
            </button>
            <button onClick={() => sendReminderAction()} className="btn btn-primary">
              {SEND_REMINDER}
            </button>
          </React.Fragment>
        )}
        {currentStatus === status.approvalRequest && giftDataStore.approvalRequestTo === userInfo.Email && (
          <React.Fragment>
            <button onClick={() => disapproveRequest()} className="btn btn-transparent">
              {REJECTED_REQUEST}
            </button>
            <button onClick={() => approveRequest()} className="btn btn-primary">
              {ACCEPT_REQUEST}
            </button>
          </React.Fragment>
        )}
      </div>
    );
  };
  const renderStatusLabel = () => {
    if (statusMessage) {
      return (
        <div className="status-label-area">
          <label className={`approval-status ${currentStatus}`}>{statusMessage}</label>
        </div>
      );
    }
  };

  const approvalStatusAction = (status, comment, callback) => {
    giftDataStore.status.push({
      status: status,
      name: getFullName(userInfo),
      email: userInfo.Email,
      date: new Date(),
      comment: comment
    });
    giftDataStore.lastComment = comment;

    giftDataStore.saveGift(
      () => {
        setCurrentStatus(status);
        setStatusMessage(`${STATUS_MESSAGE_APPROVAL[status]} am ${moment(new Date()).format('DD.MM.YYYY')}`);
        if (callback) {
          callback();
        }
      },
      { approvalStep: 'approval', action: status }
    );
  };

  const requestApproval = () => {
    let allUsedEmails = apiStore.gifts
      .filter(gifts => gifts.sender.email === apiStore.userInfo.Email)
      .map(gifts => gifts.approvalRequestTo);
    allUsedEmails = [...new Set(allUsedEmails.filter(email => !isEmpty(email)))].sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );

    return modalStore.showModal(
      REQUEST_APPROVED,
      <RequestApproval
        emailSuggestions={allUsedEmails}
        companyDomain={apiStore.settings.company.domain}
        closeModal={closeModal}
        sendToApprover={approvalRequestAction}
      />
    );
  };
  const closeModal = () => {
    modalStore.hideModal();
  };

  const approvalRequestAction = async (email, comment) => {
    modalStore.hideModal();
    giftDataStore.status = giftDataStore.status.filter(item => item.status !== status.approvalRequest);

    giftDataStore.status.push({
      status: status.approvalRequest,
      email,
      date: new Date(),
      comment,
      sender: userInfo.Email
    });
    giftDataStore.approvalRequestTo = email;
    giftDataStore.lastComment = comment;
    giftDataStore.saveGift(
      () => {
        setCurrentStatus(status.approvalRequest);
      },
      { approvalStep: 'approval-request' }
    );
  };

  const selfApproveRequest = () => {
    return modalStore.showModal(REQUEST_SELF_APPROVED, <SelfApproval closeModal={closeModal} selfApproveAction={selfApproveAction} />);
  };

  const selfApproveAction = comment => {
    modalStore.hideModal();
    approvalStatusAction(status.approved, comment);
    sstTrackingHelper('Falldaten', 'Case self approved', '/case-self-approved', apiStore.userInfo.isExternalUser);
  };

  const approveRequest = () => {
    approvalStatusAction(status.approved, comment);
    sstTrackingHelper('Falldaten', 'Case approved', '/case-approved', apiStore.userInfo.isExternalUser);
  };

  const disapproveRequest = () => {
    approvalStatusAction(status.rejected, comment);
    sstTrackingHelper('Falldaten', 'Case disaproved', '/case-disapproved', apiStore.userInfo.isExternalUser);
  };

  const sendReminderAction = () => {
    apiStore.sendReminderForApprovalRequests(giftDataStore.idFromDB);
  };

  const renderApprovedInfos = () => {
    if (
      currentStatus === status.approved &&
      giftDataStore.receiverType === receiverType.bussinesPartner.value &&
      giftDataStore.approvalRequestTo !== userInfo.Email
    ) {
      helpTextStore.setHelpText(helpApprovalText);
      const cardElements = [
        {
          type: INPUT_CARD_TYPE.label,
          label: INFO_FOR_EMPFANGER
        },
        {
          type: INPUT_CARD_TYPE.customElement,
          customElement: renderCopyableText()
        }
      ];
      return (
        <InputCard
          title={INFO_FOR_EMPFANGER_TITLE}
          data={cardElements}
          helpTextId={helpApprovalText && helpApprovalText.infoForReceiver ? helpApprovalText.infoForReceiver.id : ''}
          onClickHelpText={showHelpText}
        />
      );
    }
  };

  const renderCopyableText = () => {
    return (
      <div className="copyable-area">
        <span className="copyable-text" id="copyable-info-fur-empfanger">
          {INFO_FOR_EMPFANGER_COPYTEXT}
        </span>
        <button
          className="copy-text-button"
          onClick={e => {
            e.preventDefault();
            copyToClipboard(INFO_FOR_EMPFANGER_COPYTEXT);
          }}>
          {copyIcon(royalBlue)} <span>{COPY_TEXT_TO_CLIPBOARD}</span>
        </button>
      </div>
    );
  };

  const showHelpText = helpTextId => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  //Render Gift Value Card
  const renderGiftValueRecommendation = () => {
    const cardElements = [
      {
        type: INPUT_CARD_TYPE.customElement,
        customElement: <div className="recommendations">{ReactHtmlParser(giftDataStore.recommendation)}</div>
      }
    ];

    if (isSenderBeforeApprovalRequestStep() || isApproverBeforeApprovalStep()) {
      return <InputCard title={'Geschenkewert'} isMandatory data={cardElements} onClickHelpText={showHelpText} />;
    }
  };

  const isSenderBeforeApprovalRequestStep = () =>
    giftDataStore.senderEmail === userInfo.Email &&
    !giftDataStore.hasStatus(status.approved) &&
    !giftDataStore.hasStatus(status.approvalRequest);

  const isApproverBeforeApprovalStep = () =>
    giftDataStore.approvalRequestTo === userInfo.Email &&
    !giftDataStore.hasStatus(status.approved) &&
    !giftDataStore.hasStatus(status.rejected);

  return (
    <div className="page-with-sidebar">
      <div className="flex-container with-sidebar">
        <div className="create-gift-page-content">
          <div className="page-header">
            <span className="title">{APPROVER_TITLE_CARD}</span>
            <span className="subtitle">{CHECK_CASE_DATA_RIGHT}</span>
          </div>
          {renderStatusComment()}
          {renderApprovalMessageCard()}
          {renderApprovedInfos()}
          {renderGiftValueRecommendation()}
        </div>
        <div className="grey-container-with-sidebar"></div>
      </div>
      <SideBar showReceiver showGift showApproval showSender giftStatus={currentStatus} />
    </div>
  );
}

export default inject('rootStore')(observer(Gift));
