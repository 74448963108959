import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { inject, observer } from 'mobx-react';
import { getObjectByPropValueFromArray } from '../../../sma-shared/Utils/utils';
import { sstTrackingHelper } from '../../../Utils/utils';
import { WORKFLOW } from '../WorkflowNavigation/workflow';
import { GIFTCASE_PAYROLL_PATH } from '../../../Utils/paths';
import InputCard, { INPUT_CARD_TYPE } from '../../../Components/InputCard';
import SideBar from '../SideBar';
import { status, STATUS_MESSAGE_FIBU_PAYROLL, ROLES } from '../../../Utils/constants';
import {
  COMMENT_TITLE,
  PAYROLL_INFO_TEXT,
  PROCESSING_STATUS_TEXT,
  CHECK_CASE_DATA_RIGHT,
  COMPLETE_TASK,
  PAYROLL_PAGE_TITLE,
  PAYROLL_CASE_CLOSED
} from '../giftCaseLabels';
import ReactHtmlParser from 'react-html-parser';
import { getFullName, hasRole } from '../../../Utils/utils';

function Payroll(props) {
  const { createGiftStore, giftDataStore, apiStore } = props.rootStore;
  const { userInfo } = apiStore;
  const [payrollStatus, setPayrollStatus] = useState(giftDataStore.guidance ? giftDataStore.guidance.payrollGuidance : '');
  const [statusMessage, setStatusMessage] = useState('');
  const [payrollGuidance, setPayrollGuidance] = useState();
  const [financialComment, setFinancialComment] = useState('');

  useEffect(() => {
    createGiftStore.currentWorkflowStep = getObjectByPropValueFromArray(WORKFLOW, 'path', GIFTCASE_PAYROLL_PATH);
    createGiftStore.currentWorkflowStep.validate = () => {
      return true;
    };
  }, [createGiftStore]);

  useEffect(() => {
    if (giftDataStore.status) {
      const accountingStatus = giftDataStore.getStatus(status.payrollProcessing);
      const payrollStatus = giftDataStore.getStatus(status.completed);

      setFinancialComment(accountingStatus ? accountingStatus.comment : '');

      const message = !payrollStatus
        ? STATUS_MESSAGE_FIBU_PAYROLL.unfinished
        : `${STATUS_MESSAGE_FIBU_PAYROLL.finished} am ${moment(payrollStatus.date).format('DD.MM.YYYY')}`;

      setStatusMessage(message);
      setPayrollStatus(payrollStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.status]);

  useEffect(() => {
    setPayrollGuidance(giftDataStore.guidance ? giftDataStore.guidance.payrollGuidance : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.guidance]);

  const renderCommentSection = () => {
    return (
      <React.Fragment>
        <div className="line-separator" />
        <div className="comment-container">
          <div className="title">{COMMENT_TITLE}</div>
          <div className="comment">{financialComment}</div>
        </div>
      </React.Fragment>
    );
  };

  const renderGuidance = () => {
    if (payrollGuidance) {
      return (
        <React.Fragment>
          <div className="line-separator" />
          <div className="guidance-title">{PAYROLL_INFO_TEXT}</div>
          <div className="guidance-info">{ReactHtmlParser(payrollGuidance.info)}</div>
          {Object.entries(payrollGuidance.taxes).map(([key, value], index) => (
            <div
              className={`${index === Object.keys(payrollGuidance.taxes).length - 1 ? 'info-container last' : 'info-container'}`}
              key={uuidv4()}>
              <div className="title">{key}</div>
              <div className="info">{value}</div>
            </div>
          ))}
        </React.Fragment>
      );
    }
  };

  const renderPayrollMessageCard = () => {
    const cardElements = [];
    if (payrollStatus) {
      if (payrollStatus.status === status.completed) {
        cardElements.push({
          type: INPUT_CARD_TYPE.label,
          label: PAYROLL_CASE_CLOSED
        });
      }
    }

    cardElements.push({
      type: INPUT_CARD_TYPE.customElement,
      customElement: renderPayrollStatus()
    });

    if (hasRole(apiStore.roles, ROLES.admin) || hasRole(apiStore.roles, ROLES.payroll)) {
      cardElements.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderFinishButton()
      });
    }

    return (
      <React.Fragment>
        <div className="line-separator" />
        <InputCard title={PROCESSING_STATUS_TEXT} isMandatory data={cardElements} />
      </React.Fragment>
    );
  };

  const renderPayrollStatus = () => {
    return (
      <div className="status-label-area">
        <label className={`approval-status ${payrollStatus ? payrollStatus.status : ''}`}>{statusMessage}</label>
      </div>
    );
  };

  const renderFinishButton = () => {
    return (
      <div>
        {!payrollStatus ? (
          <div className="approval-buttons-area">
            <button onClick={() => finishPayrollWorkflow()} className="btn btn-primary">
              {COMPLETE_TASK}
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const finishPayrollWorkflow = () => {
    let newStatus;
    // it fibu already finished add complete status
    if (giftDataStore.getStatus(status.payrollProcessing)) {
      newStatus = {
        status: status.completed,
        name: getFullName(userInfo),
        email: userInfo.Email,
        date: new Date(),
        comment: ''
      };
      giftDataStore.status.push(newStatus);
      sstTrackingHelper('Falldaten', 'Case closed', '/case-closed', apiStore.userInfo.isExternalUser);
    }

    giftDataStore.saveGift(
      () => {
        setStatusMessage(`${STATUS_MESSAGE_FIBU_PAYROLL.finished} am ${moment(new Date()).format('DD.MM.YYYY')}`);
        setPayrollStatus(newStatus);
      },
      {
        approvalStep: 'complete'
      }
    );
  };

  return (
    <div className="page-with-sidebar">
      <div className="flex-container with-sidebar">
        <div className="payroll-container create-gift-page-content">
          <div className="page-header">
            <span className="title">{PAYROLL_PAGE_TITLE}</span>
            <span className="subtitle">{CHECK_CASE_DATA_RIGHT}</span>
          </div>
          {financialComment ? renderCommentSection() : ''}
          {renderGuidance()}
          {renderPayrollMessageCard()}
        </div>
        <div className="grey-container-with-sidebar"></div>
      </div>
      <SideBar showSender showReceiver showGift showApproval />
    </div>
  );
}

export default inject('rootStore')(observer(Payroll));
