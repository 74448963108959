import React from "react";
import "./_style.scss";
import TextInput from "../TextInput";
import PasswordInput from "../PasswordInput";
import CheckboxButton from "../CheckboxButton";
import RadioButton from "../RadioButton";
import TextArea from "../TextArea";
import FileInput from "../FileInput";
import FileAttachments from "../FileAttachments";
import FileUpload from "../FileUpload";
import AutosuggestInput from "../AutosuggestInput";
import DropdownInput from "../DropdownInput";
import DatepickerInput from "../DatepickerInput";
import QuestionInput from "../QuestionInput";
import Label from "../Label";
import { infoIcon } from "../../Assets/dynamic-svg/general-icons";
import { blackPearl } from "../../Utils/styleHelper";
import { getGtmIdInfoFromText, generateGtmEventName } from "../../Utils/utils";

const defaultPlaceholder = "Bitte auswählen…";

function InputCard(props) {
  const gtmInfo = getGtmIdInfoFromText(props.helpTextId, props.data?.length);

  return (
    <div
      className={`input-card-container${
        props.isMandatory ? " mandatory" : ""
      } ${props.disabled ? " disabled" : ""}`}
    >
      <div style={{ display: "flex" }}>
        <span className="title">{props.title}</span>
        {props.helpTextId && (
          <span
            className="icon-general"
            data-gtmid={gtmInfo.activeArea && generateGtmEventName(gtmInfo.activeArea, gtmInfo.action, gtmInfo.description)}>
            {infoIcon(blackPearl)}
          </span>
        )}
      </div>

      {props.data.map((item, index) => {
        switch (item.type) {
          case INPUT_CARD_TYPE.text: {
            return (
              <TextInput
                isMandatory={
                  item.isMandatory !== undefined
                    ? item.isMandatory
                    : props.isMandatory
                }
                key={`${item.label}-text-input${index}`}
                label={item.label}
                placeholder={
                  item.placeholder ? item.placeholder : defaultPlaceholder
                }
                onChange={item.onChange}
                onKeyDown={item.onKeyDown}
                onClick={item.onClick}
                onBlur={item.onBlur}
                isInvalid={item.isInvalid}
                errorMsg={item.errorMsg}
                value={item.value}
                disabled={item.disabled}
                pattern={item.pattern}
                gtmInfo={item.gtmInfo}
              />
            );
          }
          case INPUT_CARD_TYPE.password: {
            return (
              <PasswordInput
                isMandatory={
                  item.isMandatory !== undefined
                    ? item.isMandatory
                    : props.isMandatory
                }
                key={`${item.label}-text-input${index}`}
                label={item.label}
                placeholder={
                  item.placeholder ? item.placeholder : defaultPlaceholder
                }
                onChange={item.onChange}
                onClick={item.onClick}
                onBlur={item.onBlur}
                onKeyDown={item.onKeyDown}
                isInvalid={item.isInvalid}
                errorMsg={item.errorMsg}
                value={item.value}
                disabled={item.disabled}
                pattern={item.pattern}
                notAllowPaste={item.notAllowPaste}
              />
            );
          }
          case INPUT_CARD_TYPE.dropdown: {
            return (
              <DropdownInput
                isMandatory={props.isMandatory}
                label={item.label}
                key={`${item.label}-dropdown${index}`}
                placeholder={
                  item.placeholder ? item.placeholder : defaultPlaceholder
                }
                onClick={item.onClick}
                options={item.options}
                value={item.value}
                isInvalid={item.isInvalid}
                errorMsg={item.errorMsg}
                disabled={item.disabled}
              />
            );
          }
          case INPUT_CARD_TYPE.textArea: {
            return (
              <TextArea
                isMandatory={item.isMandatory}
                key={`${item.label}-text-area${index}`}
                label={item.label}
                value={item.value}
                placeholder={
                  item.placeholder ? item.placeholder : defaultPlaceholder
                }
                onClick={item.onClick}
                onChange={item.onChange}
                disabled={item.disabled}
              />
            );
          }
          case INPUT_CARD_TYPE.checkbox: {
            return (
              <CheckboxButton
                id={item.id}
                key={`${item.name}-checkbox${index}`}
                checked={item.checked}
                name={item.name}
                value={item.value}
                onChange={item.onChange}
                disabled={item.disabled}
                isInvalid={item.isInvalid}
                errorMsg={item.errorMsg}
              />
            );
          }
          case INPUT_CARD_TYPE.radioButton: {
            return (
              <RadioButton
                id={item.id}
                key={`${item.name}-radiobutton${index}`}
                label={item.label}
                checked={item.checked}
                name={item.name}
                value={item.value}
                onChange={item.onChange}
                onClick={item.onClick}
                disabled={item.disabled}
                isInvalid={item.isInvalid}
                errorMsg={item.errorMsg}
                gtmInfoLabel={item.label}
                gtmInfoValue={item.value}
              />
            );
          }
          case INPUT_CARD_TYPE.datepicker: {
            return (
              <DatepickerInput
                isMandatory={props.isMandatory}
                key={`${item.label}-text-area${index}`}
                label={item.label}
                placeholder={
                  item.placeholder ? item.placeholder : defaultPlaceholder
                }
                errorMsg={item.errorMsg}
                isInvalid={item.isInvalid}
                disabled={item.disabled}
                value={item.value}
                minDate={item.minDate}
                maxDate={item.maxDate}
                onBlur={item.onBlur}
                calendarStartDay={item.calendarStartDay}
              />
            );
          }
          case INPUT_CARD_TYPE.file: {
            return (
              <FileInput
                isMandatory={props.isMandatory}
                key={`${item.label}-file-input${index}`}
                label={item.label}
                placeholder={
                  item.placeholder ? item.placeholder : defaultPlaceholder
                }
                accept={item.accept}
                multiple={item.multiple}
                disabled={item.disabled}
                onChange={item.onChange}
              />
            );
          }
          case INPUT_CARD_TYPE.attachments: {
            return (
              <FileAttachments
                key={`file-attachments-${index}`}
                fileAttachments={item.fileAttachments}
                rootStore={item.rootStore}
                onDelete={item.onDelete}
              />
            );
          }
          case INPUT_CARD_TYPE.fileUpload: {
            return (
              <FileUpload
                isMandatory={props.isMandatory}
                key={`${item.label}-file-input${index}`}
                label={item.label}
                placeholder={
                  item.placeholder ? item.placeholder : defaultPlaceholder
                }
                accept={item.accept}
                multiple={item.multiple}
                disabled={item.disabled}
                notAllowToUpload={item.notAllowToUpload}
                notAllowToUploadMessage={item.notAllowToUploadMessage}
                fileAttachments={item.fileAttachments}
                rootStore={item.rootStore}
                uploadFile={item.uploadFile}
                deleteFile={item.deleteFile}
                downloadFile={item.downloadFile}
                step={item.step}
              />
            );
          }
          case INPUT_CARD_TYPE.question: {
            return (
              <QuestionInput
                isMandatory={item.isMandatory}
                id={item.id}
                key={`question-${index}`}
                label={item.label}
                value={item.value}
                errorMsg={item.errorMsg}
                isInvalid={item.isInvalid}
                itemChecked={item.itemChecked}
                disabled={item.disabled}
                onChange={item.onChange}
                onClick={item.onClick}
              />
            );
          }

          case INPUT_CARD_TYPE.customElement: {
            return (
              <React.Fragment key={`customElement-${index}`}>
                {item.customElement}
              </React.Fragment>
            );
          }
          case INPUT_CARD_TYPE.label: {
            return (
              <Label
                id={item.id}
                key={`${item.label}-label${index}`}
                label={item.label}
                value={item.value}
                isMandatory={item.isMandatory}
              />
            );
          }
          case INPUT_CARD_TYPE.autosuggestInput: {
            return (
              <AutosuggestInput
                id={item.id}
                key={`autosuggest-${index}`}
                label={item.label}
                errorMsg={item.errorMsg}
                isInvalid={item.isInvalid}
                isMandatory={item.isMandatory}
                suggestions={item.suggestions}
                onSuggestionsFetchRequested={item.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={item.onSuggestionsClearRequested}
                renderSuggestion={item.renderSuggestion}
                inputProps={item.inputProps}
              />
            );
          }
          default:
            return null;
        }
      })}
    </div>
  );
}

export const INPUT_CARD_TYPE = {
  text: "text",
  password: "password",
  dropdown: "dropdown",
  textArea: "textArea",
  checkbox: "checkbox",
  radioButton: "radioButton",
  datepicker: "datepicker",
  file: "file",
  attachments: "attachments",
  fileUpload: "fileUpload",
  question: "question",
  customElement: "customElement",
  label: "label",
  autosuggestInput: "autosuggestInput",
};

export default InputCard;
