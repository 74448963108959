import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import { isEmpty } from '../../sma-shared/Utils/utils';

const IS_FORMATTING = /(\[[a-zA-Z0-9-_#.=/]+\])/gi;

class FormattedText extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const textParts = this.props.text !== undefined || !isEmpty(this.props.text) ? this.props.text.split(IS_FORMATTING) : [];

    while (textParts.length > 0 && textParts[0].trim() === '') textParts.shift();
    while (textParts.length > 0 && textParts[textParts.length - 1].trim() === '') textParts.pop();

    // Check if the text is enclosed in a paragraph
    if (textParts.length > 0 && textParts[0] !== '[p]' && textParts[0] !== '[ul]' && textParts[0] !== '[ol]') {
      textParts.unshift('[p]');
    }
    if (
      textParts.length > 0 &&
      textParts[textParts.length - 1] !== '[/p]' &&
      textParts[textParts.length - 1] !== '[/ul]' &&
      textParts[textParts.length - 1] !== '[/ol]'
    ) {
      textParts.push('[/p]');
    }
    textParts.push('[end]');

    let elementKeyNr = 0;
    let paragraphText = '';
    let linkUrl = '';
    let linkText = '';

    let isList = false;
    let listEntry = [];
    let listEntries = [];

    return (
      <span className="formatted-text-container">
        {textParts.map(textPart => {
          elementKeyNr += 1;
          if (textPart.trim() === '' || textPart.trim() === '\r\n') {
            return null;
          }
          if (textPart.match(IS_FORMATTING)) {
            const formatting = textPart.substring(1, textPart.length - 1).toLowerCase();
            if (formatting === 'p') {
              paragraphText = '';
              return null;
            } else if (formatting === '/p') {
              // create a seperate elements for the paragraph
              const html = { __html: paragraphText };
              paragraphText = '';
              return (
                <React.Fragment key={elementKeyNr}>
                  <span dangerouslySetInnerHTML={html} />
                  <span className="paragraph" />
                </React.Fragment>
              );
            } else if (formatting.indexOf('link=') === 0) {
              // store the link url. The link will be created when then closing tag is found
              const linkData = formatting.split('=');
              linkUrl = linkData[1];

              // finish the current paragraph before the link starts
              if (paragraphText.trim().length > 0) {
                const html = { __html: paragraphText };
                paragraphText = '';
                if (isList) {
                  listEntry.push(<span key={elementKeyNr} dangerouslySetInnerHTML={html} />);
                  return null;
                }
                return <span key={elementKeyNr} dangerouslySetInnerHTML={html} />;
              }
              return null;
            } else if (formatting === '/link') {
              // create the link
              const url = linkUrl;
              const txt = linkText;
              linkUrl = '';
              linkText = '';
              if (isList) {
                // inside a list element then store all elements until end of list element is reached
                listEntry.push(
                  <span key={elementKeyNr} className="link" onClick={() => this.props.onLinkClicked(url)}>
                    {txt}
                  </span>
                );
                return null;
              }
              return (
                <span key={elementKeyNr} className="link" onClick={() => this.props.onLinkClicked(url)}>
                  {txt}
                </span>
              );
            } else if (formatting === 'ul' || formatting === 'ol') {
              listEntries = [];
              isList = true;
              if (paragraphText.trim().length > 0) {
                // finish the previous paragraph before starting the list
                const html = { __html: paragraphText };
                paragraphText = '';
                return (
                  <React.Fragment key={elementKeyNr}>
                    <span dangerouslySetInnerHTML={html} />
                    <span className="paragraph" />
                  </React.Fragment>
                );
              }
              return null;
            } else if (formatting === 'li') {
              listEntry = [];
              return null;
            } else if (formatting === '/li') {
              if (paragraphText.trim().length > 0) {
                // add the content of the list item to the listEntry
                const html = { __html: paragraphText };
                paragraphText = '';
                listEntry.push(<span key={elementKeyNr} dangerouslySetInnerHTML={html} />);
              }
              if (listEntry.length > 0) {
                // create the list item containing all elements of the listEntry
                listEntries.push(<li key={elementKeyNr}>{listEntry}</li>);
                listEntry = [];
              }
              return null;
            } else if (formatting === '/ul') {
              const entries = listEntries;
              listEntries = [];
              isList = false;
              // create the list containing all list items
              return <ul key={elementKeyNr}>{entries}</ul>;
            } else if (formatting === '/ol') {
              const entries = listEntries;
              listEntries = [];
              isList = false;
              // create the list containing all list items
              return <ol key={elementKeyNr}>{entries}</ol>;
            } else if (formatting === 'end') {
              // create a seperate elements for the paragraph
              if (paragraphText.trim().length > 0) {
                const html = { __html: paragraphText };
                return (
                  <React.Fragment key={elementKeyNr}>
                    <span dangerouslySetInnerHTML={html} />
                    <span className="paragraph" />
                  </React.Fragment>
                );
              }
              return null;
            }

            // add the tag to the current paragraph (which will be created when the closing tag is found)
            paragraphText += `<${formatting}>`;
            return null;
          }

          if (linkUrl) {
            // add the text to the current link
            linkText += textPart;
          } else {
            // add the text to the current paragraph
            paragraphText += textPart.replace('\n', '');
          }
          return null;
        })}
      </span>
    );
  }
}

FormattedText.propTypes = {
  onLinkClicked: PropTypes.func
};

export default FormattedText;
