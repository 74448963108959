import './style.scss';

import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { allowedHtmlTagsForCustomerInfo } from '../../Utils/constants';
import sanitizeHtml from 'sanitize-html';

function DataProtection(props) {
  const { apiStore } = props.rootStore;
  const [dataProtectionContent, setDataProtectionContent]  = useState(sanitizeHtml(apiStore.dataProtection, allowedHtmlTagsForCustomerInfo));

  useEffect(() => {
    setDataProtectionContent(sanitizeHtml(apiStore.dataProtection, allowedHtmlTagsForCustomerInfo));
  }, [apiStore.dataProtection]);

  window.scrollTo(0, 1);
  return (
    <div className="dataProtection" dangerouslySetInnerHTML={{ __html: dataProtectionContent }} /> // eslint-disable-line
  );
}

export default inject('rootStore')(observer(DataProtection));
