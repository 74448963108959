import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import RadioButton from '../RadioButton';

function QuestionInput(props) {
  return (
    <div className="text-input-container">
      <label htmlFor={props.label} className="question-input-label">
        <span>
          <span>{props.label}</span>
          {!props.isMandatory && <span className="optional-info">optional</span>}
        </span>
      </label>
      <RadioButton
        id={props.id + 'Yes'}
        key={`${props.label}-radiobuttonyes`}
        defaultValue={props.itemChecked === 'Ja'}
        checked={props.itemChecked === 'Ja'}
        label="Ja"
        value="Ja"
        name={props.id}
        onChange={props.onChange}
        onClick={props.onClick}
        disabled={props.disabled}
        isInvalid={props.isInvalid}
      />
      <RadioButton
        id={props.id + 'No'}
        key={`${props.label}-radiobuttonno`}
        defaultValue={props.itemChecked === 'Nein'}
        checked={props.itemChecked === 'Nein'}
        label="Nein"
        value="Nein"
        name={props.id}
        onChange={props.onChange}
        onClick={props.onClick}
        disabled={props.disabled}
        isInvalid={props.isInvalid}
      />
    </div>
  );
}

QuestionInput.propTypes = {
  props: PropTypes.object
};

export default QuestionInput;
