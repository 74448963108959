import React, { useState, useEffect } from 'react';
import './_style.scss';
import { inject, observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import RadioButton from '../../../Components/RadioButton';
import { getObjectByPropValueFromArray, isEmpty, roundToDecimals, validateDecimalAsText } from '../../../sma-shared/Utils/utils';
import { WORKFLOW } from '../WorkflowNavigation/workflow';
import { GIFTCASE_GIFT_PATH } from '../../../Utils/paths';
import InputCard, { INPUT_CARD_TYPE } from '../../../Components/InputCard';
import { reasonOptions, GIFT_VALUE_TYPE, firstDayLastYearDate, lastDayNextYearDate, MAX_FILES_TO_UPLOAD } from '../../../Utils/constants';
import {
  DESCRIPTION_OCCASION_TEXT,
  REASON_TEXT,
  DESCRIPTION_GIFT_TEXT,
  GIFTDATE_TEXT,
  GIFTVALUE_TEXT,
  INVOICE_NUMBER_TEXT,
  ADD_DOCUMENT_TEXT,
  PLEASE_CHOOSE_TEXT,
  PLEASE_ENTER_OCCASION_ERROR_TEXT,
  PLEASE_DESCRIPTION_OCCASION_ERROR_TEXT,
  PLEASE_ENTER_DESCRIPTION_ERROR_TEXT,
  PLEASE_ENTER_DATE_ERROR_TEXT,
  PLEASE_ENTER_VALUE_ERROR_TEXT,
  PLEASE_ENTER_INVOICE_NUMBER_ERROR_TEXT,
  INFO_GIFT_TEXT,
  INVOICE_INFORMATION
} from '../giftCaseLabels';
import { showErrorInView, scrollIntoViewById, getCorrectCentury } from '../../../Utils/utils';
import { helpTextGift } from '../../../HelpText/giftHelpText';

const decimalDigits = 2;

function Gift(props) {
  const { createGiftStore, helpTextStore, giftDataStore, apiStore } = props.rootStore;

  const [fileAttachments, setFileAttachments] = useState([]);
  const [giftDate, setGiftDate] = useState('');
  const [giftValue, setGiftValue] = useState(giftDataStore.giftValue);
  const [giftValueType, setGiftValueType] = useState(giftDataStore.giftValueType);

  const [isGiftReasonInvalid, setIsGiftReasonInvalid] = useState(false);
  const [isGiftDescriptionInvalid, setIsGiftDescriptionInvalid] = useState(false);
  const [isGiftDescriptionOccasionInvalid, setIsGiftDescriptionOccasionInvalid] = useState(false);
  const [isGiftDateInvalid, setIsGiftDateInvalid] = useState(false);
  const [isGiftValueInvalid, setIsGiftValueInvalid] = useState(false);

  useEffect(() => {
    createGiftStore.currentWorkflowStep = getObjectByPropValueFromArray(WORKFLOW, 'path', GIFTCASE_GIFT_PATH);
    createGiftStore.currentWorkflowStep.validate = validate;
    helpTextStore.setHelpText(helpTextGift);
    return () => {
      helpTextStore.resetHelpText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createGiftStore.giftDate) {
      setGiftDate(createGiftStore.giftDate);
    } else {
      setGiftDate(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createGiftStore.giftDate === '') {
      setGiftDate(Date.now());
    } else {
      setGiftDate(createGiftStore.giftDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDate]);

  useEffect(() => {
    giftDataStore.giftValueType = giftDataStore.giftValueType ? giftDataStore.giftValueType : GIFT_VALUE_TYPE.gross.value;
    setGiftValueType(giftDataStore.giftValueType);
  }, [giftDataStore, giftDataStore.giftValueType]);

  useEffect(() => {
    if (giftDataStore.invoiceAttachments !== null) {
      setFileAttachments(giftDataStore.invoiceAttachments);
      createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    }
    setGiftValue(giftDataStore.giftValue);
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.giftValueType, giftDataStore.invoiceAttachments]);

  const handleGiftValueChange = () => {
    const valueFormated = formatDisplayValue(giftValue);
    const decimalFormated = valueFormated === '' ? '' : valueFormated.toFixed(decimalDigits).toString().replace(/\./g, ',');
    if (giftDataStore.giftValueType === GIFT_VALUE_TYPE.gross.value) {
      giftDataStore.grossValue = decimalFormated;
      giftDataStore.netValue = '';
    } else if (giftDataStore.giftValueType === GIFT_VALUE_TYPE.net.value) {
      giftDataStore.netValue = decimalFormated;
      giftDataStore.grossValue = '';
    }
  };
  const validate = () => {
    const validatedResults = [];
    validatedResults.push(validateDate(giftDataStore.giftDate, setIsGiftDateInvalid));
    validatedResults.push(validateInput(giftDataStore.giftValue, setIsGiftValueInvalid));

    validatedResults.push(validateInput(giftDataStore.giftReason, setIsGiftReasonInvalid));
    validatedResults.push(validateInput(giftDataStore.giftDescription, setIsGiftDescriptionInvalid));
    validatedResults.push(validateInput(giftDataStore.giftDescriptionOccasion, setIsGiftDescriptionOccasionInvalid));

    const isValid = !validatedResults.some(value => value);
    if (!isValid) {
      showErrorInView();
    }
    createGiftStore.currentWorkflowValidating = !createGiftStore.currentWorkflowValidating;
    return isValid;
  };

  const validateInput = (value, setInvalidState, ignoreEmptyValue = false) => {
    const invalidState = ignoreEmptyValue ? false : isEmpty(value);
    setInvalidState(invalidState);
    return invalidState;
  };

  const validateDate = (value, setInvalidState, ignoreEmptyValue = false) => {
    const invalidState = value !== '' && value !== null && typeof value === 'object' ? false : true;
    setInvalidState(invalidState);
    return invalidState;
  };

  const validateValue = value => {
    // empty string, null, undefine it is an valid imput also as a number (empty value)
    if (!value) {
      return true;
    }

    let validateNumber = validateDecimalAsText(value);
    if (validateNumber && decimalDigits > 0) {
      validateNumber &= value.indexOf(',') !== 0;
      validateNumber &= value.indexOf(',') === -1 || value.length - value.indexOf(',') <= decimalDigits + 1; // not allow more decimal digits
      validateNumber &= value.indexOf('.') === -1; // allow only ',' as decimal digits
      validateNumber &= !(value.length >= 2 && value[0] === '0' && value[1] !== ','); // if first digit is '0' next one can be only ','
      return validateNumber;
    } else {
      return false;
    }
  };

  const formatDisplayValue = value => {
    if (value === undefined || value === '') {
      return '';
    }
    const number = roundToDecimals(value, decimalDigits);
    return number;
  };

  const renderValueTypeInput = () => (
    <div className="value-type-container">
      <RadioButton
        key={uuidv4()}
        label={GIFT_VALUE_TYPE.gross.label}
        checked={giftValueType === GIFT_VALUE_TYPE.gross.value}
        name="giftValueType"
        value={GIFT_VALUE_TYPE.gross.value}
        disabled={!giftDataStore.canEdit}
        onChange={e => {}}
        onClick={value => {
          setGiftValueType(value);
          giftDataStore.giftValueType = value;
          handleGiftValueChange();
        }}
      />

      <RadioButton
        key={uuidv4()}
        label={GIFT_VALUE_TYPE.net.label}
        checked={giftValueType === GIFT_VALUE_TYPE.net.value}
        name="giftValueType"
        value={GIFT_VALUE_TYPE.net.value}
        disabled={!giftDataStore.canEdit}
        onChange={e => {}}
        onClick={value => {
          setGiftValueType(value);
          giftDataStore.giftValueType = value;
          handleGiftValueChange();
        }}
      />
    </div>
  );

  const giftInputs = () => {
    const giftInputs = [];

    giftInputs.push({
      type: INPUT_CARD_TYPE.dropdown,
      label: REASON_TEXT,
      isMandatory: true,
      placeholder: PLEASE_CHOOSE_TEXT,
      value: giftDataStore.giftReason,
      isInvalid: isGiftReasonInvalid,
      disabled: !giftDataStore.canEdit,
      errorMsg: PLEASE_ENTER_OCCASION_ERROR_TEXT,
      options: Object.values(reasonOptions),
      onClick: value => {
        giftDataStore.giftReason = value;
        validateInput(value, setIsGiftReasonInvalid, true);
      }
    });

    giftInputs.push({
      type: INPUT_CARD_TYPE.text,
      label: DESCRIPTION_OCCASION_TEXT,
      isMandatory: true,
      placeholder: ' ',
      value: giftDataStore.giftDescriptionOccasion,
      isInvalid: isGiftDescriptionOccasionInvalid,
      disabled: !giftDataStore.canEdit,
      errorMsg: PLEASE_DESCRIPTION_OCCASION_ERROR_TEXT,
      onChange: e => {
        giftDataStore.giftDescriptionOccasion = e.target.value;
        validateInput(e.target.value, setIsGiftDescriptionOccasionInvalid, true);
      }
    });
    giftInputs.push({
      type: INPUT_CARD_TYPE.text,
      label: DESCRIPTION_GIFT_TEXT,
      isMandatory: true,
      placeholder: ' ',
      value: giftDataStore.giftDescription,
      isInvalid: isGiftDescriptionInvalid,
      disabled: !giftDataStore.canEdit,
      errorMsg: PLEASE_ENTER_DESCRIPTION_ERROR_TEXT,
      onChange: e => {
        giftDataStore.giftDescription = e.target.value;
        validateInput(e.target.value, setIsGiftDescriptionInvalid, true);
      }
    });
    giftInputs.push({
      type: INPUT_CARD_TYPE.datepicker,
      label: GIFTDATE_TEXT,
      isMandatory: true,
      value: giftDataStore.giftDate ? giftDataStore.giftDate : giftDate,
      isInvalid: isGiftDateInvalid,
      disabled: !giftDataStore.canEdit,
      errorMsg: PLEASE_ENTER_DATE_ERROR_TEXT,
      minDate: firstDayLastYearDate,
      maxDate: lastDayNextYearDate,
      calendarStartDay: 1,
      onBlur: date => {
        if (isNaN(date)) {
          if (date.split('.').length === 3 && date.split('.')[2] !== '') {
            const day = date.split('.')[0];
            const month = date.split('.')[1];
            let year = date.split('.')[2];
            if (year.length === 2) {
              year = getCorrectCentury(year);
            }
            date = new Date(year, month - 1, day);
            giftDataStore.giftDate = date;
          } else {
            giftDataStore.giftDate = '';
          }
        } else {
          if (typeof date === 'object') {
            giftDataStore.giftDate = date;
          }
        }
        validateDate(date, setIsGiftDateInvalid, true);
      }
    });

    giftInputs.push({
      type: INPUT_CARD_TYPE.customElement,
      customElement: renderValueTypeInput()
    });

    giftInputs.push({
      type: INPUT_CARD_TYPE.text,
      label: `${GIFTVALUE_TEXT(GIFT_VALUE_TYPE[giftValueType] ? GIFT_VALUE_TYPE[giftValueType].label : '')} `,
      isMandatory: true,
      placeholder: ' ',
      value: giftValue,
      isInvalid: isGiftValueInvalid,
      disabled: !giftDataStore.canEdit,
      errorMsg: PLEASE_ENTER_VALUE_ERROR_TEXT,
      onChange: e => {
        const isValidValue = validateValue(e.target.value);
        isValidValue ? setGiftValue(e.target.value) : setGiftValue(giftValue);
        validateInput(e.target.value, setIsGiftValueInvalid, true);
      },
      onBlur: e => {
        const valueFormated = formatDisplayValue(e.target.value);
        const decimalFormated = valueFormated === '' ? '' : valueFormated.toFixed(decimalDigits).toString().replace(/\./g, ',');
        setGiftValue(decimalFormated);
        giftDataStore.giftValue = decimalFormated;
        handleGiftValueChange();
      }
    });
    return giftInputs;
  };

  const invoiceInputs = () => [
    {
      type: INPUT_CARD_TYPE.text,
      label: INVOICE_NUMBER_TEXT,
      isMandatory: false,
      placeholder: ' ',
      value: giftDataStore.invoiceNumber,
      disabled: !giftDataStore.canEdit,
      errorMsg: PLEASE_ENTER_INVOICE_NUMBER_ERROR_TEXT,
      onChange: e => {
        giftDataStore.invoiceNumber = e.target.value;
        // currently no validation
      }
    },
    {
      step: createGiftStore.currentWorkflowStep.apiStep,
      type: INPUT_CARD_TYPE.fileUpload,
      label: ADD_DOCUMENT_TEXT,
      multiple: false,
      fileAttachments: fileAttachments,
      notAllowToUpload: giftDataStore.idFromDB === '',
      rootStore: props.rootStore,
      disabled: !giftDataStore.canEdit || fileAttachments.length >= MAX_FILES_TO_UPLOAD,
      uploadFile: async file => apiStore.uploadInvoiceAttachment(giftDataStore.idFromDB, file),
      deleteFile: async fileId => apiStore.deleteInvoiceAttachment(giftDataStore.idFromDB, fileId),
      downloadFile: async fileId => apiStore.downloadInvoiceAttachment(giftDataStore.idFromDB, fileId)
    }
  ];

  const showHelpText = helpTextId => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  return (
    <div className="gift-container flex-container">
      <div className="create-gift-page-content">
        <div className="page-header">
          <span className="title">Geschenk</span>
        </div>
        <InputCard
          title={INFO_GIFT_TEXT}
          helpTextId={helpTextGift && helpTextGift.giftInfo ? helpTextGift.giftInfo.id : ''}
          onClickHelpText={showHelpText}
          isMandatory
          data={giftInputs()}
        />
        <InputCard
          title={INVOICE_INFORMATION}
          helpTextId={helpTextGift && helpTextGift.invoiceInfo ? helpTextGift.invoiceInfo.id : ''}
          onClickHelpText={showHelpText}
          data={invoiceInputs()}
        />
      </div>
      <div className="grey-container-without-sidebar"></div>
    </div>
  );
}

export default inject('rootStore')(observer(Gift));
