import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import './_style.scss';
function AutosuggestInput(props) {
  const validationMessage = props.isInvalid && props.errorMsg;
  const getSuggestionValue = suggestion => suggestion;
  
  return (
    <div className="autosuggest-container" id={props.id}>
      <label htmlFor={props.label}>
        {props.label} {!props.isMandatory && <span className="optional-info">optional</span>}
      </label>
      <Autosuggest
        suggestions={props.suggestions}
        onSuggestionsFetchRequested={props.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={props.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={props.renderSuggestion}
        inputProps={props.inputProps}
      />
    {validationMessage && <span className="text-error-message">{props.errorMsg}</span>}
    </div>
  );
}

AutosuggestInput.defaultProps = {
  id: '',
  label: '',
  errorMsg: '',
  isInvalid: false,
  isMandatory: false,
  suggestions: [],
  inputProps: {},
};

AutosuggestInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  isInvalid: PropTypes.bool,
  isMandatory: PropTypes.bool,
  suggestions: PropTypes.array,
  onSuggestionsFetchRequested: PropTypes.func,
  onSuggestionsClearRequested: PropTypes.func,
  renderSuggestion: PropTypes.func,
  inputProps: PropTypes.object,
};

export default AutosuggestInput;
