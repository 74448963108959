import React from 'react';
import './_style.scss';
import { infoIcon } from '../../Assets/dynamic-svg/general-icons';

function ErrorSection(props) {
  if (props.errors.length > 0) {
    return (
      <div className="error-message-section">
        {props.errors.map((error, index) => {
          return (
            <div key={`error-other-tasks-${index}`}>
              <span className="icon-general info-icon">{infoIcon('#ffffff')}</span>
              <span>{error}</span>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
}

export default ErrorSection;
