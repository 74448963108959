export const ROOT_PATH = '/';
export const GENERAL_CASE_PAGE = '/';
export const SETTINGS_PATH = '/einstellungen';
export const EMPLOYEE_PATH = '/employees';
export const GIFTCASE_PATH = '/falldaten';
const LEGAL_PATH = '/legal';
export const DATA_PROTECTION_PATH = LEGAL_PATH + '/datenschutzerklaerung';
export const IMPRINT_PATH = LEGAL_PATH + '/impressum';
export const REGISTRATION = '/auth/register';
export const LOGIN = '/auth/login';
export const ADMIN_LOGIN = '/login';
export const RESET_PASSWORD = '/auth/password';
export const PAGE_NOT_FOUND = '/page-not-found';
export const ENTRY_CHECK_PATH_TEXT = 'einstiegs-check';
export const RECEIVER_PATH_TEXT = 'person';
export const GIFT_PATH_TEXT = 'geschenk';
export const APPROVAL_PATH_TEXT = 'genehmigung';
export const PAYROLL_PATH_TEXT = 'entgeltabrechnung';
export const FIBU_PATH_TEXT = 'fibu';
export const REPORT_PATH_TEXT = 'bericht';

export function OVERVIEW_PATH() {
  return '/';
}

export function GIFTCASE_ENTRYCHECK_PATH(id) {
  return getPath(ENTRY_CHECK_PATH_TEXT, id);
}
export function GIFTCASE_RECEIVER_PATH(id) {
  return getPath(RECEIVER_PATH_TEXT, id);
}
export function GIFTCASE_GIFT_PATH(id) {
  return getPath(GIFT_PATH_TEXT, id);
}
export function GIFTCASE_APPROVAL_PATH(id) {
  return getPath(APPROVAL_PATH_TEXT, id);
}
export function GIFTCASE_PAYROLL_PATH(id) {
  return getPath(PAYROLL_PATH_TEXT, id);
}
export function GIFTCASE_FIBU_PATH(id) {
  return getPath(FIBU_PATH_TEXT, id);
}
export function GIFTCASE_REPORT_PATH(id) {
  return getPath(REPORT_PATH_TEXT, id);
}

function getPath(path, id) {
  return `${GIFTCASE_PATH}/${path}${id ? '/' + id : ''}`;
}
