import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import moment from 'moment';

import { receiverType, GIFT_VALUE_TYPE, reasonOptions } from '../Utils/constants';
import { createLine, styleHeader, generateMerges } from './excelUtils';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToXLSX = csvData => {
  const preparedData = prepareData(csvData);
  const workingBook = XLSX.utils.book_new();

  const firstRow = [
    'Absender',
    '',
    'Empfänger',
    '',
    '',
    '',
    '',
    '',
    'Geschenk',
    '',
    '',
    '',
    '',
    '',
    'Genehmiger',
    '',
    'Status',
    'Rechnungsinformationen',
    '',
    '',
    '',
    'Informationen für Buchhaltung',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Informationen für Entgeltabrechnung',
    '',
    ''
  ];
  const secondRow = [
    'Vorname',
    'Nachname',
    'Mitarbeiter/Geschäftspartner',
    'Vorname',
    'Nachname',
    'Personal ID',
    'Unternehmen',
    'E-Mail-Adresse',
    'Anlass',
    'Beschreibung Anlass',
    'Geschenk Beschreibung',
    'Datum',
    'Brutto/Netto',
    'Wert pro Geschenk',
    'Genehminger Name',
    'Gehneminger Email',
    'Status',
    'Interne Belegnummer',
    'Rechnungsnummer',
    'Aufwand (brutto)',
    'Aufwand (netto)',
    'Informationen zur Buchung',
    'Sachzuwendung',
    '',
    '',
    'Vorsteuer',
    '',
    '',
    'Lohnsteuer',
    '',
    '',
    'Informationen zur Versteuerung',
    'Lohnsteuer',
    'Sozialversicherung'
  ];
  const thirdRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kontobezeichnung',
    'SKR03',
    'SKR04',
    'Kontobezeichnung',
    'SKR03',
    'SKR04',
    'Kontobezeichnung',
    'SKR03',
    'SKR04',
    '',
    '',
    ''
  ];

  // list of column headers merging
  const horizontalMerges = ['A1:B1', 'C1:H1', 'I1:N1', 'O1:P1', 'R1:U1', 'V1:AE1', 'AF1:AH1', 'W2:Y2', 'Z2:AB2', 'AC2:AE2'];
  const verticalMerges = [
    'A2:A3',
    'B2:B3',
    'C2:C3',
    'D2:D3',
    'E2:E3',
    'F2:F3',
    'G2:G3',
    'H2:H3',
    'I2:I3',
    'J2:J3',
    'K2:K3',
    'L2:L3',
    'M2:M3',
    'N2:N3',
    'O2:O3',
    'P2:P3',
    'Q2:Q3',
    'R2:R3',
    'S2:S3',
    'T2:T3',
    'U2:U3',
    'V2:V3',
    'AF2:AF3',
    'AG2:AG3',
    'AH2:AH3'
  ];

  let ws = XLSX.utils.sheet_add_aoa(workingBook, []);

  createLine(workingBook, 'A1', firstRow);
  createLine(workingBook, 'A2', secondRow);
  createLine(workingBook, 'A3', thirdRow);
  ws = XLSX.utils.sheet_add_json(workingBook, preparedData, { origin: 'A4', skipHeader: true });

  const workingBuffer = { Sheets: { data: workingBook }, SheetNames: ['data'] };

  generateMerges(ws, horizontalMerges);
  generateMerges(ws, verticalMerges);

  styleHeader(ws, 1, 1, firstRow.length, true);
  styleHeader(ws, 2, 3, secondRow.length);

  const excelBuffer = XLSX.write(workingBuffer, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, 'Falluebersicht' + fileExtension);
};

const prepareData = data => {
  const preparedData = data.map(gift => {
    const statuses = gift.status.status;
    gift.gift = gift.gift ? gift.gift : {};
    gift.invoice = gift.invoice ? gift.invoice : {};

    const lastestEditedByName = statuses[statuses.length - 1] !== undefined ? statuses[statuses.length - 1].name : '';
    const lastestEditedByEmail = statuses[statuses.length - 1] !== undefined ? statuses[statuses.length - 1].email : '';
    const giftReason = gift.gift.giftReason && gift.gift.giftReason in reasonOptions ? reasonOptions[gift.gift.giftReason].label : '';
    const date = moment(gift.gift.giftDate);
    return {
      senderName: gift.sender.firstName,
      senderLastName: gift.sender.lastName,
      receiverType: receiverType[gift.entryCheck.receiverType] ? receiverType[gift.entryCheck.receiverType].label : '',
      receiverName: gift.receiverName,
      receiverLastName: gift.receiverLastName,
      receiverPersonalId: gift.receiverPersonalId,
      receiverCompany: gift.receiverCompany,
      receiverEmailAddress: gift.receiverEmail,
      giftReason: giftReason,
      giftReasonDescription: gift.gift.giftDescriptionOccasion,
      giftDescription: gift.gift.giftDescription,
      giftDate: date.isValid() ? date.format('DD.MM.YYYY') : '',
      giftValueType: GIFT_VALUE_TYPE[gift.gift.giftValueType] ? GIFT_VALUE_TYPE[gift.gift.giftValueType].label : '',
      giftValue: gift.gift.giftValue,

      lastestEditedByName: lastestEditedByName,
      lastestEditedByEmail: lastestEditedByEmail,
      status: gift.lastStatus,

      fibuInternalInvoiceNumber: gift.invoice.invoiceNumber,
      fibInvoiceNumber: gift.invoice.invoiceNumber,
      fibuGiftValueGross: gift.invoice.grossValue,
      fibuGiftValueNet: gift.invoice.netValue,

      fibuInfo: getGuidanceInfo(gift, 'accountingGuidance'),
      fibuSachzuwendung: getAccountingGuidanceValue(gift, 'Sachzuwendung', 'accountName', true),
      fibuSachzuwendungSKR03: getAccountingGuidanceValue(gift, 'Sachzuwendung', 'SKR03'),
      fibuSachzuwendungSKR04: getAccountingGuidanceValue(gift, 'Sachzuwendung', 'SKR04'),
      fibuVorsteuer: getAccountingGuidanceValue(gift, 'Vorsteuer', 'accountName', true),
      fibuVorsteuerSKR03: getAccountingGuidanceValue(gift, 'Vorsteuer', 'SKR03'),
      fibuVorsteuerSKR04: getAccountingGuidanceValue(gift, 'Vorsteuer', 'SKR04'),
      fibuLohnsteur: getAccountingGuidanceValue(gift, 'Lohnsteuer', 'accountName', true),
      fibuLohnsteurSKR03: getAccountingGuidanceValue(gift, 'Lohnsteuer', 'SKR03'),
      fibuLohnsteurSKR04: getAccountingGuidanceValue(gift, 'Lohnsteuer', 'SKR04'),

      payrollInfo: getGuidanceInfo(gift, 'payrollGuidance'),
      payrollLohnsteuer: getPayrollGuidanceValue(gift, 'Lohnsteuer'),
      payrollSocialInscurance: getPayrollGuidanceValue(gift, 'Sozialversicherung')
    };
  });
  return preparedData;
};

const getAccountingGuidanceValue = (gift, accountTitle, propName, asString = false) => {
  if (!gift || !gift.guidance) return '';

  const { accountingGuidance } = gift.guidance;
  if (!accountingGuidance) return '';

  const { accounts } = accountingGuidance;
  if (!accounts) return '';

  const account = accounts[accountTitle];
  if (!account) return '';

  if (typeof account === 'string' && asString) return account;

  if (account[propName]) return account[propName];

  return '';
};
const getPayrollGuidanceValue = (gift, propName) => {
  return gift &&
    gift.guidance &&
    gift.guidance.payrollGuidance &&
    gift.guidance.payrollGuidance.taxes &&
    gift.guidance.payrollGuidance.taxes[propName]
    ? gift.guidance.payrollGuidance.taxes[propName]
    : '';
};

const getGuidanceInfo = (gift, guidanceName) => {
  const info =
    gift && gift.guidance && gift.guidance[guidanceName] && gift.guidance[guidanceName].info ? gift.guidance[guidanceName].info : '';
  return info
    .replace(/<br[^>]*>/gi, '')
    .replace(/(\n)+/g, '')
    .split(' ')
    .filter(value => value !== '')
    .join(' ');
};

export { exportToXLSX };
