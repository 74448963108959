export const COMPLIANCE_HEADER = 'Compliance-Hinweise';
export const GIFT_GUIDELINE_HEADER = 'Ihre Geschenke-Richtlinie';
export const GIFT_GUIDELINE_INFO = `Hier können Sie Ihre unternehmensinterne Geschenke-Richtlinie verlinken und/oder als Datei hochladen.
  Die verlinkte Richtlinie bzw. das Dokument wird den Schenkern zu Beginn des Workflows angezeigt und diese müssen bestätigen, dass die Geschenke-Richtlinie des Unternehmens eingehalten wird.`;

export const MANDATORY_FIELDS_MISSING_MESSAGE = ` <span> Der Vorgang konnte nicht gespeichert werden.
                                        <br /> Bitte tragen Sie den Stellentitel ein, bevor Sie speichern. </span>`;
export const DUPLICATE_FILENAME_MESSAGE = `Eine Datei mit gleichem Namen wurde bereits hochgeladen. Möchten Sie diese Datei ersetzen?`;
export const CONFIRM_DELETE_MESSAGE = `Wollen Sie diese Datei wirklich löschen?`;
export const WRONG_EXTENSION_TOOBIGFILE_MESSAGE = `Diese Datei können Sie leider nicht hochladen.
                                                Bitte laden Sie nur Word-, Excel-, PowerPoint-Dateien, rtf, pdf, jpg, gif, png bis zu 25 MB hoch.`;
export const COMPLIANCE_URL_INVALID_ERROR_MESSAGE = 'Bitte geben Sie die vollständige URL ein, z. B. in folgendem Format: http(s)://';
