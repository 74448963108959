import React from 'react';
import './_style.scss';

function PasswordInput(props) {
  const validationMessage = props.isInvalid && props.errorMsg;
  const className = 'form-control password' + (validationMessage ? ' invalid' : '');
  return (
    <div className="text-input-container">
      <label htmlFor={props.label}>
        {props.label} {!props.isMandatory && <span className="optional-info">optional</span>}
      </label>
      <input
        className={className}
        type="password"
        name={props.label}
        placeholder={props.placeholder}
        onClick={props.onClick}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onBlur={props.onBlur}
        disabled={props.disabled}
        value={props.value ? props.value : ''}
        pattern={props.pattern}
        onPaste={e => {
          if (props.notAllowPaste) {
            e.preventDefault();
          }
        }}
      />
      {validationMessage && <span className="text-error-message">{props.errorMsg}</span>}
    </div>
  );
}

export default PasswordInput;
