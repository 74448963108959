/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { trackPI } from '../../Utils/utils';
import './_style.scss';

const thanksBannerText = <div>Sie können den Vertrag jederzeit über den Link „Auftragsverarbeitung“ unten links aufrufen.</div>;
const acceptBannerTitle = 'Einwilligung';
const thanksBannerTitle = 'Vielen Dank';
const btnAcceptText = 'Akzeptieren';
const btnThanksText = 'Schließen';

// test push
export default class ADVBannerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showThanksBanner: false
    };
    this.handleAgreeBtnClick = this.handleAgreeBtnClick.bind(this);
    this.handleThanksBtnClick = this.handleThanksBtnClick.bind(this);
  }

  handleAgreeBtnClick() {
    this.props.acceptADV();
    trackPI({
      pageId: this.props.advPageId,
      content: 'ADV: akzeptieren',
      requrl: '/app/acceptADV',
      siteid: this.props.siteid,
      event: this.props.event,
    });
    this.setState({
      showThanksBanner: true
    });
  }

  handleThanksBtnClick() {
    if (this.props.isADVForced) {
      /* if advForced then pressing close should reload the page instantly */
      window.location.reload();
      return;
    }

    this.setState(
      {
        showThanksBanner: false
      },
      () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
    );
  }

  advForcedText = (
    <div>
      Um den Haufe {this.props.projectName} weiter verwenden zu können ist die Einwilligung zur Datenspeicherung erforderlich. Sollten Sie
      Rückfragen dazu haben wenden Sie sich bitte an den Produktsupport.
    </div>
  );

  acceptBannerText = (
    <div>
      Für die Nutzung des Haufe {this.props.projectName}s ist der Abschluss eines Vertrages zur Auftragsverarbeitung gem. Art 28 EU-DSGVO
      zwingend erforderlich. Sie haben bislang noch nicht zugestimmt. Einen von uns unterzeichneten Vertrag können Sie{' '}
      <a href={'/' + this.props.avvPdfFileName} target="_blank" rel="noopener noreferrer">
        an dieser Stelle
      </a>{' '}
      prüfen und dem Vertrag anschließend elektronisch zustimmen.
    </div>
  );

  customAcceptBannerText = this.props.customAcceptBannerText;

  displayBannerLogic(acceptBannerComponent, thanksBannerComponent) {
    if (!this.props.isADVAccepted) {
      return acceptBannerComponent;
    }
    if (this.state.showThanksBanner) {
      return thanksBannerComponent;
    }
  }

  btnClasses() {
    return `adv-banner-button ${this.props.isADVAccepted ? 'btn-adv-accepted' : 'btn-adv-not-accepted'}`;
  }

  renderADVForcedContainer() {
    return this.props.isADVForced && !this.props.isADVAccepted ? (
      <div className="adv-banner-forced-wrapper">
        <div className="adv-banner-forced adv-banner-container">{this.advForcedText}</div>
      </div>
    ) : null;
  }

  renderAcceptBanner() {
    return (
      <div className="adv-banner-wrapper">
        {this.renderADVForcedContainer()}
        <div className="adv-banner-container-wrapper">
          <div className="adv-banner-container">
            <div className="adv-banner-header">
              <div className="adv-banner-title">{acceptBannerTitle}</div>
            </div>
            <div className="adv-banner-content">
              <div className="adv-banner-text">{this.customAcceptBannerText ? this.customAcceptBannerText : this.acceptBannerText}</div>
              <button className={this.btnClasses()} onClick={() => this.handleAgreeBtnClick()}>
                {btnAcceptText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderThanksBanner() {
    return (
      <div className="adv-banner-wrapper">
        <div className="adv-banner-container-wrapper">
          <div className="adv-banner-container">
            <div className="adv-banner-header">
              <div className="adv-banner-title">{thanksBannerTitle}</div>
            </div>
            <div className="adv-banner-content">
              <div className="adv-banner-text">{thanksBannerText}</div>
              <button className={this.btnClasses()} onClick={() => this.handleThanksBtnClick()}>
                {btnThanksText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const bannerComponent = this.displayBannerLogic(this.renderAcceptBanner(), this.renderThanksBanner());

    return (
      <div>
        {this.props.isADVForced && <div className="adv-forced-panel" />}
        <div className="item-wrapper">
          <CSSTransitionGroup transitionName="fade" transitionEnterTimeout={500} transitionLeaveTimeout={500}>
            {bannerComponent}
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

ADVBannerComponent.propTypes = {
  avvPdfFileName: PropTypes.string,
  isADVForced: PropTypes.bool,
  isADVAccepted: PropTypes.bool,
  advPageId: PropTypes.string,
  acceptADV: PropTypes.func,
  projectName: PropTypes.string,
  customAcceptBannerText: PropTypes.object,
  siteid: PropTypes.string,
  event: PropTypes.string,
};
