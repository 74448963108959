import React from 'react';
import DatepickerComponent from '../DatepickerComponent';

function DatepickerInput(props) {
  return (
    <div className="text-input-container">
      <label htmlFor="Startdatum">
        {props.label}
        {!props.isMandatory && <span className="optional-info">optional</span>}
      </label>
      <DatepickerComponent
        errorMsg={props.errorMsg}
        isInvalid={props.isInvalid}
        inputValue={props.value}
        minDate={props.minDate}
        maxDate={props.maxDate}
        placeholder={props.placeholder}
        handleDateChange={value => props.onBlur(value)}
        disabled={props.disabled}
        calendarStartDay={props.calendarStartDay}
      />
    </div>
  );
}

export default DatepickerInput;
