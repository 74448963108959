import { ALLOW_FILE_TYPES, MAX_SIZE } from '../../Utils/constants';
import { getGtmInfoForStep } from '../../Utils/utils';
import React, { useEffect, useState } from 'react';

import FileAttachments from '../FileAttachments';
import FileInput from '../FileInput';

const DUPLICATE_FILENAME_MESSAGE = `Eine Datei mit gleichem Namen wurde bereits hochgeladen. Möchten Sie diese Datei ersetzen?`;
const WRONG_EXTENSION_TOOBIGFILE_MESSAGE = `Diese Datei können Sie leider nicht hochladen.
                                                Bitte laden Sie nur Word-, Excel-, PowerPoint-Dateien, rtf, pdf, jpg, gif, png bis zu 25 MB hoch.`;

function FileUpload(props) {
  const { modalStore } = props.rootStore;
  const [fileAttachments, setFileAttachments] = useState(props.fileAttachments);

  useEffect(() => {
    setFileAttachments(props.fileAttachments);
  }, [props.fileAttachments]);

  const getFileExtension = fileName => {
    if (fileName && fileName.lastIndexOf('.') >= 0) {
      return fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    }
    return '';
  };

  const isFileAlreadyUploaded = (fileName, fileAttachments) => {
    const fileIsAlreadyUploaded = fileAttachments.find(file => file.fileName === fileName);
    return fileIsAlreadyUploaded !== undefined;
  };

  const uploadFile = async file => {
    if (ALLOW_FILE_TYPES.includes(getFileExtension(file.name)) && file.size <= MAX_SIZE) {
      modalStore.showModal('', <div style={{ padding: '50px 160px' }}>Datei wird gespeichert...</div>);
      const data = new FormData();
      data.append('file', file);

      const fileAttachmentsList = await props.uploadFile(data);
      if (fileAttachmentsList) {
        setFileAttachments(fileAttachmentsList)
        modalStore.hideModal();
      }
    } else {
      modalStore.showConfirm('Fehler', WRONG_EXTENSION_TOOBIGFILE_MESSAGE);
    }
  };

  const onDelete = async fileId => {
    const fileAttachmentsList = await props.deleteFile(fileId);
    setFileAttachments(fileAttachmentsList)
  };

  const resetFileUpload = () => {
    const fileUploadElement = document.querySelector('.file-input-element');
    if (fileUploadElement) {
      fileUploadElement.value = '';
    }
  };

  const uploadingFiles = files => {
    if (files.length > 0) {
      const file = files[0];
      if (isFileAlreadyUploaded(file.name, fileAttachments)) {
        modalStore.showChoice('Warnung', DUPLICATE_FILENAME_MESSAGE, null, null, 'Ersetzen', () => {
          uploadFile(file);
        });
      } else {
        uploadFile(file);
      }
    }
  };

  const onChange = files => {
    if (props.notAllowToUpload) {
      modalStore.showConfirm('Warnung', props.notAllowToUploadMessage);
    } else {
      uploadingFiles(files);
    }
    resetFileUpload();
  };

  return (
    <React.Fragment>
      <FileInput
        isMandatory={props.isMandatory}
        key={`${props.label}-file-input`}
        label={props.label}
        placeholder={props.placeholder}
        accept={props.accept}
        multiple={props.multiple}
        disabled={props.disabled}
        onChange={onChange}
        gtmInfo={props.step && getGtmInfoForStep(props.step)}
      />
      <FileAttachments
        key={`${props.label}-file-attachments`}
        fileAttachments={fileAttachments}
        rootStore={props.rootStore}
        onDelete={onDelete}
        onDownload={props.downloadFile}
      />
    </React.Fragment>
  );
}

export default FileUpload;
