import React from 'react';
import FileDataCard from '../FileData';
import './_style.scss';

function FileDataList(props) {
  return (
    <div className="summary-attachments-container">
      {props.attachments.map((file, index) => {
        const { fileName, fileId } = file;
        return (
          <div className="summary-file-row" key={`summary-file-row-${index}`}>
            <FileDataCard
              key={`file-summary-${index}`}
              fileName={fileName}
              fileId={fileId}
              rootStore={props.rootStore}
              hideDeleteButton
              iconColor={props.iconColor}
              onDownload={props.onDownload}
            />
          </div>
        );
      })}
    </div>
  );
}

export default FileDataList;
