import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RestrictedRoute = ({ component: Component, userInfo, allowRoles, ...props }) => {
  return (
    <Route
      {...props}
      render={props => {
        // check if the route is restricted by user roles. The route can be accesible for multiple user roles
        if (allowRoles && userInfo.roles.some(role => allowRoles.includes(role))) {
          return <Component {...props} />;

        }
        // if the user doesn't has permision to access this route, redirect to home page  
        return <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
};

export default RestrictedRoute;