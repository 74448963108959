import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { helpIcon, crossIcon } from '../../Assets/dynamic-svg/general-icons';
import { white, blackPearl } from '../../Utils/styleHelper';
import { FOOTER_FIX_HEIGHT, HEADER_FIX_HEIGHT } from '../../Utils/constants';
import './_style.scss';

function HelpText(props) {
  const { helpTextStore, createGiftStore } = props.rootStore;
  const [helpTextContainerStyle, setHelpTextContainerStyle] = useState({});

  useEffect(() => {
    setHelpTextPosition();

    window.addEventListener('scroll', setHelpTextPosition);
    window.addEventListener('resize', setHelpTextPosition);

    return () => {
      window.removeEventListener('scroll', setHelpTextPosition);
      window.removeEventListener('resize', setHelpTextPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHelpTextPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGiftStore.rightContainerSizeChanged]);

  const setHelpTextPosition = () => {
    const headerHeight = HEADER_FIX_HEIGHT;
    const footerHeight = FOOTER_FIX_HEIGHT;
    const navigationBarElement =
      document.getElementsByClassName('navigation-bar').length > 0 ? document.getElementsByClassName('navigation-bar')[0] : undefined;
    const navigationBarHeight = navigationBarElement ? navigationBarElement.clientHeight : 0;
    const footerVisibleHeight = Math.max(0, window.pageYOffset + window.innerHeight + footerHeight - window.document.body.scrollHeight);

    const hasVScroll = window.document.body.scrollHeight > window.innerHeight;

    let helpTextContainerStyle = {};
    if (hasVScroll) {
      if (window.pageYOffset > window.innerHeight) {
        helpTextContainerStyle = {
          height: `${window.innerHeight + Math.min(headerHeight, window.scrollY) - footerVisibleHeight - navigationBarHeight}px`,
          top: `${Math.max(-headerHeight, 0 - window.scrollY)}px`
        };
      } else {
        helpTextContainerStyle = {
          height: `${window.innerHeight + Math.min(headerHeight, window.scrollY) - footerVisibleHeight - navigationBarHeight}px`,
          top: `${Math.max(-headerHeight, 0 - window.scrollY)}px`
        };
      }
    } else {
      helpTextContainerStyle = {
        height: `${window.innerHeight - footerHeight - navigationBarHeight}px`,
        top: `${Math.max(-headerHeight, 0 - window.scrollY)}px`
      };
    }

    setHelpTextContainerStyle(helpTextContainerStyle);
  };

  const renderHelpTextButton = () => {
    return (
      helpTextStore.helpText &&
      helpTextStore.isCollapsed && (
        <button
          className="help-text-button"
          onClick={e => {
            helpTextStore.expandHelpText();
            setHelpTextPosition();
          }}>
          Hilfe <span className="icon-general">{helpIcon(white)}</span>
        </button>
      )
    );
  };

  const renderHelpContent = () => {
    return (
      helpTextStore.helpText &&
      !helpTextStore.isCollapsed && (
        <div className="help-text-container" style={helpTextContainerStyle}>
          <div>
            <button
              className="help-text-left-button"
              onClick={e => {
                helpTextStore.collapseHelpText();
              }}>
              <div className="helper-div">Hilfe</div> <span className="icon-general">{helpIcon(blackPearl)}</span>
            </button>
          </div>
          <div className="helptext-scroll-area">
            <button
              className="help-text-close-button"
              onClick={e => {
                helpTextStore.collapseHelpText();
              }}>
              <span className="icon-general">{crossIcon(blackPearl)}</span>
            </button>
            <div className="help-text-content">
              <div className="help-text-title">Hilfetexte {helpTextStore.helpText.title}</div>
              <div className="help-text-sections">{renderHelpTextSections()}</div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderHelpTextSections = () => {
    return Object.values(helpTextStore.helpText)
      .filter(section => section.id && section.title)
      .map((section, index) => (
        <section key={`${section.id}-${index}`}>
          <div className={`help-text-section`} id={section.id}>
            {section.title}
          </div>
          <div className="help-text-description">{section.description}</div>
        </section>
      ));
  };

  return (
    <section>
      {renderHelpTextButton()}
      {renderHelpContent()}
    </section>
  );
}

export default inject('rootStore')(observer(HelpText));
