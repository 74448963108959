import { observable, action, decorate } from 'mobx';

/*
  Similar to the Modal Store - with different options and styling.
*/

export default class CreateGiftStore {
  currentWorkflowStep = {};
  rightContainerSizeChanged = true;
  currentWorkflowValidating = true;

  reset() {
    this.currentWorkflowStep = {};
  }
}

decorate(CreateGiftStore, {
  // Observables
  currentWorkflowStep: observable,
  // because is not a event triggered when a container size change, toggle this observer to reposition the navigation bar when a component resize.
  rightContainerSizeChanged: observable,
  currentWorkflowValidating: observable,

  // Actions
  reset: action
});
