import React, { useState } from 'react';
import '../_style.scss';
import { inject } from 'mobx-react';
import { chevronLeftIcon } from '../../../Assets/dynamic-svg/general-icons';
import { royalBlue } from '../../../Utils/styleHelper';
import { checkPasswordStrength } from '../../../Utils/utils';
import InputCard, { INPUT_CARD_TYPE } from '../../../Components/InputCard';
import { LOGIN } from '../../../Utils/paths';
import { showErrorInView } from '../../../Utils/utils';
import { validateEmail, isEmpty } from '../../../sma-shared/Utils/utils';
import {
  PASSWORD_FORGOT_TITLE_TEXT,
  PASSWORD_FORGOT_DESCRIPTION_TEXT,
  EMAIL_TEXT,
  PASSWORD_TEXT,
  PASSWORD_REPEAT_TEXT,
  BACK_TO_LOGIN_TEXT,
  YOUR_DATA_TEXT,
  SEND_TEXT,
  FIRTS_NAME_ERROR,
  EMAIL_ERROR,
  PASSWORD_ERROR,
  PASSWORD_NOT_STRENGTH_ERROR,
  PASSWORD_REPEAT_ERROR,
  PASSWORD_REPEAT_NOT_SAME_ERROR,
  RESET_CODE_SENT_TEXT,
  PASSWORD_CHANGED_TEXT,
  CODE_TEXT,
  NEW_PASSWORD_TEXT,
  ERROR_MESSAGE_RESET_PW,
  ERROR_MESSAGE_CHANGE_PW
} from '../labels';

function ResetPassword(props) {
  const { apiStore } = props.rootStore;
  const { history } = props;
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [result, setResult] = useState(false);
  const [resetCodeSent, setResetCodeSent] = useState(false);

  const [isValidVerificationCode, setIsValidVerificationCode] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidNewPassword, setIsValidNewPassword] = useState(true);
  const [isValidNewPasswordRepeat, setIsValidNewPasswordRepeat] = useState(true);

  const validate = () => {
    const isValidVerificationCode = validateInput(verificationCode, setIsValidVerificationCode);

    const isEmailValid = validateEmail(email);
    setIsValidEmail(isEmailValid);

    const passwordStrength = checkPasswordStrength(newPassword);
    const isNewPasswordValid = !isEmpty(newPassword) && Object.values(passwordStrength).every(value => value);
    setIsValidNewPassword(isNewPasswordValid);

    const isNewPasswordRepeatValid = !isEmpty(newPasswordRepeat) && newPassword === newPasswordRepeat;
    setIsValidNewPasswordRepeat(isNewPasswordRepeatValid);

    return isValidVerificationCode && isEmailValid && isNewPasswordValid && isNewPasswordRepeatValid;
  };

  const validateInput = (value, setValidState, ignoreEmptyValue = false) => {
    const isValid = ignoreEmptyValue ? true : !isEmpty(value);
    setValidState(isValid);
    if (!isValid) {
      showErrorInView();
    }
    return isValid;
  };

  const renderResetSection = () => {
    const inputs = [
      {
        type: INPUT_CARD_TYPE.text,
        label: EMAIL_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: email,
        isInvalid: !isValidEmail,
        errorMsg: EMAIL_ERROR,
        onChange: e => {
          setEmail(e.target.value);
          setIsValidEmail(e.target.value ? validateEmail(e.target.value) : true);
        }
      },
      {
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderResetPasswordButton()
      }
    ];

    return (
      <React.Fragment>
        <InputCard title={YOUR_DATA_TEXT} data={inputs} />
      </React.Fragment>
    );
  };

  const renderResetPasswordButton = () => {
    return (
      <div>
        <div className="line-separator" />
        <button
          onClick={() => {
            const isEmailValid = validateEmail(email);
            setIsValidEmail(isEmailValid);
            if (isEmailValid) {
              apiStore.forgotPassword({ email }, result => {
                if (result.error) {
                  const message = ERROR_MESSAGE_RESET_PW(result.error.code);
                  setResultMessage(message);
                  setResult(false);
                  setResetCodeSent(false);
                  showErrorInView('error');
                } else if (result) {
                  setResultMessage(RESET_CODE_SENT_TEXT);
                  setResult(true);
                  setResetCodeSent(true);
                }
              });
            } else {
              showErrorInView();
            }
          }}
          className="btn btn-primary right">
          {SEND_TEXT}
        </button>
      </div>
    );
  };

  const renderChangePasswordSection = () => {
    const inputs = [
      {
        type: INPUT_CARD_TYPE.text,
        label: CODE_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: verificationCode,
        isInvalid: !isValidVerificationCode,
        errorMsg: FIRTS_NAME_ERROR,
        onChange: e => {
          setVerificationCode(e.target.value);
          validateInput(e.target.value, setIsValidVerificationCode, true);
        }
      },
      {
        type: INPUT_CARD_TYPE.password,
        label: PASSWORD_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: newPassword,
        isInvalid: !isValidNewPassword,
        errorMsg: isEmpty(newPassword) ? PASSWORD_ERROR : PASSWORD_NOT_STRENGTH_ERROR,
        onChange: e => {
          setNewPassword(e.target.value);
          validateInput(e.target.value, setIsValidNewPassword, true);
        }
      },
      {
        type: INPUT_CARD_TYPE.password,
        label: PASSWORD_REPEAT_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: newPasswordRepeat,
        notAllowPaste: true,
        isInvalid: !isValidNewPasswordRepeat,
        errorMsg: newPassword !== newPasswordRepeat ? PASSWORD_REPEAT_NOT_SAME_ERROR : PASSWORD_REPEAT_ERROR,
        onChange: e => {
          setNewPasswordRepeat(e.target.value);
          validateInput(e.target.value, setIsValidNewPasswordRepeat, true);
        }
      },
      {
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderChangePasswordButton()
      }
    ];

    return (
      <React.Fragment>
        <InputCard title={NEW_PASSWORD_TEXT} data={inputs} />
      </React.Fragment>
    );
  };

  const renderChangePasswordButton = () => {
    return (
      <div>
        <div className="line-separator" />
        <button
          onClick={() => {
            if (validate()) {
              apiStore.resetPassword({ email, verificationCode, newPassword, newPasswordRepeat }, result => {
                if (result.error) {
                  const message = ERROR_MESSAGE_CHANGE_PW(result.error.code);
                  setResultMessage(message);
                  setResult(false);
                  showErrorInView('error');
                } else if (result) {
                  setResultMessage(PASSWORD_CHANGED_TEXT);
                  setResult(true);
                }
              });
            }
          }}
          className="btn btn-primary right">
          {SEND_TEXT}
        </button>
      </div>
    );
  };

  return (
    <div className="auth-container">
      <div className="left-container">
        <button
          className="text-button"
          onClick={e => {
            history.push(LOGIN);
          }}>
          <span className="icon-general">{chevronLeftIcon(royalBlue)}</span>
          {BACK_TO_LOGIN_TEXT}
        </button>
        <div className="form">
          <div className="page-title">{PASSWORD_FORGOT_TITLE_TEXT}</div>
          <div className="description">{PASSWORD_FORGOT_DESCRIPTION_TEXT}</div>
          {!resetCodeSent && renderResetSection()}
          {resetCodeSent && renderChangePasswordSection()}
          {resultMessage && <div className={`message ${result ? 'success' : 'error'}`}>{resultMessage}</div>}
        </div>
      </div>
      <div className="right-container"></div>
    </div>
  );
}

export default inject('rootStore')(ResetPassword);
