import React, { useEffect, useRef } from 'react';
import './_style.scss';
import { inject, observer } from 'mobx-react';
import WorkflowNavigation from './WorkflowNavigation';
import WorkflowActions from './WorkflowActions';
import { Route, Switch, useParams } from 'react-router-dom';
import RestrictedRoute from '../App/restrictedRoute';
import { ROLES } from '../../Utils/constants';
import { historyListener, showUnsavedChangesModal } from '../../Utils/utils';
import {
  OVERVIEW_PATH,
  GIFTCASE_ENTRYCHECK_PATH,
  GIFTCASE_RECEIVER_PATH,
  GIFTCASE_GIFT_PATH,
  GIFTCASE_APPROVAL_PATH,
  GIFTCASE_REPORT_PATH,
  GIFTCASE_PAYROLL_PATH,
  GIFTCASE_FIBU_PATH,
  PAGE_NOT_FOUND,
  GIFTCASE_PATH
} from '../../Utils/paths';
import NavigationBar from './NavigationBar';
import EntryCheck from './01_EntryCheck';
import Receiver from './02_Receiver';
import Gift from './03_Gift';
import Approval from './04_Approval';
import Report from './05_Report';
import Payroll from './07_Payroll';
import Fibu from './06_Fibu';
import SAVE_ICON from '../../Assets/svg/save.svg';
import CANCEL_ICON from '../../Assets/svg/cancel.svg';

function CreateGiftPages(props) {
  const { apiStore, giftDataStore, createGiftStore, modalStore } = props.rootStore;
  let { giftId } = useParams();
  const unblock = useRef();
  const giftsRef = useRef();

  useEffect(() => {
    historyListener(props.history);
    unblock.current = props.history.block(location => {
      const checkBlockingCondition = giftDataStore.isGiftDataChanged() && !location.pathname.startsWith(GIFTCASE_PATH);
      if (checkBlockingCondition) {
        showUnsavedChangesModal(modalStore, location.pathname, allowUserToChangeRoute);
        return false;
      }
      return true;
    });
    return () => {
      unblock.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (giftsRef.current === undefined && apiStore.gifts.length > 0) {
      giftsRef.current = apiStore.gifts;
      loadGiftData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.gifts]);

  const allowUserToChangeRoute = targetLocation => {
    unblock.current();
    props.history.replace(targetLocation);
  };

  async function loadGiftData() {
    if (giftId){
      await apiStore.refreshGift(giftId);
    }
     
    const id = giftId || giftDataStore.idFromDB;
    if (id) {
      const gift = apiStore.gifts.find(gift => gift._id === id);
      if (gift) {
        giftDataStore.editGift(gift);
      } else if (apiStore.gifts.length > 0) {
        props.history.push(PAGE_NOT_FOUND);
      }
    } else {
      giftDataStore.reset();
      props.history.push(GIFTCASE_ENTRYCHECK_PATH());
    }
  }
  const createActions = () => {
    const actions = [
      {
        key: 1,
        name: 'Speichern',
        callback: () => saveAction(),
        icon: SAVE_ICON
      },
      {
        key: 2,
        name: 'Abbrechen',
        callback: () => cancelAction(),
        icon: CANCEL_ICON
      }
    ];

    return actions;
  };

  const cancelAction = () => {
    if (!giftDataStore.isGiftDataChanged()) {
      allowUserToChangeRoute(OVERVIEW_PATH());
    } else {
      showUnsavedChangesModal(modalStore, OVERVIEW_PATH(), allowUserToChangeRoute);
    }
  };

  const saveAction = () => {
    if (createGiftStore.currentWorkflowStep.validate instanceof Function && createGiftStore.currentWorkflowStep.validate())
      giftDataStore.saveGift(id => {
        props.history.push(createGiftStore.currentWorkflowStep.path(id));
      });
  };

  return (
    <div className="navigation-page">
      <div className="two-container-page">
        <div className="container-left">
          <div className="sticky-left-container">
            <WorkflowNavigation history={props.history} />
            <div className="line-separator" />
            <WorkflowActions actions={createActions()} />
          </div>
        </div>
        <div className="container-right">
          <Switch>
            <Route exact path={GIFTCASE_ENTRYCHECK_PATH(giftId)} component={props => <EntryCheck {...props} />} />
          </Switch>
          <Switch>
            <Route exact path={GIFTCASE_RECEIVER_PATH(giftId)} component={props => <Receiver {...props} />} />
          </Switch>
          <Switch>
            <Route exact path={GIFTCASE_GIFT_PATH(giftId)} component={props => <Gift {...props} />} />
          </Switch>
          <Switch>
            <Route exact path={GIFTCASE_APPROVAL_PATH(giftId)} component={props => <Approval {...props} />} />
          </Switch>
          <Switch>
            <RestrictedRoute
              exact
              path={GIFTCASE_PAYROLL_PATH(giftId)}
              userInfo={apiStore.userInfo}
              allowRoles={[ROLES.admin, ROLES.accounting, ROLES.payroll]}
              component={props => <Payroll {...props} />}
            />
          </Switch>
          <Switch>
            <RestrictedRoute
              exact
              path={GIFTCASE_FIBU_PATH(giftId)}
              userInfo={apiStore.userInfo}
              allowRoles={[ROLES.admin, ROLES.accounting, ROLES.payroll]}
              component={props => <Fibu {...props} />}
            />
          </Switch>
          <Switch>
            <Route exact path={GIFTCASE_REPORT_PATH(giftId)} component={props => <Report {...props} />} />
          </Switch>
        </div>
      </div>
      <NavigationBar history={props.history} />
    </div>
  );
}

export default inject('rootStore')(observer(CreateGiftPages));
