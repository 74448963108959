import {
  APPROVAL_PAGE_TITLE,
  INFO_FOR_EMPFANGER_TITLE,
  RECEIVER_NUMBER_TEXT,
  RECEIVER_PAGE_TITLE,
  RECEIVER_PERSONALDATA_TEXT
} from '../Pages/CreateGiftPages/giftCaseLabels';

const infoForReceiver = {
  id: 'help-text-copyable-receiver-info',
  title: INFO_FOR_EMPFANGER_TITLE,
  description: (
    <div>
      Zuwendungen mit einem Wert von über 10 EUR unterliegen bei im Inland steuerpflichtigen Empfängern der Einkommensteuer. Nach § 37b Abs.
      3 Satz 3 EStG hat der Schenker den Empfänger zu informieren, wenn er das Geschenk pauschal versteuert. Eine besondere Form ist nicht
      vorgeschrieben. Die Information kann z. B. durch die Beilage einer Notiz zum Geschenk oder durch ein gesondertes Schreiben an den
      Empfänger erfolgen.
    </div>
  )
};

const numberOfReceivers = {
  id: 'help-text-receiver-number-of-receivers',
  title: RECEIVER_NUMBER_TEXT,
  description: (
    <div>
      <p>Wenn mehrere Empfänger das gleiche Geschenk erhalten sollen, so können Sie diese in einer Tabelle untereinander eintragen.</p>
      <p>
        Wenn sehr viele Empfänger das gleiche Geschenk erhalten, können Sie diese über eine Excel-Vorlage (jeweils unterschiedlich für
        Mitarbeiter oder Geschäftspartner) importieren. So gehen Sie vor:
      </p>
      <ol type="1">
        <li>Laden Sie die Datei über den Button „Download Excel-Vorlage“ herunter und öffnen Sie sie.</li>
        <li>
          Kopieren Sie die Daten (mindestens Nachname und Vorname sowie bei Geschäftspartnern den Firmennamen) in die entsprechenden
          Spalten. Anschließend speichern Sie die Datei.
        </li>
        <li>
          Importieren Sie die Daten über den Button „Import Empfängerliste aus Vorlage“. Dabei können Sie vorhandene Empfänger ergänzen oder
          diese komplett überschreiben.
        </li>
      </ol>
      <p>
        Anschließend werden alle Empfänger in der Tabelle angezeigt. Bei fehlenden Angaben (Nachname, Vorname, ggf. Firmenname) oder
        fehlerhaften Inhalten (falsche E-Mail-Adresse) wird die fehlerhafte Zeile hervorgehoben.
      </p>
      <p>Vor einer Weiterverarbeitung sind die Angaben zu korrigieren.</p>
    </div>
  )
};
const receiverPersonalDataEmployees = {
  id: 'help-text-receiver-receiver-personal-data',
  title: RECEIVER_PERSONALDATA_TEXT,
  description: (
    <div>
      <p>Geben Sie hier die Personendaten des Empfängers ein. </p>
      <p>
        Die Personal ID und die E-Mail-Adresse sind optional. Es bleibt zu prüfen, ob der Empfänger ggf. schon weitere Geschenke erhalten
        hat oder noch erhält:
      </p>
      <p>
        - Bei Mitarbeitern ist der Erhalt weiterer Sachzuwendungen im gleichen Monat zu prüfen: Ab einem Gesamt-Bruttowert von über 50 EUR
        sind alle Sachzuwendungen lohnsteuerpflichtig. Sachzuwendungen aus einem besonderen persönlichen Anlass oder Streuartikel mit einem
        Einzelwert von bis zu 10 EUR sind dabei nicht einzubeziehen.
      </p>
      <p>
        - Bei Geschäftspartnern sind alle Sachzuwendungen in einem Wirtschaftsjahr zu prüfen. Ab einem Gesamtwert von über 50 EUR (bis 2023:
        35 EUR) pro Empfänger und Geschäftsjahr sind die Geschenke nicht mehr als Betriebsausgabe abziehbar. Der Wert von 50 EUR (bis 2023:
        35 EUR) ist für vorsteuerabzugsberechtigte Unternehmen ein Nettowert, für alle anderen ein Bruttowert.
      </p>
    </div>
  )
};

const receiverPersonalDataBusinessParterns = {
  id: 'help-text-receiver-receiver-personal-data',
  title: RECEIVER_PERSONALDATA_TEXT,
  description: (
    <div>
      <p>Geben Sie hier die Personendaten des Empfängers ein. </p>
      <p>
        Die E-Mail-Adresse ist optional. Es bleibt zu prüfen, ob der Empfänger ggf. schon weitere Geschenke erhalten hat oder noch erhält:
      </p>
      <p>
        - Bei Mitarbeitern ist der Erhalt weiterer Sachzuwendungen im gleichen Monat zu prüfen: Ab einem Gesamt-Bruttowert von über 50 EUR
        sind alle Sachzuwendungen lohnsteuerpflichtig. Sachzuwendungen aus einem besonderen persönlichen Anlass oder Streuartikel mit einem
        Einzelwert von bis zu 10 EUR sind dabei nicht einzubeziehen.
      </p>
      <p>
        - Bei Geschäftspartnern sind alle Sachzuwendungen in einem Wirtschaftsjahr zu prüfen. Ab einem Gesamtwert von über 50 EUR (bis 2023:
        35 EUR) pro Empfänger und Geschäftsjahr sind die Geschenke nicht mehr als Betriebsausgabe abziehbar. Der Wert von 50 EUR (bis 2023:
        35 EUR) ist für vorsteuerabzugsberechtigte Unternehmen ein Nettowert, für alle anderen ein Bruttowert.
      </p>
    </div>
  )
};

export const helpTextReceiverEmployees = { title: RECEIVER_PAGE_TITLE, numberOfReceivers, receiverPersonalDataEmployees };
export const helpTextReceiverBusinessParterns = {
  title: RECEIVER_PAGE_TITLE,
  numberOfReceivers,
  receiverPersonalDataBusinessParterns,
  infoForReceiver
};

export const helpApprovalText = {
  title: APPROVAL_PAGE_TITLE,
  infoForReceiver
};
