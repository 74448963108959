import React from "react";
import PropTypes from "prop-types";
import "./_footer.scss";
import FooterLinks from "./FooterLinks";
import { buildDate, buildVersion } from "../../Utils/constants";
import dayjs from "dayjs";

function Footer(props) {
  const version = props.appInfo?.backendBuildVersion
    ? buildVersion + props.appInfo.backendBuildVersion
    : "Version: " + (props.appInfo.buildVersion || "BUILD_VERSION");

  let deployDate =
    buildDate +
    (props.deployDate || dayjs(props.appInfo.deployDate).format("DD.MM.YYYY"));
  // If the date is invalid, for old multi repos
  if (deployDate === "Stand: Invalid Date") {
    deployDate = buildDate + props.appInfo.deployDate;
  }

  return (
    <div className="footer">
      <FooterLinks footerLinks={props.footerLinks} />
      <div>
        <span>{version}</span>
        <span> | </span>
        <span>{deployDate}</span>
      </div>
    </div>
  );
}

Footer.propTypes = {
  appInfo: PropTypes.object,
  footerLinks: PropTypes.array,
};

export default Footer;
