import { decorate, observable, action } from 'mobx';
import { sstTrackingHelper } from '../Utils/utils';

export default class UserSettingsStore {
  rootStore;
  tableSort;
  hideProductTour = true;
  isProductTourVisible = false;
  isFeatureTourShown = observable({});
  hideAskingRolePermision = true;

  // === Feedback === //
  feedbackGiftsCompleted = observable({});
  isFeedbackProvided = false;


  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setUserSettings(data) {
    this.tableSort = data.tableSort || [];
    this.hideProductTour = data.hideProductTour;
    this.isProductTourVisible = !data.hideProductTour;
    this.rootStore.filtersStore.showAllTableEntries = data.showAllTableEntries || true;
    //this.rootStore.filtersStore.activeFilters = data.activeFilters || [];
    this.hideAskingRolePermision = data.hideAskingRolePermision;

    this.feedbackGiftsCompleted = data.giftFeedback || {};
    this.isFeedbackProvided = data.isFeedbackProvided || false;

    this.isFeatureTourShown =
      data.isFeatureTourShown === undefined
        ? {
            basicData: false,
            tasks: false,
            percentageTasks: false,
            overviewPage: false,
            operations: false,
            evaluation: false
          }
        : data.isFeatureTourShown;
    sstTrackingHelper('user_settings', 'Einstellungen speichern', '/app/settingssaved', this.rootStore.apiStore.userInfo.isExternalUser);
  }

  getSettingsForDB() {
    return {
      tableSort: this.tableSort,
      hideProductTour: this.hideProductTour,
      showAllTableEntries: this.rootStore.filtersStore.showAllTableEntries,
      //activeFilters: this.rootStore.filtersStore.activeFilters,
      hideAskingRolePermision: this.hideAskingRolePermision,
      isFeatureTourShown: this.isFeatureTourShown,
      // feedback popup settings
      giftFeedback: this.feedbackGiftsCompleted,
      isFeedbackProvided: this.isFeedbackProvided,
    };
  }

  saveSettings() {
    this.rootStore.apiStore.updateUserSettings(this.getSettingsForDB());
  }
}

decorate(UserSettingsStore, {
  // Observables
  tableSort: observable,
  hideProductTour: observable,
  isProductTourVisible: observable,
  isFeatureTourShown: observable,
  hideAskingRolePermision: observable,
  isFeedbackProvided: observable,
  feedbackGiftsCompleted: observable,

  //actions:
  setUserSettings: action,
  addFeedbackGift: action,
  resetFeedbackGifts: action,
  getFeedbackGift: action,
});
