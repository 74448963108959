import React from "react";
import "./_style.scss";
import { royalBlue } from "../../Utils/styleHelper";
import { ALL_FILTER_LABEL } from "../../Stores/FiltersStore";
import { generateGtmEventName } from "../../Utils/utils";
import { actions, activeArea } from "../../Utils/constants";

function StatusFilter(props) {
  const statusFilter = (filterInfo, index) => {
    const { label, value, onClick } = filterInfo;
    /* Edge case - Alle filter */
    const showAllEntries = props.activeFilters.length === 0;
    const isFilterActive =
      label === ALL_FILTER_LABEL
        ? showAllEntries
        : props.isFilterActive(filterInfo);
    const isLatChild = index === props.filterOptions.length - 1;

    return (
      <div
        style={{ display: "flex", paddingBottom: isLatChild ? "0px" : "6px" }}
        key={`status-filter-${index}`}
      >
        <button
          style={{
            display: "flex",
            textAlign: "left",
            marginRight: "auto",
            padding: "0",
          }}
          onClick={onClick}
        >
          <span
            style={{ marginRight: "7px" }}
            className={isFilterActive ? "doughnut" : "circle"}
          />
          <span style={isFilterActive ? { color: royalBlue } : {}}>
            {label}
          </span>
        </button>
        <span
          style={
            isFilterActive ? { opacity: 1, color: royalBlue } : { opacity: 0.6 }
          }
        >
          {props.getNumberOfEntriesForFilter(value)}
        </span>
      </div>
    );
  };

  return (
    <div className="status">
      <div id="filterTag">{props.filterTitle}</div>
      <div
        className="filter-options-container"
        data-gtmid={generateGtmEventName(
          activeArea.caseOverview,
          actions.select,
          "Filter"
        )}
      >
        {props.filterOptions.map((filter, index) => {
          return statusFilter(filter, index);
        })}
      </div>
    </div>
  );
}

export default StatusFilter;
