import React from "react";
import "./_style.scss";
import { generateGtmEventName, getGtmIdDescriptionFromButtonValue } from "../../Utils/utils";

class RadioButton extends React.PureComponent {
  render() {
    let className = this.props.isInvalid
      ? "radioButton invalid"
      : "radioButton";
    if (this.props.class !== undefined) className += " " + this.props.class;
   
    const gtmInfo = getGtmIdDescriptionFromButtonValue(this.props.gtmInfoValue, this.props.id);
    return (
      <div 
        className={className}
        data-gtmid={gtmInfo.activeArea && generateGtmEventName(gtmInfo.activeArea, gtmInfo.action, gtmInfo.description)}>
        <input
          type="radio"
          id={this.props.id}
          checked={this.props.checked}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          // data-gtmid={generateGtmEventName(
          //   gtmIdArea,
          //   gtmIdAction,
          //   gtmIdDescription
          // )}
        />
        <label
          className={`radio-label ${this.props.isInvalid ? "invalid" : ""}`}
          htmlFor={this.props.id}
          onClick={(e) => {
            this.props.onClick(this.props.value);
          }}
        >
          {this.props.label}
        </label>
        {this.props.isInvalid && this.props.errorMsg && (
          <div className="radio-error-message">{this.props.errorMsg}</div>
        )}
      </div>
    );
  }
}

export default RadioButton;
