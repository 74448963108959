import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { WORKFLOW } from '../WorkflowNavigation/workflow';
import InputCard, { INPUT_CARD_TYPE } from '../../../Components/InputCard';
import { GIFTCASE_RECEIVER_PATH } from '../../../Utils/paths';
import { getObjectByPropValueFromArray, isEmpty } from '../../../sma-shared/Utils/utils';
import OneReceiver from './oneReceiver';
import MultipleReceivers from './multipleReceivers';
import {
  CANCEL_BUTTON,
  MULTIPLE_TO_ONE_RECEIVER_CHANGES_MESSAGE,
  numberOfReceiversType,
  RECEIVER_CHANGES_TITLE,
  OK_BUTTON
} from '../../../Utils/constants';
import { receiverType } from '../../../Utils/constants';
import { copyIcon } from '../../../Assets/dynamic-svg/general-icons';
import { royalBlue } from '../../../Utils/styleHelper';
import { INFO_FOR_EMPFANGER_TITLE, INFO_FOR_EMPFANGER, INFO_FOR_EMPFANGER_COPYTEXT, COPY_TEXT_TO_CLIPBOARD } from '../giftCaseLabels';
import { showErrorInView, scrollIntoViewById, copyToClipboard } from '../../../Utils/utils';
import { helpTextReceiverEmployees, helpTextReceiverBusinessParterns } from '../../../HelpText/createGiftHelpText';

function Receiver(props) {
  const { giftDataStore, helpTextStore, createGiftStore, modalStore } = props.rootStore;

  const oneReceiverRef = useRef(null);
  const multileReceiversRef = useRef(null);

  const [numberOfReceivers, setNumberOfReceivers] = useState(giftDataStore.numberOfReceivers);
  const [isInvalidNumberOfReceivers, setIsInvalidNumberOfReceivers] = useState(false);

  useEffect(() => {
    createGiftStore.currentWorkflowStep = getObjectByPropValueFromArray(WORKFLOW, 'path', GIFTCASE_RECEIVER_PATH);
    createGiftStore.currentWorkflowStep.validate = validate;
    if (giftDataStore.receiverType === receiverType.bussinesPartner.value) helpTextStore.setHelpText(helpTextReceiverBusinessParterns);
    else helpTextStore.setHelpText(helpTextReceiverEmployees);
    return () => {
      helpTextStore.resetHelpText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNumberOfReceivers(giftDataStore.numberOfReceivers);
    createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.numberOfReceivers]);

  const validate = () => {
    let isValid = !validateInput(giftDataStore.numberOfReceivers, setIsInvalidNumberOfReceivers);

    if (oneReceiverRef.current) {
      isValid &&= oneReceiverRef.current.validate();
    }

    if (multileReceiversRef.current) {
      isValid &&= multileReceiversRef.current.validate();
    }

    if (!isValid) {
      const errorClassName = giftDataStore.numberOfReceivers === numberOfReceiversType.multiple.value ? 'error-message-section' : 'invalid';
      showErrorInView(errorClassName);
    }
    createGiftStore.currentWorkflowValidating = !createGiftStore.currentWorkflowValidating;
    return isValid;
  };

  const validateInput = (value, setInvalidState, ignoreEmptyValue = false) => {
    const invalidState = ignoreEmptyValue ? false : isEmpty(value);
    setInvalidState(invalidState);
    return invalidState;
  };

  const handelNumberOfReceiversChanged = newValue => {
    if (newValue === numberOfReceiversType.one.value && giftDataStore.receivers && giftDataStore.receivers.length > 1) {
      modalStore.showChoice(
        RECEIVER_CHANGES_TITLE,
        MULTIPLE_TO_ONE_RECEIVER_CHANGES_MESSAGE,
        CANCEL_BUTTON,
        () => {
          setNumberOfReceivers(numberOfReceiversType.multiple.value);
        },
        OK_BUTTON,
        () => {
          giftDataStore.receivers = giftDataStore.receivers.slice(0, 1);
          updateNumberOfReceiversChanged(newValue);
        }
      );
    } else {
      updateNumberOfReceiversChanged(newValue);
    }
  };

  const updateNumberOfReceiversChanged = value => {
    setNumberOfReceivers(value);
    giftDataStore.numberOfReceivers = value;
    createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
  };

  const receiverNumberInputsCard = () => [
    {
      type: INPUT_CARD_TYPE.label,
      isMandatory: true,
      label: 'An wie viele Empfänger möchten Sie verschenken?'
    },
    {
      type: INPUT_CARD_TYPE.radioButton,
      checked: numberOfReceivers === numberOfReceiversType.one.value,
      label: numberOfReceiversType.one.label,
      name: 'receiverType',
      id: 'oneReceiver',
      value: numberOfReceiversType.one.value,
      isInvalid: isInvalidNumberOfReceivers,
      disabled: !giftDataStore.canEdit,
      onChange: e => {},
      onClick: value => {
        validateInput(value, setIsInvalidNumberOfReceivers, true);
        handelNumberOfReceiversChanged(value);
      }
    },
    {
      type: INPUT_CARD_TYPE.radioButton,
      checked: numberOfReceivers === numberOfReceiversType.multiple.value,
      label: numberOfReceiversType.multiple.label,
      name: 'receiverType',
      id: 'multipleReceivers',
      value: numberOfReceiversType.multiple.value,
      isInvalid: isInvalidNumberOfReceivers,
      disabled: !giftDataStore.canEdit,
      onChange: e => {},
      onClick: value => {
        validateInput(value, setIsInvalidNumberOfReceivers, true);
        handelNumberOfReceiversChanged(value);
      }
    }
  ];

  const showHelpText = helpTextId => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  const renderApprovedInfos = () => {
    return [
      {
        type: INPUT_CARD_TYPE.label,
        label: INFO_FOR_EMPFANGER
      },
      {
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderCopyableText()
      }
    ];
  };
  const renderCopyableText = () => {
    return (
      <div className="copyable-area">
        <span className="copyable-text">{INFO_FOR_EMPFANGER_COPYTEXT}</span>
        <button
          className="copy-text-button"
          onClick={e => {
            e.preventDefault();
            copyToClipboard(INFO_FOR_EMPFANGER_COPYTEXT);
          }}>
          {copyIcon(royalBlue)} <span>{COPY_TEXT_TO_CLIPBOARD}</span>
        </button>
      </div>
    );
  };

  return (
    <div className={numberOfReceivers === numberOfReceiversType.one.value || !numberOfReceivers ? 'flex-container' : 'no-flex-container'}>
      <div className={numberOfReceivers === numberOfReceiversType.one.value || !numberOfReceivers ? 'create-gift-page-content' : ''}>
        <div className="page-header">
          <span className="title">Empfänger</span>
        </div>
        <InputCard
          title="Anzahl"
          helpTextId={
            helpTextReceiverEmployees && helpTextReceiverEmployees.numberOfReceivers ? helpTextReceiverEmployees.numberOfReceivers.id : ''
          }
          onClickHelpText={showHelpText}
          isMandatory
          data={receiverNumberInputsCard()}
        />

        {numberOfReceivers === numberOfReceiversType.one.value && <OneReceiver ref={oneReceiverRef} />}
        {numberOfReceivers === numberOfReceiversType.multiple.value && <MultipleReceivers ref={multileReceiversRef} />}
        {giftDataStore.receiverType === receiverType.bussinesPartner.value ? (
          <InputCard
            title={INFO_FOR_EMPFANGER_TITLE}
            data={renderApprovedInfos()}
            helpTextId={
              helpTextReceiverBusinessParterns && helpTextReceiverBusinessParterns.infoForReceiver
                ? helpTextReceiverBusinessParterns.infoForReceiver.id
                : ''
            }
            onClickHelpText={showHelpText}
          />
        ) : (
          ''
        )}
      </div>
      {(numberOfReceivers === numberOfReceiversType.one.value || !numberOfReceivers) && (
        <div className="grey-container-without-sidebar"></div>
      )}
    </div>
  );
}

export default inject('rootStore')(observer(Receiver));
