import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { menuDotsIcon } from "../../Assets/dynamic-svg/general-icons";
import { tuna } from "../../Utils/styleHelper";
import MenuActions from "./MenuActions";
import { FOOTER_FIX_HEIGHT, actions, activeArea } from "../../Utils/constants";
import { generateGtmEventName } from "../../Utils/utils";

function ContextMenu(props) {
  const menuButtonRef = useRef();
  let contextMenuRef = undefined;
  const [showMenu, setShowMenu] = useState(false);
  const [menuOutOfBounds, setMenuOutOfBounds] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      /* clicking contextMenu's element, descendent elements or menu button - do nothing */
      if (
        !contextMenuRef ||
        contextMenuRef.contains(event.target) ||
        menuButtonRef.current.contains(event.target)
      ) {
        return;
      }
      setShowMenu(false);
    };
    if (showMenu) {
      document.addEventListener("mousedown", listener);
      if (props.scrollTableRowIndex >= 0) {
        const tableBody = document.getElementsByTagName("tbody")[0];
        tableBody.addEventListener("scroll", listener);
      }
    }

    return () => {
      document.removeEventListener("mousedown", listener);
      if (props.scrollTableRowIndex >= 0) {
        const tableBody = document.getElementsByTagName("tbody")[0];
        tableBody.removeEventListener("scroll", listener);
      }
    };
  }, [showMenu, contextMenuRef, props]);

  function toggleMenu(e) {
    e.stopPropagation();
    calcMenuOutOfBounds();
    setShowMenu((isMenuVisible) => !isMenuVisible);
  }

  function calcMenuOutOfBounds() {
    const windowHeight = window.innerHeight;
    const menuBottom = menuButtonRef.current.getBoundingClientRect().bottom;
    const nrOfMenuActions = props.listItems.length;
    const menuHeight = 30 + 30 * nrOfMenuActions - 6;

    const isMenuOutOfBounds =
      windowHeight - (menuBottom + menuHeight) < FOOTER_FIX_HEIGHT;
    setMenuOutOfBounds(isMenuOutOfBounds);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  return (
    <Fragment>
      <button
        tabIndex="-1"
        key={`context-menu-${props.index}`}
        ref={menuButtonRef}
        style={{
          height: "30px",
          padding: "0",
          display: "flex",
          marginLeft: "auto",
        }}
        onClick={(e) => toggleMenu(e)}
        data-gtmid={generateGtmEventName(
          activeArea.caseOverview,
          actions.select,
          "ContextMenu"
        )}
      >
        <span
          style={{ height: "100%", width: "12px", minWidth: "12px" }}
          className="icon-general"
        >
          {menuDotsIcon(tuna)}
        </span>
      </button>
      {showMenu && (
        <MenuActions
          isMenuOutOfBounds={menuOutOfBounds}
          rowMenuRef={(ref) => (contextMenuRef = ref)}
          listItems={props.listItems}
          index={props.index}
          closeMenu={() => closeMenu()}
          scrollTableRowIndex={props.scrollTableRowIndex}
        />
      )}
    </Fragment>
  );
}

ContextMenu.defaultProps = {
  listItems: [],
  index: 0,
};

ContextMenu.propTypes = {
  listItems: PropTypes.array,
  index: PropTypes.number,
};

export default ContextMenu;
