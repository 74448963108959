import React, { useState } from "react";
import Dropzone from "react-dropzone";
import "./_style.scss";
import { generateGtmEventName } from "../../Utils/utils";

function FileInput(props) {
  const [dropZoneClass, setDropZoneClass] = useState("");
  const gtmArea = props.gtmInfo ? props.gtmInfo.gtmArea : undefined;
  const gtmAction = props.gtmInfo ? props.gtmInfo.gtmAction : undefined;
  const gtmDescription = props.gtmInfo
    ? props.gtmInfo.gtmDescription
    : undefined;

  return (
    <Dropzone
      disabled={props.disabled}
      onDrop={(acceptedFiles) => {
        setDropZoneClass("onDropLeave");
        props.onChange(acceptedFiles);
      }}
      onDragEnter={() => setDropZoneClass("onDrop")}
      onDragLeave={() => setDropZoneClass("onDropLeave")}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          className="file-input-container"
          {...getRootProps({ onClick: (event) => event.stopPropagation() })}
        >
          <label htmlFor={props.label}>
            {props.label}{" "}
            {!props.isMandatory && (
              <span className="optional-info">optional</span>
            )}
          </label>
          <section className={`custom-file-input-container ${dropZoneClass}`}>
            <span className="file-upload-hint">
              Dateien hier hinziehen oder
            </span>
            <label className="custom-file-input" disabled={props.disabled}>
              <span
                data-gtmid={
                  gtmArea &&
                  generateGtmEventName(gtmArea, gtmAction, gtmDescription)
                }
              >
                Auswählen
              </span>
              <section>
                <input
                  type="file"
                  className="file-input-element"
                  name={props.label}
                  onChange={props.onChange}
                  placeholder={props.placeholder}
                  {...getInputProps({
                    accept: props.accept,
                    multiple: props.multiple,
                    disabled: props.disabled,
                  })}
                />
              </section>
            </label>
          </section>
        </div>
      )}
    </Dropzone>
  );
}

export default FileInput;
