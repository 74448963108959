import * as Scroll from 'react-scroll';

import { NotificationManager } from 'react-notifications';

export const isLocalhost = window && window.location.host.startsWith('localhost');

export const IS_FORMATTING = /(\[[a-zA-Z0-9-_#.=/]+\])/gi;

export const isE2ETest = (window && window.location.search.toLowerCase() === '?e2e') || sessionStorage.getItem('e2e');

// SST: event and siteId are mandatory and relate to the application.
// SST: pageId and content are mandatory and relate to the tracking event
// SST: Target is an optional array with 4 elements: group, name, version, type
// SST: requrl is a custom or the current page url
export function trackPI({ pageId = '', content = '', requrl = window.location.pathname, Target = undefined, event = '', siteid = '' }) {
  if (window.dataLayer) {
    window.dataLayer.push({
      pageId,
      content,
      requrl,
      Target,
      event,
      siteid,
    });
  }
}

export function trackPITarget({ content = '', group = '', name = '', event = '', siteid = '' }) {
  if (window.dataLayer) {
    window.dataLayer.push({
      content,
      Target: [group, name, 1, 'a'],
      event,
      siteid,
    });
  }
}

export function showCookieBanner() {
  if (window.UC_UI) {
    window.UC_UI.showSecondLayer();
  }
}

/*
 * String and number utils
 */
export function isEmpty(obj) {
  if (obj && (typeof obj === 'string' || obj instanceof String)) {
    return obj.trim() === '';
  }
  if (obj && typeof obj === 'object') {
    return Object.values(obj).every(value => isEmpty(value));
  }

  return !obj;
}

export function validateEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function validateUrl(url) {
  const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
  return urlRegex.test(url);
}

export function isRegexValid(regex, value) {
  value = value && (typeof value === 'string' || value instanceof String) ? value : '';
  const match = value.match(regex);
  return match !== null && match[0] === value;
}

function valueToTextNumber(numberText) {
  numberText = numberText ? numberText.toString() : '';
  return numberText && (typeof numberText === 'string' || numberText instanceof String) ? numberText.replace(/,/g, '.') : '';
}

export function validateNumber(numberText, timeUnit) {
  numberText = valueToTextNumber(numberText);
  const regex = timeUnit === '1' ? /\d*\.{0,1}\d*/g : /\d*/g;
  return isRegexValid(regex, numberText);
}

export function validateDecimalAsText(numberText) {
  numberText = valueToTextNumber(numberText);
  const regex = /\d*\.{0,1}\d*/g;
  const isValid = isRegexValid(regex, numberText);
  return isValid;
}

export function validateIntAsText(numberText) {
  numberText = valueToTextNumber(numberText);
  const regex = /\d*/g;
  const isValid = isRegexValid(regex, numberText);
  return isValid;
}

export function roundToDecimals(numberText, decimalDigits = 1) {
  numberText = valueToTextNumber(numberText);
  const value = numberText ? Math.round(parseFloat(numberText) * Math.pow(10, decimalDigits)) / Math.pow(10, decimalDigits) : 0;
  return value;
}

export function roundTo2Decimals(numberText) {
  return roundToDecimals(numberText, 2);
}

export function roundDecimalsToDivision(numberText, division = 2) {
  numberText = valueToTextNumber(numberText);
  const value = numberText ? Math.round(parseFloat(numberText).toFixed(2) * division) / division : 0;
  return value;
}

export function roundDecimalsToQuarter(numberText) {
  return roundDecimalsToDivision(numberText, 4);
}

export function formatNumber(number, decimalDigits = 2, culture = 'de-DE') {
  let numberFormated = roundToDecimals(number, decimalDigits);
  if (numberFormated) {
    const decimalSeparator = getDecimalSeparator(culture);
    const parts = numberFormated.toFixed(decimalDigits).split('.');
    parts[0] = roundToDecimals(parts[0]).toLocaleString(culture);
    numberFormated = parts.join(decimalSeparator);
  }
  return numberFormated;
}

export function getDecimalSeparator(culture) {
  const decimalNumber = 1.1;
  const decimalSeparator = decimalNumber.toLocaleString(culture).replace(/\d/g, '');
  return decimalSeparator;
}

export function numBetweenMinAndMax(n, min, max) {
  if (n > max) {
    return max;
  } else if (n < min) {
    return min;
  }
  return n;
}

export function formatDate(date, format = 'DD.MM.YYYY') {
  return date ? date.format(format) : '';
}

export function formatDateObject(date) {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Intl.DateTimeFormat('de-DE', options).format(date);
}

export function formatValue(value, defaultValue = '-') {
  if (value && (typeof value === 'string' || value instanceof String)) {
    return value.trim() ? value.trim() : defaultValue;
  }
  return defaultValue;
}

export function removeNonValidChars(stringValue) {
  // returns string containing only numbers . ,
  return stringValue.replace(/[^0-9./,]/g, '');
}

export function formatStringToEUR(stringValue) {
  // returns EUR value rounded to 2
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(stringValue);
}

export function formatEURStringToFloat(stringValue) {
  if (stringValue) {
    let removeSeparatingPoint = '';
    for (let index in stringValue) {
      if (stringValue[index] !== '.') {
        removeSeparatingPoint += stringValue[index];
      }
    }

    let addDecimalPoint = '';
    for (let index in removeSeparatingPoint) {
      if (removeSeparatingPoint[index] === ',') {
        addDecimalPoint += '.';
      } else addDecimalPoint += removeSeparatingPoint[index];
    }

    return parseFloat(addDecimalPoint);
  }
}

/*
 * Array utils
 */
export function getUniqueArray(a, keepEmptyKeys) {
  var seen = {};
  return a.filter(function (item) {
    return (!keepEmptyKeys && !item) || seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
}

export function getUniqueArrayByPropertyFromObjectArray(array, propertyName, shouldNotSort, keepEmptyKeys) {
  let values = [...new Set(array.map(task => (task[propertyName] ? task[propertyName].trim() : '')))];
  values = getUniqueArray(values, keepEmptyKeys);
  return shouldNotSort ? values : values.sort();
}

export function getUniqueArrayByPropertyFromObjectArrays(array1, array2, propertyName, shouldNotSort) {
  const values1 = [...new Set(array1.map(task => (task[propertyName] ? task[propertyName].trim() : '')))];
  const values2 = [...new Set(array2.map(task => (task[propertyName] ? task[propertyName].trim() : '')))];
  const values = getUniqueArray(values1.concat(values2));
  return shouldNotSort ? values : values.sort();
}

export function getObjectByPropValueFromArray(array, prop, value) {
  if (Array.isArray(array)) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].hasOwnProperty(prop)) {
        return null;
      } else if (array[i][prop] === value) {
        return array[i];
      }
    }
  }
  return null;
}

export function getIndexOfObjectByPropValueFromArray(array, prop, value) {
  if (Array.isArray(array)) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].hasOwnProperty(prop)) {
        return null;
      } else if (array[i][prop] === value) {
        return i;
      }
    }
  }
  return -1;
}

/*
 * UI Utils
 */

export function showNotification(text) {
  if (!isE2ETest) NotificationManager.success(text, '', 5000);
}

export function scrollElementIntoView(className) {
  if (className) {
    var elem = document.querySelector('.' + className);
    if (elem) {
      var elementBounding = elem.getBoundingClientRect();
      // scroll only if the element is not in visible area
      if (elementBounding.top < 0 || elementBounding.bottom > window.innerHeight - 80) {
        Scroll.scroller.scrollTo(className, {
          duration: 1000,
          delay: 0,
          smooth: true,
          offset: -200,
        });
      }
    }
  }
}

export function scrollIntoView(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView(true);
  }
}

export function scrollToTop() {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
}

export function focusElement(querySelector = 'input') {
  setTimeout(() => {
    // wait to be created elements
    const firstElement = document.querySelectorAll(querySelector)[0];
    if (firstElement !== undefined) {
      firstElement.focus();
    }
  }, 100);
}

/*
 * Application utils
 */
export function getIDeskLink(url, productID) {
  const HI_REGEX = /(hi([0-9])*)/gi;
  const matches = url.match(HI_REGEX);
  const BASE_LINK = `https://beta.products.haufe.de/${productID}/document/`;
  let link_to_idesk = '';
  if (matches !== null && (matches.length !== 0 || isEmpty(productID))) {
    const hi = getCustomHI(matches);
    const lastHIPosition = url.lastIndexOf('HI');
    if (url.lastIndexOf('_') >= lastHIPosition || url.indexOf('absatz') >= 0) {
      link_to_idesk = BASE_LINK + `${hi}/${url}`;
    } else {
      link_to_idesk = BASE_LINK + `${hi}`;
    }
    return link_to_idesk;
  }
}

// transforms an array into a string connected with dots ['HI1','HI2'] => 'HI1.HI2'
export function getCustomHI(array) {
  let longString = '';
  array.map((value, index) => {
    if (array.length === index + 1) return (longString += value);

    return (longString += `${value}.`);
  });
  return longString.toUpperCase();
}
