import { INVOICE_INFORMATION, PAYROLL_PAGE_TITLE, PROCESSING_BY_FIBU } from '../Pages/CreateGiftPages/giftCaseLabels';

export const helpTextFibu = {
  title: PROCESSING_BY_FIBU,
  statusInf: {
    id: 'help-text-accounting-info',
    title: INVOICE_INFORMATION,
    description: (
      <div>
        <p>
          <b> Interne Belegnummer: </b>Hier tragen Sie die interne Belegnummer aus Ihrem Fibu-Programm ein, unter der Sie die Rechnung
          gebucht haben. Damit können Sie die korrespondierenden Geschäftsvorfälle im Geschenkemanager und in Ihrem Fibu-Programm jederzeit
          identifizieren.
        </p>
        <p>
          <b> Rechnungsnummer:</b> Hier können Sie die Rechnungsnummer eintragen und anschließend das Dokument hochladen. Dies verbindet
          Geschenkefall und Eingangsrechnung direkt im Geschenkemanager und vereinfacht spätere Analysen.
        </p>
        <p>
          <b> Aufwand (brutto/netto) pro Geschenk:</b> Anhand dieser Eingaben prüft der Geschenkemanager die Abzugsfähigkeit des Geschenks
          als Betriebsausgabe und die Anforderungen an die lohnsteuerliche Behandlung. Als Ergebnis werden Buchungssätze für die
          Finanzbuchhaltung und lohnsteuerliche Vorgaben für die Entgeltabrechnung angezeigt.
        </p>
        <p>
          <b> Grundsätzlich gilt:</b> Für die Gewährung des Betriebsausgaben- und Vorsteuerabzugs muss zunächst eine ordnungsgemäße Rechnung
          nach § 14 UStG vorliegen. Des Weiteren sind Pflichtangaben unveränderbar mit der Rechnung zu verknüpfen. Diese sind: Name des
          Beschenkten, ggf. für die Zuordenbarkeit auch Anschrift, sowie der Anlass. Mit dem Geschenkemanager können diese Anforderungen
          erfüllt werden.
        </p>
      </div>
    )
  }
};

export const helpTextPayroll = {
  title: PAYROLL_PAGE_TITLE
};
