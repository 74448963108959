import React, { useEffect } from 'react';
import LeftContent from './LeftContent';
import RightContent from './RightContent';
import { historyListener } from '../../Utils/utils';

function EmployeesOverview(props) {
  useEffect(() => {
    historyListener(props.history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="two-container-page">
      <LeftContent />
      <RightContent />
    </div>
  );
}

export default EmployeesOverview;
