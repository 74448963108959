import React, { useState } from 'react';
import '../_style.scss';
import { inject } from 'mobx-react';
import { chevronLeftIcon } from '../../../Assets/dynamic-svg/general-icons';
import { royalBlue } from '../../../Utils/styleHelper';
import { checkPasswordStrength } from '../../../Utils/utils';
import InputCard, { INPUT_CARD_TYPE } from '../../../Components/InputCard';
import { LOGIN } from '../../../Utils/paths';
import { showErrorInView } from '../../../Utils/utils';
import { validateEmail, isEmpty } from '../../../sma-shared/Utils/utils';
import {
  FIRST_NAME_TEXT,
  LAST_NAME_TEXT,
  EMAIL_TEXT,
  PASSWORD_TEXT,
  PASSWORD_REPEAT_TEXT,
  BACK_TO_LOGIN_TEXT,
  REGISTER_TEXT,
  YOUR_DATA_TEXT,
  REGISTER_DESCRIPTION_TEXT,
  SEND_TEXT,
  FIRTS_NAME_ERROR,
  LAST_NAME_ERROR,
  EMAIL_ERROR,
  PASSWORD_ERROR,
  PASSWORD_NOT_STRENGTH_ERROR,
  PASSWORD_REPEAT_ERROR,
  PASSWORD_REPEAT_NOT_SAME_ERROR,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE_REGISTER,
  AVV_CHECKBOX_TEXT
} from '../labels';

function Registration(props) {
  const { apiStore } = props.rootStore;
  const { history } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [signupMessage, setSignupMessage] = useState('');
  const [signupWithSuccess, setSignupWithSuccess] = useState();
  const [avv, setAvv] = useState(false);

  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidPasswordRepeat, setIsValidPasswordRepeat] = useState(true);
  const [isValidAvvCheckbox, setIsValidAvvCheckbox] = useState(true);

  const validate = () => {
    const isFirstNameValid = validateInput(firstName, setIsValidFirstName);
    const isLastNameValid = validateInput(lastName, setIsValidLastName);

    const isEmailValid = validateEmail(email);
    setIsValidEmail(isEmailValid);

    const passwordStrength = checkPasswordStrength(password);
    const isPasswordValid = !isEmpty(password) && Object.values(passwordStrength).every(value => value);
    setIsValidPassword(isPasswordValid);

    const isPasswordRepeatValid = !isEmpty(passwordRepeat) && password === passwordRepeat;
    setIsValidPasswordRepeat(isPasswordRepeatValid);

    setIsValidAvvCheckbox(avv);

    const isValid = isFirstNameValid && isLastNameValid && isEmailValid && isPasswordValid && isPasswordRepeatValid && avv;
    if (!isValid) {
      showErrorInView();
    }

    return isValid;
  };

  const validateInput = (value, setValidState, ignoreEmptyValue = false) => {
    const isValid = ignoreEmptyValue ? true : !isEmpty(value);
    setValidState(isValid);
    return isValid;
  };

  const renderSignUpSection = () => {
    const inputs = [
      {
        type: INPUT_CARD_TYPE.text,
        label: FIRST_NAME_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: firstName,
        isInvalid: !isValidFirstName,
        errorMsg: FIRTS_NAME_ERROR,
        onChange: e => {
          setFirstName(e.target.value);
          validateInput(e.target.value, setIsValidFirstName, true);
        }
      },
      {
        type: INPUT_CARD_TYPE.text,
        label: LAST_NAME_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: lastName,
        isInvalid: !isValidLastName,
        errorMsg: LAST_NAME_ERROR,
        onChange: e => {
          setLastName(e.target.value);
          validateInput(e.target.value, setIsValidLastName, true);
        }
      },
      {
        type: INPUT_CARD_TYPE.text,
        label: EMAIL_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: email,
        isInvalid: !isValidEmail,
        errorMsg: EMAIL_ERROR,
        onChange: e => {
          setEmail(e.target.value);
          setIsValidEmail(e.target.value ? validateEmail(e.target.value) : true);
        }
      },
      {
        type: INPUT_CARD_TYPE.password,
        label: PASSWORD_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: password,
        isInvalid: !isValidPassword,
        errorMsg: isEmpty(password) ? PASSWORD_ERROR : PASSWORD_NOT_STRENGTH_ERROR,
        onChange: e => {
          setPassword(e.target.value);
          validateInput(e.target.value, setIsValidPassword, true);
        }
      },
      {
        type: INPUT_CARD_TYPE.password,
        label: PASSWORD_REPEAT_TEXT,
        isMandatory: true,
        placeholder: ' ',
        value: passwordRepeat,
        notAllowPaste: true,
        isInvalid: !isValidPasswordRepeat,
        errorMsg: password !== passwordRepeat ? PASSWORD_REPEAT_NOT_SAME_ERROR : PASSWORD_REPEAT_ERROR,
        onChange: e => {
          setPasswordRepeat(e.target.value);
          validateInput(e.target.value, setIsValidPasswordRepeat, true);
        }
      },
      {
        id: 'avv-checkbox',
        type: INPUT_CARD_TYPE.checkbox,
        name: AVV_CHECKBOX_TEXT,
        isMandatory: true,
        className: 'avv-checkbox-class',
        value: avv,
        errorMsg: 'Dies ist ein Pflichtfeld.',
        isInvalid: !isValidAvvCheckbox,
        onChange: () => {
          setAvv(!avv);
          if (!avv) {
            setIsValidAvvCheckbox(true);
          }
        }
      },
      {
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderSubmitButton()
      }
    ];

    return (
      <React.Fragment>
        <InputCard title={YOUR_DATA_TEXT} data={inputs} />
      </React.Fragment>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div>
        <div className="line-separator" />
        <button
          onClick={() => {
            if (validate()) {
              apiStore.signUp({ email, firstName, lastName, password, passwordRepeat }, result => {
                if (result.error) {
                  const message = ERROR_MESSAGE_REGISTER(result.error.code);
                  setSignupMessage(message);
                  setSignupWithSuccess(false);
                  showErrorInView('error');
                } else if (result) {
                  setSignupMessage(SUCCESS_MESSAGE(() => history.push(LOGIN)));
                  setSignupWithSuccess(true);
                }
              });
            }
          }}
          className="btn btn-primary right">
          {SEND_TEXT}
        </button>
      </div>
    );
  };

  return (
    <div className="auth-container">
      <div className="left-container">
        <button
          className="text-button"
          onClick={e => {
            history.push(LOGIN);
          }}>
          <span className="icon-general">{chevronLeftIcon(royalBlue)}</span>
          {BACK_TO_LOGIN_TEXT}
        </button>
        <div className="form">
          <div className="page-title">{REGISTER_TEXT}</div>
          <div className="description">{REGISTER_DESCRIPTION_TEXT}</div>
          {renderSignUpSection()}
          {signupMessage && <div className={`message ${signupWithSuccess ? 'success' : 'error'}`}>{signupMessage}</div>}
        </div>
      </div>
      <div className="right-container"></div>
    </div>
  );
}

export default inject('rootStore')(Registration);
