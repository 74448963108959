import React from 'react';
import './_style.scss';

class LoadingPage extends React.PureComponent {
  render() {
    return <div className="notFoundMessage">Wird geladen...</div>;
  }
}

export default LoadingPage;
