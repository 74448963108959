import React from "react";
import "./_style.scss";
import { generateGtmEventName } from "../../Utils/utils";
import { actions, activeArea } from "../../Utils/constants";

class CheckboxButton extends React.PureComponent {
  render() {
    const validationMessage = this.props.isInvalid && this.props.errorMsg;
    let className = this.props.isInvalid
      ? "checkbox-container invalid"
      : "checkbox-container";
    if (this.props.class !== undefined) className += " " + this.props.class;

    return (
      <div className={className}>
        <div>
          <input
            type="checkbox"
            id={this.props.id}
            checked={this.props.checked}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            data-gtmid={
              this.props.gtmId &&
              generateGtmEventName(
                activeArea.caseOverview,
                actions.select,
                this.props.gtmId
              )
            }
          />
          <label
            className="checkbox"
            disabled={this.props.disabled}
            htmlFor={this.props.id}
          />
          <label
            className="checkbox-text"
            disabled={this.props.disabled}
            htmlFor={this.props.id}
          >
            {this.props.name}
          </label>
        </div>
        {validationMessage && (
          <span className="text-error-message">{this.props.errorMsg}</span>
        )}
      </div>
    );
  }
}

export default CheckboxButton;
