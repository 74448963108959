import React, { Fragment } from 'react';

export const overwriteChangesModal = (modalStore, canUpdate, updateCallback, overwriteCallback) => {
  const modalButtons = (
    <Fragment>
      <button
        className="modal-btn secondary-button"
        onClick={() => {
          modalStore.hideModal();
        }}>
        Abbrechen
      </button>
      <button
        className={`modal-btn ${!canUpdate ? 'primary-button' : 'secondary-button'}`}
        onClick={() => {
          modalStore.hideModal();
          updateCallback();
        }}>
        Aktualisieren
      </button>
      {canUpdate && (
        <button
          className="modal-btn primary-button"
          onClick={() => {
            modalStore.hideModal();
            overwriteCallback();
          }}>
          Überschreiben
        </button>
      )}
    </Fragment>
  );

  modalStore.showModal(
    'Geänderte Daten überschreiben?',
    <div>
      Dieser Vorgang wurde durch einen anderen Nutzer geändert.
      <br />
      Möglicherweise arbeiten Sie parallel an diesem Vorgang.
      <br />
      {canUpdate && `Wenn Sie „Überschreiben“ auswählen, werden die Daten des anderen Nutzers überschrieben.`}
      {canUpdate && <br />}
      Wenn Sie „Aktualisieren", gehen Ihre Eingaben verloren und die Eingaben des anderen Nutzers werden übernommen.
    </div>,
    modalButtons,
    'warning'
  );
};
