import { status, ROLES, receiverType } from '../../../Utils/constants';
import {
  OVERVIEW_PATH,
  GIFTCASE_ENTRYCHECK_PATH,
  GIFTCASE_RECEIVER_PATH,
  GIFTCASE_GIFT_PATH,
  GIFTCASE_APPROVAL_PATH,
  GIFTCASE_REPORT_PATH,
  GIFTCASE_PAYROLL_PATH,
  GIFTCASE_FIBU_PATH
} from '../../../Utils/paths';
import OVERVIEW_ICON from '../../../Assets/svg/overview.svg';
import { isEmpty, validateEmail } from '../../../sma-shared/Utils/utils';
const OVERVIEW_TITLE = 'Fallübersicht';
const ENTRY_CHECK_TITLE = 'Einstiegs-Check';
const RECEIVER_TITLE = 'Empfänger';
const GIFT_TITLE = 'Geschenk';
const APPROVAL_TITLE = 'Genehmigung';
const PAYROLL_TITLE = 'Bearbeitung Entgeltabrechnung';
const FIBU_TITLE = 'Bearbeitung Fibu';
const REPORT_TITLE = 'Bericht';
const NEXT_NEUTRAL = 'Weiter zu ';
const BACK_NEUTRAL = 'Zurück zu ';
const NEXT_FEM = 'Weiter zur ';
const BACK_FEM = 'Zurück zur ';
const NEXT_MASC = 'Weiter zum ';
const BACK_MASC = 'Zurück zum ';

function getNextAction(buttonName, pathName) {
  return {
    name: `${buttonName}`,
    navigateTo: pathName
  };
}

function getBackAction(buttonName, pathName, left=true, className) {
  return {
    name: `${buttonName}`,
    navigateTo: pathName,
    left: left ?? false, // if button should be one the left side
    className
  };
}


function isFibuVisible(rootStore) {
  const { apiStore } = rootStore;
  // TODO: payroll role has access to FiBu?
  const isVisible =
    ((apiStore && apiStore.roles.includes(ROLES.admin)) ||
      apiStore.roles.includes(ROLES.accounting) ||
      apiStore.roles.includes(ROLES.payroll)) &&
    hasGiftStatus(rootStore, status.approved);

  return isVisible;
}

function isPayrollVisible(rootStore) {
  const { apiStore } = rootStore;
  // TODO: accounting role has access to Payroll?
  const isVisible =
    ((apiStore && apiStore.roles.includes(ROLES.admin)) ||
      apiStore.roles.includes(ROLES.accounting) ||
      apiStore.roles.includes(ROLES.payroll)) &&
    hasGiftStatus(rootStore, status.approved);

  return isVisible;
}

function hasGiftStatus(rootStore, statusToCheck) {
  const { giftDataStore } = rootStore;
  return giftDataStore.hasStatus(statusToCheck);
}

export const WORKFLOW = [
  {
    id: 1,
    title: OVERVIEW_TITLE,
    css_stepId: 'step-overview',
    path: OVERVIEW_PATH,
    icon: OVERVIEW_ICON,
    getActions: () => [],
    isVisible: () => true,
    isValid: () => true
  },
  {
    id: 2,
    title: ENTRY_CHECK_TITLE,
    css_stepId: 'step-entrycheck',
    apiStep: 'entry-check',
    path: GIFTCASE_ENTRYCHECK_PATH,
    getActions: () => [
      getBackAction(BACK_FEM + OVERVIEW_TITLE, OVERVIEW_PATH),
      getNextAction(NEXT_NEUTRAL + RECEIVER_TITLE, GIFTCASE_RECEIVER_PATH)
    ],
    isVisible: () => true,
    isValid: giftDataStore => {
      if (giftDataStore.isComplianceGiftPolicyVisible) {
        return giftDataStore.receiverType !== '' && giftDataStore.compliantGiftPolicy;
      } else {
        return giftDataStore.receiverType !== '';
      }
    }
  },
  {
    id: 3,
    title: RECEIVER_TITLE,
    css_stepId: 'step-receiver',
    apiStep: 'update-receiver',
    path: GIFTCASE_RECEIVER_PATH,
    getActions: () => [
      getBackAction(BACK_MASC + ENTRY_CHECK_TITLE, GIFTCASE_ENTRYCHECK_PATH),
      getNextAction(NEXT_MASC + GIFT_TITLE, GIFTCASE_GIFT_PATH),
    ],
    isVisible: () => true,
    isValid: giftDataStore => {
      if (giftDataStore.receivers.length > 0) {
        for (const receiver of giftDataStore.receivers) {
          const isEmailValid = isEmpty(receiver.email) || validateEmail(receiver.email);
          if (isEmpty(receiver.firstName) || isEmpty(receiver.lastName) || !isEmailValid) return false;
        }
        return true;
      }
      return false;
    }
  },
  {
    id: 4,
    title: GIFT_TITLE,
    css_stepId: 'step-gift',
    apiStep: 'update-gift-data',
    path: GIFTCASE_GIFT_PATH,
    getActions: () => [
      getBackAction(BACK_NEUTRAL + RECEIVER_TITLE, GIFTCASE_RECEIVER_PATH),
      getNextAction(NEXT_FEM + APPROVAL_TITLE, GIFTCASE_APPROVAL_PATH),
    ],
    isVisible: () => true,
    isValid: giftDataStore => {
      let isValid = giftDataStore.giftDate !== null && giftDataStore.giftDate !== '' && giftDataStore.giftValue !== '';

      if (giftDataStore.receiverType === receiverType.employee.value) {
        isValid &= giftDataStore.giftReason !== '' && giftDataStore.giftDescription !== '' && giftDataStore.giftDescriptionOccasion !== '';
      }

      return isValid;
    }
  },
  {
    id: 5,
    title: APPROVAL_TITLE,
    css_stepId: 'step-approval',
    path: GIFTCASE_APPROVAL_PATH,
    getActions: rootStore => {
      if (isFibuVisible(rootStore) && hasGiftStatus(rootStore, status.approved)) {
        return [getNextAction(NEXT_FEM + FIBU_TITLE, GIFTCASE_FIBU_PATH), getBackAction(BACK_MASC + GIFT_TITLE, GIFTCASE_GIFT_PATH)];
      }
      if (!isFibuVisible(rootStore) && hasGiftStatus(rootStore, status.completed)) {
        return [getBackAction(BACK_MASC + GIFT_TITLE, GIFTCASE_GIFT_PATH), getNextAction(NEXT_MASC + REPORT_TITLE, GIFTCASE_REPORT_PATH),];
      }
      return [getBackAction(BACK_MASC + GIFT_TITLE, GIFTCASE_GIFT_PATH, true, 'navigation-bar-action-1')];
    },
    isVisible: () => true,
    isValid: giftDataStore => {
      return giftDataStore.hasStatus(status.approved);
    }
  },
  {
    id: 6,
    title: FIBU_TITLE,
    css_stepId: 'step-fibu',
    apiStep: 'update-financial-accounting-data',
    path: GIFTCASE_FIBU_PATH,
    getActions: rootStore => {
      if (isPayrollVisible(rootStore) && hasGiftStatus(rootStore, status.payrollProcessing)) {
        return [
          getBackAction(BACK_FEM + APPROVAL_TITLE, GIFTCASE_APPROVAL_PATH),
          getNextAction(NEXT_FEM + PAYROLL_TITLE, GIFTCASE_PAYROLL_PATH),
        ];
      }
      return [getBackAction(BACK_FEM + APPROVAL_TITLE, GIFTCASE_APPROVAL_PATH, true, 'navigation-bar-action-1')];
    },
    isVisible: isFibuVisible,
    isValid: giftDataStore => {
      const isStepValid =
        !isEmpty(giftDataStore.internalNumber) &&
        !isEmpty(giftDataStore.grossValue) &&
        !isEmpty(giftDataStore.netValue) &&
        !isEmpty(giftDataStore.guidance) &&
        giftDataStore.hasStatus(status.payrollProcessing);
      return isStepValid;
    }
  },
  {
    id: 7,
    title: PAYROLL_TITLE,
    css_stepId: 'step-payroll',
    apiStep: 'complete-gift-case',
    path: GIFTCASE_PAYROLL_PATH,
    getActions: rootStore => {
      if (hasGiftStatus(rootStore, status.completed)) {
        return [getBackAction(BACK_FEM + FIBU_TITLE, GIFTCASE_FIBU_PATH), getNextAction(NEXT_MASC + REPORT_TITLE, GIFTCASE_REPORT_PATH)];
      }
      return [getBackAction(BACK_FEM + FIBU_TITLE, GIFTCASE_FIBU_PATH, true, 'navigation-bar-action-1')];
    },
    isVisible: isPayrollVisible,
    isValid: giftDataStore => {
      return giftDataStore.hasStatus(status.completed);
    }
  },
  {
    id: 8,
    title: REPORT_TITLE,
    css_stepId: 'step-report',
    path: GIFTCASE_REPORT_PATH,
    // the order of the actions determine the order and css of the buttons in the navigation bar
    getActions: () => [
      getBackAction(BACK_FEM + PAYROLL_TITLE, GIFTCASE_PAYROLL_PATH),
      {
        name: 'Bericht exportieren',
        title: 'Bericht exportieren',
        specialAction: 'ExportReport'
      },
      getNextAction(NEXT_FEM + OVERVIEW_TITLE, OVERVIEW_PATH),
    ],
    isVisible: () => true,
    isValid: () => true
  }
];
