import { GIFT_TITLE_TEXT, INFO_GIFT_TEXT, INVOICE_INFORMATION } from '../Pages/CreateGiftPages/giftCaseLabels';

export const helpTextGift = {
  title: GIFT_TITLE_TEXT,
  giftInfo: {
    id: 'help-text-gift-info',
    title: INFO_GIFT_TEXT,
    description: (
      <div>
        <p>
          Sachgeschenke an Mitarbeiter und Geschäftspartner können unter Einhaltung gewisser Voraussetzungen steuervergünstigt oder sogar
          steuerfrei gewährt werden. Geldgeschenke sind immer in vollem Umfang steuerpflichtig.
        </p>
        <p>
          <b> Anlass: </b>Für die steuerliche Bewertung ist der Anlass des Geschenks relevant. Zuwendungen aus einem besonderen persönlichen
          Anlass (z. B. Geburtstag, Hochzeit, Geburt eines Kindes, nicht aber Weihnachten) sind bis zu einem höheren Wert steuerfrei.
        </p>
        <p>
          <b>Beschreibung Anlass: </b>Beschreiben Sie hier den Anlass des Geschenks.
        </p>

        <p>
          <b>Beschreibung Geschenk:</b> Beschreiben Sie hier die Art des Geschenks.
        </p>
        <p>
          <b>Datum der Schenkung: </b>Das Datum der Schenkung ist für die steuerlichen Aufzeichnungen und die Überprüfung der Wertgrenzen
          wichtig. Es bleibt zu prüfen, ob der Empfänger ggf. schon weitere Geschenke erhalten hat oder noch erhält.
        </p>
        <p>
          <b>Wert (brutto/netto) pro Geschenk:</b> Hier können Sie wählen, welchen Wert Sie eingeben. Für die Genehmigung kann es genügen,
          wenn Sie den ungefähren Wert eingeben. Im weiteren Workflow wird der exakte Wert (brutto und netto) abgefragt.
        </p>
      </div>
    )
  },
  invoiceInfo: {
    id: 'help-text-gift-invoice',
    title: INVOICE_INFORMATION,
    description: (
      <div>
        <p>
          Wenn Ihnen die Rechnung bereits vorliegt, können Sie hier die Rechnungsnummer eintragen und anschließend auch das Dokument
          hochladen. Dies erleichtert der Finanzbuchhaltung später die Verknüpfung von Geschenkefall und Eingangsrechnung.
        </p>
        <p>
          <b>Grundsätzlich gilt:</b> Für die Gewährung des Betriebsausgaben- und Vorsteuerabzugs muss zunächst eine ordnungsgemäße Rechnung
          nach § 14 UStG vorliegen. Des Weiteren sind Pflichtangaben unveränderbar mit der Rechnung zu verknüpfen. Diese sind: Name des
          Beschenkten, ggf. für die Zuordenbarkeit auch Anschrift, sowie der Anlass. Mit dem Geschenkemanager werden diese Anforderungen
          erfüllt.
        </p>
      </div>
    )
  }
};
