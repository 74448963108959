import React from 'react';
import './_style.scss';
import Table from '../../../Components/Table';
import { COMPANY_TEXT, EMAIL_TEXT, FIRST_NAME_TEXT, LAST_NAME_TEXT, PERSONAL_ID } from '../giftCaseLabels';
import { receiverType } from '../../../Utils/constants';

function ReceiversTable(props) {
  const columns = [];
  columns.push({
    Header: LAST_NAME_TEXT,
    accessor: 'lastName'
  });
  columns.push({
    Header: FIRST_NAME_TEXT,
    accessor: 'firstName'
  });

  if (props.receiversType === receiverType.bussinesPartner.value) {
    columns.push({
      Header: COMPANY_TEXT,
      accessor: 'company'
    });
  }

  if (props.receiverType === receiverType.employee.value) {
    columns.push({
      Header: PERSONAL_ID,
      accessor: 'personalId'
    });
  }

  columns.push({
    Header: <div>{EMAIL_TEXT}</div>,
    accessor: 'email',
    style: { width: '200px' }
  });

  return (
    <div>
      <Table columns={columns} data={props.listOfReceivers} className="list-receiver-table" />
    </div>
  );
}

export default ReceiversTable;
