import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import './_style.scss';

function LinksContainer(props) {
  return (
    <div className="header__links-container">
      <Link to="/" className="application-title">
        {props.applicationTitle}
      </Link>
      <div className="page-links-container">
        {props.headerLinks.map(item => (
          <NavLink exact key={item.title} className={`header__page-link ${item.className}`} activeClassName="selected-link" to={item.path}>
            {item.title}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default withRouter(LinksContainer);
