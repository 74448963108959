import React from 'react';
import { inject, observer } from 'mobx-react';
import './_style.scss';
import { generateGtmEventName, getGtmIdInfoForActionFromStep } from '../../../Utils/utils';

function WorkflowActions(props) {
  const step = props.rootStore.createGiftStore.currentWorkflowStep.id;
  let {gtmActiveArea, gtmAction} = getGtmIdInfoForActionFromStep(step);

  if (props.actions?.length && props.actions[0].page) {
    gtmActiveArea = undefined; // do not track
  }
  return (
    <div className={'workflow-actions'}>
      {props.actions.map(action => (
        <button key={action.key} className="action-container" onClick={action.callback}
          data-gtmid={gtmActiveArea && generateGtmEventName(gtmActiveArea, gtmAction, action.key === 1 ? 'Save' : 'Cancel')}>
          {action.icon && <img className="action-icon" src={action.icon} alt="" />}
          <span className="action-name">{action.name}</span>
        </button>
      ))}
    </div>
  );
}

export default inject('rootStore')(observer(WorkflowActions));
