import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import './_style.scss';
import { chevronRightIcon } from '../../Assets/dynamic-svg/general-icons';
import {  SETTINGS_HEADER_NAME } from '../../Utils/constants';
import { historyListener, showUnsavedChangesModal, showErrorInView } from '../../Utils/utils';
import SAVE_ICON from '../../Assets/svg/save.svg';
import CANCEL_ICON from '../../Assets/svg/cancel.svg';
import WorkflowActions from '../CreateGiftPages/WorkflowActions';
import CompanyPage from './CompanyPage';
import CompliancePage from './CompliancePage';
import { royalBlue } from '../../Utils/styleHelper';
import { OVERVIEW_PATH } from '../../Utils/paths';
import { helpTextSettings } from '../../HelpText/settingsHelpText';

function SettingsPage(props) {
  const { settingsStore, modalStore, helpTextStore } = props.rootStore;
  const [isComplianceDisplayed, setComplianceDisplayed] = useState(false);
  const UNTERNEHMEN_LABEL = 'UNTERNEHMEN';
  const COMPLIANCE_LABEL = 'COMPLIANCE';
  const unblock = useRef();
  const companyRef = useRef(null);
  const complianceRef = useRef(null);

  useEffect(() => {
    historyListener(props.history);
    helpTextStore.setHelpText(helpTextSettings);
    return () => {
      helpTextStore.resetHelpText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    unblock.current = props.history.block(location => {
      const checkBlockingCondition = settingsStore.isSettingsDataChanged();
      if (checkBlockingCondition) {
        showUnsavedChangesModal(modalStore, location.pathname, allowUserToChangeRoute);
        return false;
      }
      return true;
    });
    return () => {
      unblock.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isComplianceDisplayed) {
      helpTextStore.resetHelpText();
    } else {
      helpTextStore.setHelpText(helpTextSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplianceDisplayed]);

  const allowUserToChangeRoute = targetLocation => {
    unblock.current();
    props.history.replace(targetLocation);
  };

  const createActions = () => [
    {
      key: 1,
      name: 'Speichern',
      callback: () => saveAction(),
      icon: SAVE_ICON,
      page: SETTINGS_HEADER_NAME
    },
    {
      key: 2,
      name: 'Abbrechen',
      callback: () => cancelAction(),
      icon: CANCEL_ICON,
      page: SETTINGS_HEADER_NAME
    }
  ];

  const saveAction = () => {
    if (companyRef && companyRef.current && !companyRef.current.validate()) {
      setComplianceDisplayed(false);
      showErrorInView();
      return;
    }
    if (complianceRef && complianceRef.current && !complianceRef.current.validate) {
      setComplianceDisplayed(true);
      showErrorInView();
      return;
    }
    settingsStore.saveSettings();
  };

  const cancelAction = () => {
    if (settingsStore.isSettingsDataChanged()) {
      showUnsavedChangesModal(modalStore, OVERVIEW_PATH(), allowUserToChangeRoute);
    } else {
      allowUserToChangeRoute(OVERVIEW_PATH());
    }
  };

  const navButton = (unitName, isSelected) => {
    return (
      <div
        className={`units-item ${isSelected ? 'selected' : ''}`}
        onClick={e => {
          unitName === COMPLIANCE_LABEL ? setComplianceDisplayed(true) : setComplianceDisplayed(false);
        }}>
        <span title={unitName}>{unitName}</span>
        <span className="icon-general">{isSelected ? chevronRightIcon(royalBlue) : chevronRightIcon()}</span>
      </div>
    );
  };
  return (
    <div className="two-container-page ">
      <div className="container-left">
        <div className="sticky-left-container">
          <div className="title">{SETTINGS_HEADER_NAME} </div>
          {navButton(UNTERNEHMEN_LABEL, !isComplianceDisplayed)}
          {navButton(COMPLIANCE_LABEL, isComplianceDisplayed)}
          <div className="line-separator"></div>
          <WorkflowActions actions={createActions()} />
        </div>
      </div>
      <div className="container-right">
        <div className={isComplianceDisplayed ? 'displayNone' : 'page-height'}>
          <CompanyPage ref={companyRef} />
        </div>
        <div className={isComplianceDisplayed ? 'page-height  compliance' : 'displayNone'}>
          <CompliancePage ref={complianceRef} />
        </div>
      </div>
    </div>
  );
}
export default inject('rootStore')(observer(SettingsPage));
