import React from 'react';
import './_style.scss';
import { OVERVIEW_PATH } from '../../Utils/paths';
import { PAGE_NOT_FOUND_DESCRIPTION, PAGE_NOT_FOUND_TITLE, PAGE_NOT_FOUND_BUTTON_TEXT } from './labels';

function NotFoundPage(props) {
  const handleGoToOverview = () => {
    props.history.push(OVERVIEW_PATH());
  };

  return (
    <div className="notfound-container">
      <div className="left-container">
        <div className="content">
          <h2>{PAGE_NOT_FOUND_TITLE}</h2>
          <p>{PAGE_NOT_FOUND_DESCRIPTION}</p>
          <button className={'btn btn-active'} onClick={handleGoToOverview}>
            {PAGE_NOT_FOUND_BUTTON_TEXT}
          </button>{' '}
        </div>
      </div>
      <div className="right-container"></div>
    </div>
  );
}

export default NotFoundPage;
