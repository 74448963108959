import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './_style.scss';
import { blackPearl } from '../../../Utils/styleHelper';
import Table from '../../../Components/Table';
import TextCell from '../../../Components/Table/Cells/TextCell';
import ContextMenuCell from '../../../Components/Table/Cells/ContextMenuCell';
import { editIcon, trashIcon } from '../../../Assets/dynamic-svg/general-icons';
import FiltersBar from '../../../Components/FiltersBar';
import NotificationArea from '../../../Components/NotificationArea';
import EmptyTable from '../../../Components/EmptyTable';
import { EMPLOYEE_PATH } from '../../../Utils/paths';
import {
  ROLES,
  FOOTER_FIX_HEIGHT,
  HEADER_FIX_HEIGHT,
  ROLES_NAME_DE,
  STICKY_FIX_HEIGHT,
  USER_STATUS_INACTIVE
} from '../../../Utils/constants';
import { fillEmployerSettingsWarning } from '../../../Utils/constants';

const OVERVIEW_TABLE_OFFSET = 310;

function RightContent(props) {
  const { apiStore, filtersStore, modalStore } = props.rootStore;
  const [activeFilters, setActiveFilters] = useState(filtersStore.activeFilters);
  const [searchFilterValue, setSearchFilterValue] = useState(filtersStore.searchFilterValue.toLowerCase());
  const [overviewBodyStyle, setOverviewBodyStyle] = useState();
  const history = useHistory({});
  const [data, setData] = useState([]);
  const dataRef = useRef();
  dataRef.current = data;

  useEffect(() => {
    setTableSize();

    window.addEventListener('resize', setTableSize);
    return () => {
      window.removeEventListener('resize', setTableSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    setTableSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.employees, activeFilters, searchFilterValue]);

  useEffect(() => {
    setActiveFilters(filtersStore.activeFilters.map(filter => filter.value));
    setSearchFilterValue(filtersStore.searchFilterValue.toLowerCase());
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersStore.activeFilters, filtersStore.searchFilterValue]);

  const setTableSize = () => {
    const adjustment = 100;
    const headerElement = document.getElementsByClassName('header')[0];
    const headerHeight = headerElement ? headerElement.clientHeight : HEADER_FIX_HEIGHT;
    const footerElement = document.getElementsByClassName('footer')[0];
    const footerHeight = footerElement ? footerElement.clientHeight : FOOTER_FIX_HEIGHT;
    const stickyElement = document.getElementsByClassName('sticky-left-container')[0];
    const stickyHeigth = stickyElement ? stickyElement.clientHeight : STICKY_FIX_HEIGHT;

    const hasVScroll = window.document.body.scrollHeight > window.innerHeight;

    const difference = window.innerHeight - stickyHeigth - headerHeight - footerHeight - adjustment;
    if (hasVScroll && difference < 0) {
      const scrollOffset = Math.max(0, OVERVIEW_TABLE_OFFSET - window.document.body.scrollHeight + window.innerHeight);
      setOverviewBodyStyle({ height: `calc(100vh - ${scrollOffset}px)` });
    } else {
      setOverviewBodyStyle({ height: `calc(100vh - ${OVERVIEW_TABLE_OFFSET}px)` });
    }
  };

  const loadData = () => {
    setData(getFilteredEmployees());
  };

  function getFilteredEmployees() {
    let filteredData =
      activeFilters.length > 0
        ? apiStore.employees.filter(employee => employee.roles && employee.roles.includes(activeFilters[0]))
        : apiStore.employees;

    if (searchFilterValue) {
      filteredData = filteredData.filter(employee =>
        Object.values(employee)
          .map(value => value.toString().toLowerCase())
          .some(value => value.includes(searchFilterValue))
      );
    }

    return filteredData;
  }

  const menuActions = userData => {
    const items = [
      {
        label: 'Bearbeiten',
        icon: editIcon(blackPearl),
        click: index => {
          handleEditEmployee(index);
        }
      }
    ];

    if (!userData.roles.includes(ROLES.admin) && userData.status !== USER_STATUS_INACTIVE) {
      items.push({
        label: 'Deaktivieren',
        icon: trashIcon(blackPearl),
        click: () => {
          handleDeactivateUser(userData.id);
        }
      });
    }

    return items;
  };

  const handleEditEmployee = index => history.push(`${EMPLOYEE_PATH}/${dataRef.current[index].id}`);

  const handleDeactivateUser = id => {
    //this.rootStore.modalStore.showConfirm('API-Error 500', this.lastApiErrorMessage);
    modalStore.showChoice(
      'Mitarbeiter deaktivieren',
      'Sind Sie sicher?',
      'Abbrechen',
      () => {},
      'Deaktivieren',
      () => {
        apiStore.deactivateEmployee(id);
      }
    );
  };

  const Cell = props => <span className={props.row.original.status}>{props.value}</span>;

  const columns = useMemo(
    () => [
      {
        Header: 'Personalnr.',
        accessor: 'personalNumber',
        Cell
      },
      {
        Header: 'Name',
        accessor: 'lastName',
        Cell
      },
      {
        Header: 'Vorname',
        accessor: 'firstName',
        Cell
      },
      {
        Header: 'E-Mail-Adresse',
        accessor: 'email',
        Cell
      },
      {
        Header: 'Rolle',
        accessor: 'rolesInString',
        Cell: props => <TextCell column={{ ...props.column, className: props.row.original.status }} cell={{ value: props.value }} />
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell
      },
      {
        Header: '',
        accessor: 'menu',
        Cell: props => <ContextMenuCell row={props.row} column={{ listItems: menuActions(props.row.original) }} />
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getRolesInString = (roles = []) => roles.map(role => ROLES_NAME_DE[role]).join(', ');
  const renderNotification = () => {
    return apiStore.settings === '' && <NotificationArea message={fillEmployerSettingsWarning} />;
  };

  const renderHeader = () => {
    return (
      apiStore.settings && (
        <div className="overview-page-header">
          <span className="title">Mitarbeiter</span>
          <FiltersBar />
        </div>
      )
    );
  };

  const addRolesInString = tableData => {
    if (tableData) {
      tableData.forEach((row, i) => {
        row = row.roles.length > 0 ? getRolesInString(row.roles) : '';
        tableData[i].rolesInString = row;
      });
    }
    return tableData;
  };

  const renderData = () => {
    return (
      <section>
        {apiStore.settings && (
          <Table
            columns={columns}
            data={addRolesInString(data)}
            className="employees-overview"
            bodyStyle={overviewBodyStyle}
            getRowProps={row => ({
              onClick: () => handleEditEmployee(row.index),
              style: {
                cursor: 'pointer'
              }
            })}
          />
        )}
        {apiStore.settings === '' && <EmptyTable message={'Noch kein Mitarbeiter eingetreten.'} />}
      </section>
    );
  };
  return (
    <div className="container-right overview-content">
      {renderNotification()}
      {renderHeader()}
      {renderData()}
    </div>
  );
}

export default inject('rootStore')(observer(RightContent));
