import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { inject, observer } from 'mobx-react';

import { validateEmail, isEmpty, focusElement, getUniqueArrayByPropertyFromObjectArray } from '../../../sma-shared/Utils/utils';
import { plusIcon, trashIcon, cloudDownload, cloudUpload } from '../../../Assets/dynamic-svg/general-icons';
import ErrorSection from '../../../Components/ErrorSection';
import Table from '../../../Components/Table';
import EditableCell from '../../../Components/Table/Cells/EditableCell';
import DeleteCell from '../../../Components/Table/Cells/DeleteCell';
import { royalBlue } from '../../../Utils/styleHelper';
import './_style.scss';
import {
  COMPANY_TEXT,
  EMAIL_TEXT,
  FIRST_NAME_TEXT,
  LAST_NAME_TEXT,
  PERSONAL_ID,
  ERROR_ENTER_LASTNAME,
  ERROR_ENTER_FIRSTNAME,
  ERROR_ENTER_COMPANY,
  ERROR_MESSAGE_EMAIL
} from '../giftCaseLabels';
import { MULTIPLE_RECEIVER_ERROR_MESSAGE, receiverType, actions, activeArea } from '../../../Utils/constants';
import { scrollIntoViewById, generateGtmEventName } from '../../../Utils/utils';
import config from '../../../config';
import { importReceivers } from './importReceivers';

function MultipleReceivers(props, ref) {
  const { giftDataStore, createGiftStore, modalStore } = props.rootStore;

  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [importListTemplate, setImportListTemplate] = useState();

  const inputFile = useRef(null);
  const dataRef = useRef();
  dataRef.current = data;

  useEffect(() => {
    setData(giftDataStore.receivers);
  }, [giftDataStore.receivers]);

  useEffect(() => {
    createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const importTemplateFile =
      giftDataStore.receiverType === receiverType.employee.value ? config.template.employeeList : config.template.bussinesPartnerList;
    setImportListTemplate(`/${importTemplateFile}`);
  }, [giftDataStore.receiverType]);

  useImperativeHandle(ref, () => ({
    validate: validateReceivers
  }));

  function validateReceivers(ignoreEmpty = false) {
    const errors = !data && data.length === 0 ? [{ errorMsg: MULTIPLE_RECEIVER_ERROR_MESSAGE }] : [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < columns.length; j++) {
        const cellValue = data[i][columns[j].accessor];
        if (ignoreEmpty && isEmpty(cellValue)) {
          continue;
        }
        const validator = columns[j].validator;
        if (validator && !validator(cellValue)) {
          errors.push({ row: i, column: columns[j].accessor, errorMsg: columns[j].errorMsg });
        }
      }
    }

    const isValid = errors.length === 0 && data.length > 0;
    setErrors(errors);
    createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    return isValid;
  }

  function loadData(data) {
    setData(
      data.map(item => {
        return item;
      })
    );
    giftDataStore.receivers = [...data];
  }

  const update = (rowIndex, columnID, value) => {
    if (data && data.length > rowIndex) {
      const receiver = data[rowIndex];
      receiver[columnID] = value;
      loadData(data);
    }
    validateReceivers(true);
  };

  const handleDeleteReceiver = index => {
    if (giftDataStore.canEdit) {
      modalStore.showChoice(
        'Empfänger löschen',
        'Sind Sie sicher?',
        'Abbrechen',
        () => {},
        'Löschen',
        () => {
          const employes = [...dataRef.current];
          employes.splice(index, 1);
          loadData(employes);
        }
      );
    }
  };

  function addReceivers() {
    if (giftDataStore.canEdit) {
      const employes = [...dataRef.current];
      if (giftDataStore.receiverType === receiverType.employee.value) {
        employes.push({ lastName: '', firstName: '', personalId: '', email: '' });
      }
      if (giftDataStore.receiverType === receiverType.bussinesPartner.value) {
        employes.push({ lastName: '', firstName: '', company: '', email: '' });
      }
      loadData(employes);
      focusElement('tr:last-child input');
      scrollIntoViewById('add-receivers');
      createGiftStore.rightContainerSizeChanged = !createGiftStore.rightContainerSizeChanged;
    }
  }

  function deleteReceivers() {
    if (giftDataStore.canEdit) {
      modalStore.showChoice(
        'Alle Empfänger löschen',
        'Sind Sie sicher?',
        'Abbrechen',
        () => {},
        'Löschen',
        () => {
          const employes = [];
          if (giftDataStore.receiverType === receiverType.employee.value) {
            employes.push({ lastName: '', firstName: '', personalId: '', email: '' });
          }
          if (giftDataStore.receiverType === receiverType.bussinesPartner.value) {
            employes.push({ lastName: '', firstName: '', company: '', email: '' });
          }
          loadData(employes);
          setErrors([]);
          focusElement('tr:last-child input');
          scrollIntoViewById('add-receivers');
        }
      );
    }
  }

  async function importReceiversList(file) {
    try {
      if (giftDataStore.canEdit) {
        const receivers = await importReceivers(file, giftDataStore.receiverType);

        const modalButtons = (
          <Fragment>
            <button
              className="modal-btn secondary-button"
              onClick={() => {
                modalStore.hideModal();
              }}>
              Abbrechen
            </button>
            <button
              className={`modal-btn secondary-button`}
              onClick={() => {
                modalStore.hideModal();
                loadData(receivers);
                setErrors([]);
              }}>
              Überschreiben
            </button>
            <button
              className="modal-btn primary-button"
              onClick={() => {
                modalStore.hideModal();
                const newlist = data.concat(receivers);
                loadData(newlist);
                setErrors([]);
              }}>
              Ergänzen
            </button>
          </Fragment>
        );

        modalStore.showModal(
          'Eine neue Empfängerliste wurde importiert.',
          'Möchten Sie die Empfänger der bestehenden Liste ergänzen oder überschreiben?',
          modalButtons
        );
      }
    } catch (error) {
      modalStore.showConfirm('Fehler', 'Die importierte Datei entspricht nicht der Excel-Vorlage. Bitte passen Sie die Datei an.');
    }

    resetFileUpload();
  }

  const columns = React.useMemo(
    () => {
      const columns = [];
      columns.push({
        Header: LAST_NAME_TEXT,
        accessor: 'lastName',
        placeholder: LAST_NAME_TEXT,
        disabled: !giftDataStore.canEdit,
        errorMsg: ERROR_ENTER_LASTNAME,
        validator: value => !isEmpty(value),
        Cell: EditableCell
      });
      columns.push({
        Header: FIRST_NAME_TEXT,
        accessor: 'firstName',
        placeholder: FIRST_NAME_TEXT,
        disabled: !giftDataStore.canEdit,
        errorMsg: ERROR_ENTER_FIRSTNAME,
        validator: value => !isEmpty(value),
        Cell: EditableCell
      });
      if (giftDataStore.receiverType === receiverType.bussinesPartner.value) {
        columns.push({
          Header: COMPANY_TEXT,
          accessor: 'company',
          placeholder: COMPANY_TEXT,
          disabled: !giftDataStore.canEdit,
          errorMsg: ERROR_ENTER_COMPANY,
          validator: value => !isEmpty(value),
          Cell: EditableCell
        });
      }

      if (giftDataStore.receiverType === receiverType.employee.value) {
        columns.push({
          Header: (
            <div>
              {PERSONAL_ID} <span className="optional-info">optional</span>
            </div>
          ),
          accessor: 'personalId',
          isMandatory: false,
          placeholder: PERSONAL_ID,
          disabled: !giftDataStore.canEditPersonalNumber,
          Cell: EditableCell
        });
      }

      columns.push({
        Header: (
          <div>
            {EMAIL_TEXT} <span className="optional-info">optional</span>
          </div>
        ),
        accessor: 'email',
        placeholder: EMAIL_TEXT,
        disabled: !giftDataStore.canEdit,
        errorMsg: ERROR_MESSAGE_EMAIL,
        validator: value => (value ? validateEmail(value) : true),
        Cell: EditableCell
      });
      columns.push({
        Header: '',
        accessor: 'delete',
        disabled: !giftDataStore.canEdit,
        deleteAction: handleDeleteReceiver,
        Cell: DeleteCell
      });

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function resetFileUpload() {
    const fileUploadElement = document.querySelector('#file');
    if (fileUploadElement) {
      fileUploadElement.value = '';
    }
  }

  function renderImportReceivers() {
    return (
      <div className="import-container" id="import-receivers">
        <button className="text-button" disabled={!giftDataStore.canEdit}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={importListTemplate}
            data-gtmid={generateGtmEventName(activeArea.recipient, actions.select, 'DownloadXLS')}>
            <span className="icon-general">{cloudDownload(royalBlue)}</span>
            <span>Download Excel-Vorlage</span>
          </a>
        </button>
        <button
          className="text-button"
          disabled={!giftDataStore.canEdit}
          onClick={e => {
            inputFile.current.click();
          }}>
          {/* eslint-disable-next-line */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            data-gtmid={generateGtmEventName(activeArea.recipient, actions.select, 'ImportXLS')}>
            <span className="icon-general">{cloudUpload(royalBlue)}</span>
            <span>Import Empfängerliste (aus Vorlage)</span>
          </a>
        </button>
        <input
          type="file"
          id="file"
          accept={['.xlsx', '.xls', '.csv']}
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={e => importReceiversList(e.target.files[0])}
        />
      </div>
    );
  }

  function renderAddButton() {
    return (
      <div className="add-row-container" id="add-receivers">
        <button className="text-button" disabled={!giftDataStore.canEdit} onClick={e => addReceivers()}
          data-gtmid={generateGtmEventName(activeArea.recipient, actions.select, 'AddRecipient')}>
          <span className="icon-general">{plusIcon(royalBlue)}</span>
          Empfänger hinzufügen
        </button>
        <button className="text-button" disabled={!giftDataStore.canEdit} onClick={e => deleteReceivers()}
          data-gtmid={generateGtmEventName(activeArea.recipient, actions.select, 'DeleteRecipients')}>
          <span className="icon-general">{trashIcon(royalBlue)}</span>
          Löschen aller Empfänger
        </button>
      </div>
    );
  }

  function renderErrors() {
    const distinctErrors = getUniqueArrayByPropertyFromObjectArray(errors, 'errorMsg', true);
    return <ErrorSection errors={distinctErrors} />;
  }

  return (
    <React.Fragment>
      {renderImportReceivers()}
      <Table columns={columns} data={data} errors={errors} update={update} className="multiple-receiver" />
      {renderAddButton()}
      {renderErrors()}
    </React.Fragment>
  );
}

export default inject('rootStore')(observer(forwardRef(MultipleReceivers)));
