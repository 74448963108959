import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import './_style.scss';
import { plusIcon, infoIconSans } from '../../../Assets/dynamic-svg/general-icons';
import { blackPearl, white } from '../../../Utils/styleHelper';
import { actions, activeArea, giftStatus, ROLES } from '../../../Utils/constants';
import Filters from '../../../Components/Filters';
import ProductVideo from '../../../Components/ProductVideo';
import VideoPlayer from '../../../Components/VideoPlayer';
import { ALL_FILTER_VALUE } from '../../../Stores/FiltersStore';
import { GIFTCASE_ENTRYCHECK_PATH } from '../../../Utils/paths';
import { getLatestGiftStatus } from '../helper';
import { copyToClipboard, addClass, removeClass, scrollIntoViewById, getRoleBasedProductVideos } from '../../../Utils/utils';
import { helpTextOverviewPage } from '../../../HelpText/overviewPageHelpText';
import { LINK_NAME, LINK_TOOLTIP, LINK_TOOLTIP_CLICKED } from '../labels';
import { sstTrackingHelper, generateGtmEventName } from '../../../Utils/utils';

function LeftContent(props) {
  const { apiStore, filtersStore, helpTextStore, modalStore } = props.rootStore;
  const [filterOptions, setFilterOptions] = useState([]);
  const [showGlobalLink, setShowGlobalLink] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);
  const baseUrl = window.location.origin;
  const isExternalUser = apiStore.userInfo.isExternalUser;

  useEffect(() => {
    if (showGlobalLink) {
      helpTextStore.setHelpText(helpTextOverviewPage);

      return () => {
        helpTextStore.resetHelpText();
      };
    }
  }, [showGlobalLink, helpTextStore]);

  useEffect(() => {
    const filters = [];
    for (const status of Object.values(giftStatus)) {
      filterOptions.push({ label: status.label, value: status.value });
    }
    setFilterOptions(filters);
    filtersStore.setFilterOptions(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.gifts]);

  useEffect(() => {
    setShowGlobalLink(apiStore.roles.includes(ROLES.admin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.roles]);

  const renderAddButton = () => {
    return (
      apiStore.settings !== undefined && (
        <div className="add-case-button">
          <button
            className={apiStore.settings ? 'btn-active' : 'btn-disabled'}
            onClick={() => {
              if (apiStore.settings) {
                handleCreateNewCaseClick(props);
              }
            }}
            data-gtmid={generateGtmEventName(activeArea.caseOverview, actions.open, 'NewCase')}>
            {plusIcon(white)} Neuer Fall
          </button>
        </div>
      )
    );
  };

  const handleCreateNewCaseClick = props => {
    const { giftDataStore } = props.rootStore;
    giftDataStore.reset();
    props.history.push(GIFTCASE_ENTRYCHECK_PATH());
    sstTrackingHelper('Fallübersicht', 'Creat new case', '/', isExternalUser);
  };

  const showHelpText = helpTextId => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  const renderGlobalLink = () => {
    return (
      apiStore.settings !== undefined && (
        <div className="gift-link">
          <div className="gift-link-header">
            <h2>{LINK_NAME}</h2>
            <span className="help-icon" onClick={() => showHelpText('help-text-invitation-link')}
              data-gtmid={generateGtmEventName(activeArea.startpage, actions.open, 'HelpInvitationLink')}>
              {' '}
              {infoIconSans(blackPearl)}
            </span>
          </div>
          <div className={linkClicked ? 'link-clicked display-tooltip invitation-tooltip' : 'invitation-tooltip'}>
            {linkClicked ? LINK_TOOLTIP_CLICKED : LINK_TOOLTIP}
          </div>
          <p
            className="inivitation-link"
            onMouseEnter={e => {
              e.preventDefault();
              addClass('.invitation-tooltip', 'display-tooltip');
            }}
            onMouseLeave={e => {
              e.preventDefault();
              removeClass('.invitation-tooltip', 'display-tooltip');
            }}
            onClick={e => {
              e.preventDefault();
              copyToClipboard(baseUrl);
              setLinkClicked(true);
              setTimeout(() => {
                setLinkClicked(false);
              }, 5000);
              sstTrackingHelper('Fallübersicht', 'Copy invite link', '/', isExternalUser);
            }}
            data-gtmid={generateGtmEventName(activeArea.startpage, actions.open, 'InvitationLink')}
          >
            {baseUrl}
          </p>
        </div>
      )
    );
  };

  const getNumberOfEntriesForFilter = filterValue => {
    const { apiStore } = props.rootStore;
    const entries = apiStore.gifts;

    const nrFilteredEntries = entries.filter(entry => getLatestGiftStatus(entry) === filterValue).length;
    return filterValue === ALL_FILTER_VALUE ? entries.length : nrFilteredEntries;
  };

  const renderFilters = () => {
    return apiStore.settings ? (
      <Filters
        searchTitle="Suche"
        searchPlaceholder="z. B. Rechnungsnummer"
        filterTitle="Status"
        getNumberOfEntriesForFilter={getNumberOfEntriesForFilter}
      />
    ) : (
      ''
    );
  };

  const renderProductVideo = () => {
    return (
      <ProductVideo
        videos={getRoleBasedProductVideos(apiStore.userInfo.roles)}
        onClick={({ title, source }) => {
          return modalStore.showConfirm(title, <VideoPlayer source={source} />, 'Schließen');
        }}
      />
    );
  };

  return (
    <div className="container-left">
      <div className="sticky-left-container">
        {renderAddButton(props)}
        {showGlobalLink && renderGlobalLink()}
        {renderFilters()}
        {renderProductVideo()}
      </div>
    </div>
  );
}

export default inject('rootStore')(observer(LeftContent));
