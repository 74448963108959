import React, { useState, useEffect } from 'react';
import './_style.scss';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { WORKFLOW } from '../WorkflowNavigation/workflow';
import { getObjectByPropValueFromArray } from '../../../sma-shared/Utils/utils';
import { sstTrackingHelper, generateGtmEventName, getGtmInfoFromButtonName } from '../../../Utils/utils';
import { downloadReport } from '../../../Utils/pdf';
import { FOOTER_FIX_HEIGHT } from '../../../Utils/constants';

function NavigationBar(props) {
  const { createGiftStore, giftDataStore } = props.rootStore;
  let footerHeight = FOOTER_FIX_HEIGHT;
  const [currentActions, setCurrentActions] = useState([]);
  const [navigationBarStyle, setNavigationBarStyle] = useState({ bottom: '80px', position: 'absolute' });

  const isExternalUser = props.rootStore.apiStore.userInfo.isExternalUser;

  useEffect(() => {
    calculateFooterHeight();
    setNavigationBarPosition();

    window.addEventListener('scroll', setNavigationBarPosition);
    window.addEventListener('resize', setNavigationBarPosition);

    return () => {
      window.removeEventListener('scroll', setNavigationBarPosition);
      window.removeEventListener('resize', setNavigationBarPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createGiftStore.currentWorkflowStep && typeof createGiftStore.currentWorkflowStep.getActions === 'function') {
      setCurrentActions(createGiftStore.currentWorkflowStep.getActions(props.rootStore));
    } else {
      setCurrentActions([]);
    }

    setNavigationBarPosition();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGiftStore.currentWorkflowStep, giftDataStore.status]);

  useEffect(() => {
    setTimeout(() => {
      setNavigationBarPosition();
    }, 10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGiftStore.rightContainerSizeChanged]);

  const calculateFooterHeight = () => {
    const footerElements = document.getElementsByClassName('footer');
    const footer = footerElements.length > 0 ? footerElements[0] : undefined;
    footerHeight = footer ? footer.clientHeight : FOOTER_FIX_HEIGHT;
  };

  const setNavigationBarPosition = () => {
    const hasVScroll = window.document.body.scrollHeight > window.innerHeight;

    let navigationBarStyle = {};
    if (hasVScroll) {
      const calculateBottomOffset = window.document.body.scrollHeight - window.innerHeight - footerHeight;
      if (window.pageYOffset > calculateBottomOffset) {
        navigationBarStyle = {
          bottom: `${-calculateBottomOffset}px`,
          position: 'absolute',
          minWidth: '1440px'
        };
      } else {
        navigationBarStyle = {
          bottom: '0px',
          position: 'fixed'
        };
      }
    } else {
      navigationBarStyle = {
        bottom: `${footerHeight}px`,
        position: 'absolute',
        minWidth: '1440px'
      };
    }

    setNavigationBarStyle(navigationBarStyle);
  };

  /**
   * Function to handle button clicks on the navigation bar.
   * It handles actions such as going to the next step, going to the previous step, exporting the report or
   * showing the feedback dialogue.
   * @param {{
   *  name: string,
   *  title: string,
   *  specialAction: string,
   *  navigateTo: string,
   *  left: boolean,
   *  className: string
   * }} action - The action object
   * @returns
   * @memberof NavigationBar
   */
  const handleActionClick = action => {
    const { createGiftStore, giftDataStore, apiStore } = props.rootStore;

    if (action.specialAction === 'ExportReport') {
      const gift = apiStore.gifts.find(gift => gift._id === giftDataStore.idFromDB);
      downloadReport(gift);
      sstTrackingHelper('Falldaten', 'Download case overview in PDF Format', '/case-download-pdf', isExternalUser);
      return;
    }

    const navigateTo = getObjectByPropValueFromArray(WORKFLOW, 'path', action.navigateTo);
    const isUserGoingForward = navigateTo.id > createGiftStore.currentWorkflowStep.id;
    if (isUserGoingForward) {
      const stepIsValid = createGiftStore.currentWorkflowStep.validate();
      if (stepIsValid) {
        if (action.navigateTo) {
          if (giftDataStore.isGiftDataChanged()) {
            giftDataStore.saveGift(id => {
              props.history.push(action.navigateTo(id));
            });
          } else {
            props.history.push(action.navigateTo(giftDataStore.idFromDB));
          }
        }
      }
    } else {
      if (giftDataStore.isGiftDataChanged()) {
        giftDataStore.saveGift(id => {
          props.history.push(action.navigateTo(id));
        });
      } else {
        props.history.push(action.navigateTo(giftDataStore.idFromDB));
      }
    }
  };

  /**
   * Function to retrieve the correct css class depending on the index of the action.
   * If a feedback button is inside the navigation bar, it will gain another disabled class.
   * @param {{name:string,title:string,specialAction:string,navigateTo:string,className:string}} action - The action object
   * @param {number} index - The index of the action
   * @returns {string} The css class
   * @memberof NavigationBar
   */
  const getClass = (action, isLeft) => {
    let cls = action.className ? action.className : `navigation-bar-action`

    if (!!isLeft) {
      cls = `${cls} left`;
    }

    return cls
  }

  /**
   * Function to render the buttons of the bottom navigation bar.
   * @param {boolean} isLeft - Whether the action is on the left or right side of the navigation bar
   * @returns {JSX.Element} The JSX element
   * @memberof NavigationBar
   */
  const renderButtons = (isLeft=false) => {
    const filteredButtons = currentActions.filter(action => !!action.left === isLeft);
    let gtmInfo;
    return (
      <div className="navigation-bar-items">
        {filteredButtons.map((action, index) => action.name && (
          gtmInfo = getGtmInfoFromButtonName(action.name, giftDataStore.giftDate),
          <button
          key={action.name + '-' + index}
          className={getClass(action, isLeft)}
          onClick={() => handleActionClick(action)}
          data-gtmid={(gtmInfo) &&
            generateGtmEventName(gtmInfo.activeArea, gtmInfo.action, gtmInfo.description)}>
            <span>{action.name}</span>
          </button>
        ))}
      </div>
    )
  }

  return (

    <React.Fragment>

      <div className="navigation-bar" style={navigationBarStyle}>
      {renderButtons(true)}
      {renderButtons()}
      </div>

    </React.Fragment>
  );
  //  }
}

NavigationBar.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject('rootStore')(observer(NavigationBar));
