import { observable, decorate, action } from 'mobx';
import giftSchema from '../Resources/gift_empty.json';
import moment from 'moment';
import { isEmpty } from '../sma-shared/Utils/utils';
import { status as STATUS, ROLES, UPDATE_STATUS } from '../Utils/constants';
import { overwriteChangesModal } from '../Utils/overwriteDialog';
import { changeHistoryLocation, getFullName } from '../Utils/utils';
import { OVERVIEW_PATH } from '../Utils/paths';
import { getArray } from '../Utils/gift';
export default class GiftDataStore {
  apiStore;
  userInfo;
  companyStore;
  modalStore;

  idFromDB = '';
  refreshedAt = '';
  dataFromDB = '';
  canEdit = true;
  canSave = true;
  canEditPersonalNumber = true;

  //Entry Check
  receiverType = '';
  compliantGiftPolicy = false;
  isComplianceGiftPolicyVisible = false;
  recommendation = '';

  //Sender
  senderFirstName = '';
  senderLastName = '';
  senderCompany = '';
  senderEmail = '';

  //Receiver
  numberOfReceivers;
  receivers = [];

  //Status
  status = [];
  approvalRequestTo = '';
  // gift page
  giftReason = '';
  giftDescriptionOccasion = '';
  giftDescription = '';
  giftDate = '';
  giftValueType = '';
  giftValue = '';

  numberOfApproverChange = 0;
  // invoice
  invoiceNumber = '';
  invoiceAttachments = [];
  internalNumber = '';
  grossValue = '';
  netValue = '';

  guidance = '';

  lastComment;

  constructor(rootStore) {
    this.apiStore = rootStore.apiStore;
    this.modalStore = rootStore.modalStore;
    this.userInfo = this.apiStore.userInfo;
  }

  reset() {
    this.idFromDB = '';
    this.dataFromDB = '';
    this.refreshedAt = '';
    this.canEdit = true;
    this.canSave = true;
    this.canEditPersonalNumber = true;

    this.receiverType = '';
    this.compliantGiftPolicy = false;
    this.isComplianceGiftPolicyVisible = false;
    this.recommendation = '';

    this.senderFirstName = '';
    this.senderLastName = '';
    this.senderCompany = '';
    this.senderEmail = '';

    this.numberOfReceivers = '';
    this.receivers = [];

    this.status = [
      {
        status: STATUS.draft,
        name: getFullName(this.apiStore.userInfo),
        email: this.apiStore.userInfo.Email,
        date: new Date(),
        comment: ''
      }
    ];
    this.approvalRequestTo = '';
    this.giftReason = '';
    this.giftDescription = '';
    this.giftDescriptionOccasion = '';
    this.giftDate = '';
    this.giftValueType = '';
    this.giftValue = '';

    this.numberOfApproverChange = 0;
    this.invoiceNumber = '';
    this.internalNumber = '';
    this.invoiceAttachments = [];
    this.grossValue = '';
    this.netValue = '';
    this.guidance = '';
    this.lastComment = undefined;
  }

  editGift(giftObject) {
    this.reset();
    if (!giftObject) {
      return;
    }
    this.idFromDB = giftObject._id;
    this.refreshedAt = giftObject.status ? giftObject.status.refreshedAt : '';
    if (giftObject.entryCheck) {
      this.receiverType = giftObject.entryCheck.receiverType;
      this.compliantGiftPolicy = giftObject.entryCheck.compliantGiftPolicy;
      this.recommendation = giftObject.entryCheck.recommendation;
    }

    if (giftObject.sender) {
      this.senderFirstName = giftObject.sender.firstName;
      this.senderLastName = giftObject.sender.lastName;
      this.senderCompany = giftObject.sender.company;
      this.senderEmail = giftObject.sender.email;
    }

    if (giftObject.receiver) {
      this.numberOfReceivers = giftObject.receiver.numberOfReceivers;
      this.receivers = giftObject.receiver.receivers;
    }

    this.status =
      giftObject.status && giftObject.status.status
        ? getArray(giftObject.status.status)
        : [
            {
              status: STATUS.draft,
              name: getFullName(this.apiStore.userInfo),
              email: this.apiStore.userInfo.Email,
              date: new Date(),
              comment: ''
            }
          ];

    this.approvalRequestTo = giftObject.approvalRequestTo;
    this.numberOfApproverChange = !isEmpty(giftObject.numberOfApproverChange) ? parseInt(giftObject.numberOfApproverChange) : 0;
    if (giftObject.gift) {
      this.giftReason = giftObject.gift.giftReason;
      this.giftDescription = giftObject.gift.giftDescription;
      this.giftDescriptionOccasion = giftObject.gift.giftDescriptionOccasion;
      this.giftDate = giftObject.gift.giftDate ? moment(giftObject.gift.giftDate).toDate() : '';
      this.giftValueType = giftObject.gift.giftValueType;
      this.giftValue = giftObject.gift.giftValue;
    }

    if (giftObject.invoice) {
      this.invoiceNumber = giftObject.invoice.invoiceNumber;
      this.internalNumber = giftObject.invoice.internalNumber || '';
      this.grossValue = giftObject.invoice.grossValue;
      this.netValue = giftObject.invoice.netValue;
      this.invoiceAttachments = giftObject.invoice.invoiceAttachments ? giftObject.invoice.invoiceAttachments : [];
    }

    this.guidance = giftObject.guidance;
    this.canEdit =
      !(this.hasStatus(STATUS.payrollProcessing) || this.hasStatus(STATUS.completed)) &&
      (this.apiStore.roles.includes(ROLES.admin) ||
        this.apiStore.roles.includes(ROLES.accounting) ||
        this.apiStore.roles.includes(ROLES.payroll) ||
        this.status.find(
          item =>
            item.status === STATUS.approved ||
            item.status === STATUS.rejected ||
            (item.status === STATUS.approvalRequest && this.approvalRequestTo === this.userInfo.Email)
        ) === undefined);

    this.canEditPersonalNumber =
      !this.hasStatus(STATUS.completed) &&
      (this.apiStore.roles.includes(ROLES.admin) ||
        this.apiStore.roles.includes(ROLES.payroll) ||
        (this.apiStore.roles.includes(ROLES.accounting) && !this.hasStatus(STATUS.payrollProcessing)) ||
        this.status.find(
          item =>
            item.status === STATUS.approved ||
            item.status === STATUS.rejected ||
            (item.status === STATUS.approvalRequest && this.approvalRequestTo === this.userInfo.Email)
        ) === undefined);

    this.canSave = this.canEdit || this.canEditPersonalNumber;

    this.updateDataFromDB();
  }

  async saveGift(callback, { approvalStep, action } = {}) {
    if (this.canSave) {
      const schema = this.writeGiftToSchemma();
      const updatedData = await this.apiStore.addOrUpdateGift(schema, this.idFromDB, false, { approvalStep, action });
      if (updatedData) {
        if (updatedData.message === UPDATE_STATUS.success) {
          this.giftSaved(updatedData.gift, callback);
        } else if (updatedData.message === UPDATE_STATUS.removed) {
          // discard any possible changes, so the history will not be blocked when user is redirected to fallubersicht
          this.editGift(schema);
          this.modalStore.showConfirm(
            'Achtung',
            'Der Fall wurde durch einen anderen Benutzer gelöscht. Sie werden zur Fallübersicht zurückgeleitet.',
            '',
            () => {
              changeHistoryLocation(OVERVIEW_PATH());
            }
          );
        } else {
          overwriteChangesModal(
            this.modalStore,
            updatedData.message === UPDATE_STATUS.changedByOther,
            () => {
              this.editGift(updatedData.gift);
            },
            async () => {
              const overwritedData = await this.apiStore.addOrUpdateGift(schema, this.idFromDB, true);
              if (overwritedData.message === UPDATE_STATUS.success) {
                this.giftSaved(overwritedData.gift, callback);
              }
            }
          );
        }
      }
    } else {
      if (callback) {
        callback(this.idFromDB);
      }
    }
  }

  giftSaved(giftSaved, callback) {
    this.idFromDB = giftSaved._id;
    this.editGift(giftSaved);
    if (callback) {
      callback(giftSaved._id);
    }
  }

  deleteGift() {
    this.apiStore.deleteGift(this.idFromDB, () => {
      this.reset();
    });
  }

  writeGiftToSchemma() {
    giftSchema.entryCheck.receiverType = this.receiverType;
    giftSchema.entryCheck.compliantGiftPolicy = this.compliantGiftPolicy;

    giftSchema.sender.firstName = this.idFromDB ? this.senderFirstName : this.apiStore.userInfo ? this.apiStore.userInfo.FirstName : '';
    giftSchema.sender.lastName = this.idFromDB ? this.senderLastName : this.apiStore.userInfo ? this.apiStore.userInfo.LastName : '';
    giftSchema.sender.email = this.idFromDB ? this.senderEmail : this.apiStore.userInfo ? this.apiStore.userInfo.Email : '';
    giftSchema.sender.company = this.idFromDB ? this.senderCompany : this.apiStore.settings ? this.apiStore.settings.company.name : '';

    giftSchema.receiver.numberOfReceivers = this.numberOfReceivers;
    giftSchema.receiver.receivers = this.receivers;

    giftSchema.status = { status: this.status, refreshedAt: this.refreshedAt };
    giftSchema.approvalRequestTo = this.approvalRequestTo;
    giftSchema.gift.giftReason = this.giftReason;
    giftSchema.gift.giftDescription = this.giftDescription;
    giftSchema.gift.giftDescriptionOccasion = this.giftDescriptionOccasion;
    giftSchema.gift.giftDate = this.giftDate;
    giftSchema.gift.giftValue = this.giftValue;
    giftSchema.gift.giftValueType = this.giftValueType;

    giftSchema.invoice.invoiceNumber = this.invoiceNumber;
    giftSchema.invoice.internalNumber = this.internalNumber;
    giftSchema.invoice.invoiceAttachments = this.invoiceAttachments;
    giftSchema.invoice.grossValue = this.grossValue;
    giftSchema.invoice.netValue = this.netValue;
    giftSchema.lastComment = this.lastComment;
    return giftSchema;
  }

  isGiftDataChanged() {
    if (this.dataFromDB) {
      const dbData = JSON.stringify(JSON.parse(this.dataFromDB));
      this.writeGiftToSchemma();
      const currentData = JSON.stringify(giftSchema);

      return dbData !== currentData;
    } else {
      const giftSchemaClone = { ...this.writeGiftToSchemma() };
      // delete default values set to fields before created it
      delete giftSchemaClone.receiver.receivers;
      delete giftSchemaClone.status.status;
      delete giftSchemaClone.sender;
      return !isEmpty(giftSchemaClone);
    }
  }

  updateDataFromDB() {
    this.writeGiftToSchemma();
    this.dataFromDB = JSON.stringify(giftSchema);
  }

  discardChanges() {
    const dataFromDBObject = JSON.parse(this.dataFromDB);
    this.editGift(dataFromDBObject);
  }

  getStatus(statusType, gift) {
    const statuses = gift && gift.status && gift.status.status ? getArray(gift.status.status) : this.status;
    return statuses.find(item => item.status === statusType);
  }

  hasStatus(statusType) {
    const status = this.getStatus(statusType);
    return status ? true : false;
  }

  getNameFromStatus(statusType, gift) {
    const status = this.getStatus(statusType, gift);
    return status ? status.name : '';
  }

  getCommentFromStatus(statusType, gift) {
    const status = this.getStatus(statusType, gift);
    return status ? status.comment : '';
  }
}

decorate(GiftDataStore, {
  receiverType: observable,
  compliantGiftPolicy: observable,
  recommendation: observable,
  numberOfReceivers: observable,
  receivers: observable,
  senderFirstName: observable,
  senderLastName: observable,
  senderCompany: observable,
  senderEmail: observable,
  status: observable,
  approvalRequestTo: observable,
  giftReason: observable,
  giftDescription: observable,
  giftDescriptionOccasion: observable,
  giftDate: observable,
  giftValue: observable,
  giftValueType: observable,
  invoiceNumber: observable,
  internalNumber: observable,
  invoiceAttachments: observable,
  grossValue: observable,
  netValue: observable,
  guidance: observable,
  // Actions
  editGift: action,
  getStatus: action,
  hasStatus: action
});
