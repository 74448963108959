import React from 'react';
import PropTypes from 'prop-types';
import { infoIcon, infoIconSolid } from '../../Assets/dynamic-svg/general-icons';
import { inject, observer } from 'mobx-react';
import { royalBlue } from '../../Utils/styleHelper';
import './_style.scss';

function InfoButton(props) {
  return (
    <button style={{ alignSelf: 'baseline' }} onClick={e => props.handleInfoClick(e)}>
      <span title="Hinweis" className="icon-general">
        {props.isFocused ? infoIconSolid(royalBlue) : infoIcon(royalBlue)}
      </span>
    </button>
  );
}

InfoButton.defaultProps = {
  title: 'Hinweis',
  description: '',
  isFocused: false
};

InfoButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isFocused: PropTypes.bool,
  handleInfoClick: PropTypes.func
};

export default inject('rootStore')(observer(InfoButton));
